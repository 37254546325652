import React from "react";
import doc3 from "../assets/img/doctor/dr-rashmi-new.jpg";
import { useLocation, Link } from "react-router-dom";
import CustomTitle from "../CustomTitle";

export default function Rashmi() {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const helmetDescriptions = {
    titleName:
      "Embryologist Expert Dr. Rashmi T.S. - Expert in Fertility & IVF Solutions",
    Description:
      "Meet Dr. Rashmi T.S., a renowned embryologist offering personalised fertility and IVF solutions. Trusted expert in reproductive health. Book your consultation today!",
  };

  return (
    <div id="each-doctor">
      <CustomTitle customValue={helmetDescriptions} />
      {/* <div className="py-md-5" style={{ backgroundImage: "url(" + blogHeader + ")", backgroundPosition: 'center', backgroundSize: 'cover', height: '400px' }}>
        <div className="banner-header text-center py-5">
            <h2 style={{ color: '#fff', position: "relative", top: "100px" }}>Dr. DIVYASREE D</h2>
        </div>
    </div> */}
      <br />
      {/* <h6 className="offset-1 my-4 "  style={{ textAlign: "left", color: "black" }}>HOME &gt; MEDIA &gt; Blog</h6> */}
      <nav aria-label="breadcrumb" id="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>

          <li className="breadcrumb-item active" aria-current="page">
            Doctor
          </li>
        </ol>
      </nav>
      {/* -------------------------doctor detail--------------- */}

      <div className="container mt-4">
        <div className="row">
          <div className="col-md-5">
            <div className="card" style={{ maxWidth: "30rem" }}>
              <img src={doc3} className="card-img-top" alt="Dr. Divyashree D" />
            </div>
            <br />
            <br />
            <br />
            <h3>More Info</h3>
            <br />
            <p>M.Sc (Clinical Embryology & Pre – Implantation Genetics)</p>
            <br />
            <p>
              Department : <span>Infertility And Ivf</span>
            </p>
            <br />
            {/* <p>Consultations : 15 Minutes per Consultation</p>
            <br /> */}
            <p>Location : Mysore</p>
            <br />

            <span className="badge bg-primary p-2">
              Available on Prior Appointment
            </span>
            <br />
            <p> -Toll-free number @ 1800-425-4500</p>
            <br />
          </div>
          <div className="col-md-7">
            <h1 className="text-blue">Dr RASHMI T S </h1>
            <p>
              <strong>Embryologist</strong>
            </p>
            <h3>ACADEMIC PROFILE</h3>

            <span>
              Rashmi T S, Junior Embryologist - consultant, associated with
              Kangaroo Care Women & Children Hospitals, Mysore. She has immense
              knowledge of Embryology, Infertility treatment and Reproduction
              Endocrinology.
            </span>
            <br />

            <br />
            <h3>QUALIFICATION</h3>

            <span>
              MSc (Clinical Embryology & Pre – Implantation Genetics) - Asia
              Pacific Institute of Embryology, Mysore
            </span>
            <br />
            <br />
            <h3>WORK EXPERIENCE</h3>

            <span>
              She has completed Observership at Pearl Singapore Fertility
              Centre, Chennai India, and an Internship at Manipal Hospital,
              Fertility centre, Mysore. She worked as a Junior Embryologist at
              Bhoomi IVF centre, Bangalore.
            </span>
            <br />
            <br />
            <h3>SkillS</h3>
            <ol>
              <li>
                Clinical Embryology and Micromanipulation Laboratory skills -
              </li>
              <li>Preparation of culture dishes for IVF and ICSI</li>
              <li>Follicular fluid screening for OCC</li>
              <li>Identification of Oocytes</li>
              <li>Culture of oocytes, Conventional IVF</li>
              <li>
                Fertilisation of check, monitoring of embryos, and grading of
                embryos up to day 5
              </li>
            </ol>

            <br />
            <br />
            <h3>ANDOLOGY LABORATORY SKILLS</h3>
            <ol>
              <li>
                Comprehensive semen assessment including strict Kruger
                morphology
              </li>
              <li>Viability assessment</li>
              <li>Sperm DNA fragmentation test</li>
              <li>Semen preparation for IUI, IVF, and ICSI</li>
              <li>Testicular tissue preparation</li>
              <li>Semen cryopreservation</li>
            </ol>
            <br />
            <br />

            <ol></ol>
            {/* <p>
              Dr. Divyasree is practicing obstetrician and gynecologist with
              experience of more than 10 yrs and exclusive experience in
              treating infertile couple for the past 3 years.
            </p>
            <p>
              She completed her MBBS from prestigious Gandhi medical college,
              Hyderabad and did her post graduation in Obstetrics and Gynecology
              from CAIMS Karimnagar, Andra Pradesh. She has done her fellowship
              in reproductive medicine from CRAFTS hospital and research
              institute, Kerala. She is trained in minimal invasive surgeries,
              at Bangalore.
            </p>
            <p>
              Dr. Divyasree is specialized in treating infertile couple with
              PCOS, poor ovarian reserve, recurrent IVF failures and recurrent
              pregnancy loss.
            </p>
            <p>
              She has given good pregnancy rates and take home baby rate in
              infertile couple
            </p>
            <h3>QUALIFICATION</h3>
            <p>MBBS, MS (OBG)</p>
            <p>Fellowship in reproductive medicine</p>
            <h3>WORK EXPERIENCE</h3>
            <p>
              Consultant at Garbhagudi IVF center, Bangalore from 2018 till
              2021.
            </p>
            <p>
              Reproductive medicine unit at CRAFTS hospital, Kerala 2017 t0
              2018.
            </p>
            <p>
              Assistant professor in dept of OBG at PESIMSR, Kuppam.2012 to
              2017.
            </p>
            <h3>RESEARCH AND PUBLICATIONS:</h3>
            <p>
              She has presented and published various clinical papers and case
              reports in national journals and conferences.
            </p>
            <p>
              Testicular sperms versus macs in patients with high dna
              fragmentation index and their ICSI outcome - presentation at
              national fertility conference 2018.
            </p>
            <p>
              Laparoscopic management of large ovarian cyst – published in
              international journal Reprod contracept Obstet Gynecol 2017.
            </p>
            <p>
              Disengagement of the deeply engaged fetal head during caesarean
              section in advanced labour : patwardhan versus push method
              published in international journal reprod contracept obstet
              gynecol 2015.
            </p>
            <h3>MEMBERSHIP:</h3>
            <p>Karnataka Medical Council</p>
            <p>She is a life time member in Indian fertility society.</p> */}
          </div>
        </div>
      </div>
    </div>
  );
}
