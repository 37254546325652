import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
//browser router
import { useNavigate } from "react-router-dom";
import { RedirectionArray } from "./RedirectionArray";
export default function Redirection() {
  const { location } = useLocation();
  const { pathname } = window.location;
  function removeSlash(link) {
    if (link.slice(-1) === "/") {
      return link.slice(0, -1);
    } else {
      return link;
    }
  }

  useEffect(() => {
    RedirectionArray.map((item) => {
      let WebLink = removeSlash(window.location.pathname);
      if (item.link.includes(WebLink)) {
        window.location.href = item.redirect;
        console.log("present", item.redirect);
      }
    });
  }, [location, pathname]);
  return null;
}
