import React from "react";
import CustomTitle from "../CustomTitle";
import "./TesaPesa.css";
import simg from "../assets/img/fertility/tesa-sub.jpg";
import { useLocation, Link } from "react-router-dom";
export default function Services() {
  const helmetDescriptions = {
    titleName:
      "TESA & PESA Fertility Treatment in Bangalore | Best Fertility Centert",
    Description:
      "Kangaroo Care Fertility is the best source of TESA & PESA infertility treatment in Bangalore with state of art labs and highly experienced specialists.",
  };
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const tesaSchema = `<script type="application/ld+json">
  {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "When is PESA/TESA done?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "PESA/TESA is done, when a male partner is suffering from any one of the following conditions:
  - Primary testicular problems with deficient sperm production.
  - An irreversible obstruction in the genital tract caused due by an infection or previous surgery.
  - Congenital absence of the vas deferens commonly seen in the carriers of cystic fibrosis.
  - Has undergone a vasectomy or an unsuccessful vasectomy reversal."
      }
    },{
      "@type": "Question",
      "name": "What is the success with PESA/TESA?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "ICSI treatment using sperm obtained by PESA or TESA is a commonly performed procedure and has good success rates. In general, the success rate is around 30-35% for every treatment cycle."
      }
    },{
      "@type": "Question",
      "name": "What is the difference between TESA and TESE?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "TESE (testicular sperm extraction), is actually a surgical biopsy of the testis and TESA (testicular sperm aspiration), is a procedure, performed by sticking a needle in the testis and aspirating fluid and tissue with negative pressure."
      }
    },{
      "@type": "Question",
      "name": "Can TESA be repeated?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Yes TESA can be repeated. We have repeated around three to four times in a patient."
      }
    },{
      "@type": "Question",
      "name": "How long does a TESA procedure take?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "The procedure takes approximately 20 to 30 minutes. Recovery is shorter and significantly less painful. While open testicular surgery may require a recovery period of nearly a week."
      }
    }]
  }
  </script>


  `;
  return (
    <div>
      <CustomTitle
        href="https://kangaroocarefertility.com/treatments/tesa-pesa"
        customValue={helmetDescriptions}
      />
      <iframe srcDoc={tesaSchema} title="schema" width="0" height="0" />
      <div className="main-content container mt-2">
        <nav aria-label="breadcrumb" className="mb-5">
          <ol className="breadcrumb">
            <li className="breadcrumb-item text-specifc-color">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item text-specifc-color">
              <Link to="/">Specialities</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              TESA/ PESA
            </li>
          </ol>
        </nav>
      </div>

      {/* -------main content------------ */}

      {/* -----------------------------what is portion -------------------- */}
      <div className="container-fluid service my-5">
        <div className="row justify-content-center">
          <div className="col-md-6 mx-2 d-none d-sm-block">
            <div className="mb-2" id="service-left-img-tesa">
              <img src={simg} alt="Tesa pesa treatment" />
            </div>
          </div>
          <div className="col-md-5 mb-2 mx-2" id="service-content-card">
            <h2 className="my-4 text-center text-blue">
              Sperm Retrieval Techniques - TESA/ PESA
            </h2>
            <hr />
            <p>
              <i className="fas fa-check-circle me-2"></i>
              <strong>What is Sperm Retrieval?</strong>
            </p>
            <p>
              Surgical sperm retrieval is recommended for men with azoospermia.
              Azoospermia is a condition when there is blockage of the tube for
              sperm passage or an absence of sperm in ejaculate.
              <br />
              Sperm retrieval techniques are PESA, TESA, TESE, micro-TESA.
            </p>
            <p>
              Percutaneous epididymal sperm aspiration (PESA), is a method where
              sperm is collected from the epididymis inside the scrotum using a
              fine needle. This method is usually used when there is no sperm
              found in the ejaculate due to hindrance in the passages. This may
              be due to vasectomy reversal, damage to the vas deferens from
              infection, or bilateral congenital absence of vas deferens.
            </p>
            <p>
              Testicular sperm aspiration (TESA), is a method where sperm is
              retrieved directly from the testicles and is commonly used for men
              with non-obstructive azoospermia. With this condition, no sperm
              can be retrieved from the epididymis.
            </p>
          </div>
        </div>

        {/* -----------------------section2 content process--------------------- */}

        <div className="row justify-content-center my-5">
          <h1 className="heading1 text-center">
            TESA & PESA Fertility Treatment
          </h1>
          <div className="col-md-8 mx-2" id="service-content2-card">
            <div className="mb-2 p-4">
              <h2 className="text-center text-blue"> Procedure of PESA/TESA</h2>
              <br />
              <p>
                <i className="fas fa-check-circle me-2"></i>
                The procedure of PESA/TESA is performed under local/short
                anaesthesia.
              </p>
              <p>
                <i className="fas fa-check-circle me-2"></i>
                In PESA, a fine needle is passed through the skin of the
                epididymis to retrieve the sperm, in TESA the needle is passed
                through the skin directly into the testes to collect a small
                number of seminiferous tubules (seminiferous tubules are the
                place where the sperms are produced) themselves. The tubules are
                dissected in the laboratory to search if any content of the
                sperms are present.
              </p>
              <p>
                <i className="fas fa-check-circle me-2"></i>
                The success rate of PESA and TESA methods for surgical sperm
                retrieval treatment is similar to the success using sperm
                ejaculation. PESA and TESA methods are also a treatment option
                for men who are experiencing non-obstructive and obstructive
                azoospermia. In both cases there is a success rate of around 25%
                for every treatment cycle.
              </p>
            </div>
          </div>
          <div
            className="col-md-3 mb-2 mx-2 p-4 card  d-none d-md-block"
            id="service-link-card"
          >
            <h2 className="text-blue">Important Links</h2>
            <br />
            <ul className="ms-5">
              <li>
                <Link to="/treatments/ovulation-induction">
                  <span className="hand-link">&#9758;</span> &nbsp; Ovulation
                  Induction
                </Link>
              </li>
              <li>
                <Link to="/treatments/intrauterine">
                  <span className="hand-link">&#9758;</span> &nbsp; Intrauterine
                  Insemination (IUI)
                </Link>
              </li>
              <li>
                <Link to="/treatments/invitro-fertilization">
                  <span className="hand-link">&#9758;</span> &nbsp; In Vitro
                  Fertilization
                </Link>
              </li>
              <li>
                <Link to="/treatments/intracytoplasmic">
                  <span className="hand-link">&#9758;</span> &nbsp;
                  Intracytoplasmic Sperm Injection{" "}
                </Link>
              </li>
              <li>
                <Link to="/treatments/genetic-counselling-and-testing">
                  <span className="hand-link">&#9758;</span> &nbsp; Genetic
                  Counselling and Testing
                </Link>
              </li>
              <li>
                <Link to="/treatments/varicocele">
                  <span className="hand-link">&#9758;</span> &nbsp; Varicocele
                </Link>
              </li>
              <li>
                <Link to="/treatments/tesa-pesa">
                  <span className="hand-link">&#9758;</span> &nbsp; TESA/ PESA
                </Link>
              </li>
              <li>
                <Link to="/treatments/fertility-preservation">
                  <span className="hand-link">&#9758;</span> &nbsp; Fertility
                  Preservation
                </Link>
              </li>
              <li>
                <Link to="/treatments/Donor">
                  <span className="hand-link">&#9758;</span> &nbsp; Donor
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* -------------------------advantage cards---------------- */}

      <div className="container-fluid my-4">
        <div className="row justify-content-center" id="scards">
          <h2 className="text-center text-blue">Advantages of PESA/TESA</h2>
          <br />
          <br />
          <div className="col-sm-3 m-2 p-3 the-3-service-card">
            <div className="text-center">
              {/* <img src={logo} alt="" /> */}
              <p>
                <strong className="text-blue">Minimally invasive</strong>
              </p>
            </div>
            <p>
              The PESA/TESA procedures are minimally invasive compared to TESE,
              so no harm is done to the testes.
            </p>
          </div>
          <div className="col-sm-3 m-2 p-3 the-3-service-card">
            <div className="text-center">
              {/* <img src={logo} alt="" /> */}
              <p>
                <strong className="text-blue">Affordable</strong>
              </p>
            </div>
            <p>
              As they involve a simple procedure, the cost is very less compared
              to other major procedures such as TESE.{" "}
            </p>
          </div>
          <div className="col-sm-3 m-2 p-3 the-3-service-card">
            <div className="text-center">
              {/* <img src={logo} alt="" /> */}
              <p>
                <strong className="text-blue">Good outcome</strong>
              </p>
            </div>
            <p>
              With the state of the art lab and experienced specialists, we
              obtain good no of sperms and good pregnancy outcome out of these.
            </p>
          </div>
        </div>
      </div>

      {/* ---------------------------faq------------------------ */}

      <div className="accordion container-fluid my-5 " id="accordionExample">
        <h2 className="text-center text-blue">FAQs</h2>
        <br />
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              • When is PESA/TESA done?
            </button>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              PESA/TESA is done, when a male partner is suffering from any one
              of the following conditions:
              <p>
                - Primary testicular problems with deficient sperm production.
              </p>
              <p>
                - An irreversible obstruction in the genital tract caused due by
                an infection or previous surgery.
              </p>
              <p>
                - Congenital absence of the vas deferens commonly seen in the
                carriers of cystic fibrosis.
              </p>
              <p>
                - Has undergone a vasectomy or an unsuccessful vasectomy
                reversal.
              </p>
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingTwo">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              • What is the success with PESA/TESA?
            </button>
          </h2>
          <div
            id="collapseTwo"
            className="accordion-collapse collapse"
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              ICSI treatment using sperm obtained by PESA or TESA is a commonly
              performed procedure and has good success rates. In general, the
              success rate is around 30-35% for every treatment cycle.
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingThree">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              • What is the difference between TESA and TESE?
            </button>
          </h2>
          <div
            id="collapseThree"
            className="accordion-collapse collapse"
            aria-labelledby="headingThree"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              TESE (testicular sperm extraction), is actually a surgical biopsy
              of the testis and TESA (testicular sperm aspiration), is a
              procedure, performed by sticking a needle in the testis and
              aspirating fluid and tissue with negative pressure.
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingFour">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
              • Can TESA be repeated?
            </button>
          </h2>
          <div
            id="collapseFour"
            className="accordion-collapse collapse"
            aria-labelledby="headingFour"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              Yes TESA can be repeated. We have repeated around three to four
              times in a patient.
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingFive">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFive"
              aria-expanded="false"
              aria-controls="collapseFive"
            >
              • How long does a TESA procedure take?
            </button>
          </h2>
          <div
            id="collapseFive"
            className="accordion-collapse collapse"
            aria-labelledby="headingFive"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              The procedure takes approximately 20 to 30 minutes. Recovery is
              shorter and significantly less painful. While open testicular
              surgery may require a recovery period of nearly a week.
            </div>
          </div>
        </div>
      </div>
      {/* ----------------some heading and para----------- */}
      <div className="container-fluid bg-light ">
        <div className="container">
          <div className="row">
            <div className="col text-center my-sm-5 my-3">
              <h2 className="heading2">
                Why choose Kangaroo Care Fertility for TESA/PESA treatment in
                Bangalore?
              </h2>
              <p>
                At Kangaroo Care Fertility, we are equipped with a comprehensive
                assessment for male infertility related problems which helps to
                overcome infertility with sperm retrieval treatment in
                Bangalore. Our team provides the best TESA & PESA Fertility
                Treatment.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid bg-light ">
        <div className="container">
          <div className="row">
            <div className="col text-center my-sm-5 my-3">
              <h2 className="heading2">
                TESA & PESA Infertility Treatment in Bangalore
              </h2>
              <p>
                Kangaroo Care Fertility provides the opportunity for men to
                overcome infertility problems with sperm retrieval treatment.
                With experienced specialists, we have obtained a good pregnancy
                outcome with the help of TESA & PESA male fertility treatment.
                We also provide treatments like Ovulation Induction, IVF, IUI,
                ICSI, genetic counseling & testing, Varicocele treatment. &nbsp;
                <a href="https://kangaroocarefertility.com/treatments/ovulation-induction">
                  Ovulation Induction,
                </a>
                <a href="https://kangaroocarefertility.com/treatments/invitro-fertilization">
                  IVF treatment,
                </a>
                <a href="https://kangaroocarefertility.com/treatments/intrauterine">
                  IUI,
                </a>
                <a href="https://kangaroocarefertility.com/treatments/intracytoplasmic">
                  ICSI,
                </a>
                <a href="https://kangaroocarefertility.com/treatments/genetic-counselling-and-testing">
                  genetic counseling & testing,
                </a>
                <a href="https://kangaroocarefertility.com/treatments/varicocele">
                  varicocele treatment, &nbsp;
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
