import React from "react";
import styles from "./bookappointment.module.css";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

export default function BookAppointment() {
  const [branch, setBranch] = React.useState("fertility");
  const { appoitmentBranch } = useParams();
  // Metadata for each branch
  const helmetDescriptions = {
    fertility: {
      titleName: "Book Your Fertility Appointment | Kangaroo Care Fertility",
      description:
        "Schedule your fertility consultation at Kangaroo Care. Expert care, personalized treatment plans, and advanced technology to support your journey to parenthood.",
    },
    "ramanagara-fertility": {
      titleName: "Book Fertility Appointment in Ramanagara | Kangaroo Care",
      description:
        "Book your fertility appointment in Ramanagara with Kangaroo Care. Trusted specialists, personalized care. Start your fertility journey today!",
    },
    "mysore-fertility": {
      titleName:
        "Book Fertility Appointment in Mysore | Kangaroo Care Fertility",
      description:
        "Schedule your fertility consultation in Mysore with Kangaroo Care. Expert care and personalized treatment plans to help you on your journey to parenthood.",
    },
  };

  const Links = {
    fertility:
      "https://kangaroocare-bangalore.pulseright.io/booking?orgId=64428e3d94af9311780d62a3",

    "ramanagara-fertility":
      "https://kangaroocare-bangalore.pulseright.io/booking?orgId=660e5a5c769c7a6a01f3dcfb",
    "mysore-fertility":
      "https://kangaroocare-bangalore.pulseright.io/booking?orgId=65f80548133c30154bd93d76",
  };

  React.useEffect(() => {
    setBranch(Links[appoitmentBranch]);
  }, []);
  // Select metadata based on the branch
  const selectedMeta =
    helmetDescriptions[appoitmentBranch] || helmetDescriptions.fertility;

  return (
    <section className={styles.wrapper}>
      <Helmet>
        <title>{selectedMeta.titleName}</title>
        <meta name="description" content={selectedMeta.description} />
      </Helmet>
      <Container width>
        <div className={styles.container}>
          <h1>Book Appointment</h1>
        </div>

        {branch && (
          <iframe
            title="Appointment"
            width="100%"
            height="600px"
            src={branch}
          ></iframe>
        )}
        {/* <div className={styles.items}>
          <h2>Create Appointment</h2>
          <div className={styles.item}>
            <div>
              <h2>1</h2>
              <h3>Step 1</h3>
            </div>
            <div>
              <h2>2</h2>
              <h3>Step 2</h3>
            </div>
            <div>
              <h2>3</h2>
              <h3>Step </h3>
            </div>
          </div>
        </div> */}
      </Container>
    </section>
  );
}
