import React from "react";
import "./events.css";
import img1 from "../assets/img/media/blogs/img-1.jpg";
import img2 from "../assets/img/media/blogs/img-2.jpg";
import img3 from "../assets/img/media/blogs/img-3.jpeg";
import { useLocation, Link } from "react-router-dom";
import CustomTitle from "../CustomTitle";
export default function Events() {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
   const helmetDescriptions = {
     titleName: "Events | Leading Fertility Treatment Hospital in Bangalore",
     Description:
       "Join events at Bangalore's top fertility hospital. Explore advancements in fertility care and connect with specialists for insights on your journey to parenthood.",
   };
  return (
    <>
      <CustomTitle customValue={helmetDescriptions} />
      <div>
        {/* <div className="py-md-5" style={{ backgroundImage: "url(" + blogHeader + ")", backgroundPosition: 'center', backgroundSize: 'cover', height: '400px' }}>
        <div className=" text-center py-5">
            <h2 style={{ color: 'white', position: "relative", top: "100px" }}>| Events |</h2>
        </div>
    </div> */}
        <br />
        {/* <h6 className="offset-1 my-4" style={{ textAlign: "left", color: "black" }}>HOME &gt; MEDIA &gt; EVENTS</h6> */}

        <nav aria-label="breadcrumb" id="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Media
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Events
            </li>
          </ol>
        </nav>
        {/* ---------------events---------------- */}
        <div className="container events">
          <h1 className="h1-top-text">Events</h1>
          <div className="row">
            <div className="col-md-4 mb-3 mb-md-5 bg-light">
              <div className="card-img events-img events-container">
                <img src={img1} alt="..." />
                <div className="events-overlay">
                  <div className="events-text">25 July 2024</div>
                </div>
                <div className="card-title text-center py-2">
                  <h6>IVF Day & Embryologist Day celebration at Bangalore</h6>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-3 mb-md-5">
              <div className="card-img events-img events-container">
                <img src={img2} alt="..." />
                <div className="events-overlay">
                  <div className="events-text">25 July 2024</div>
                </div>
                <div className="card-title text-center py-2">
                  <h6>IVF Day & Embryologist Day celebration at Mysore</h6>
                </div>
              </div>
            </div>
            <div className="col-md-4 mb-3 mb-md-5">
              <div className="card-img events-img events-container">
                <img src={img3} alt="..." />
                <div className="events-overlay">
                  <div className="events-text">25 July 2024</div>
                </div>
                <div className="card-title text-center py-2">
                  <h6>IVF Day & Embryologist Day celebration at Ramanagara</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
