import React from "react";
import logo from "../assets/img/media/blogs/logo-01.png";
import logogs from "../assets/img/media/blogs/blog-andrology.jpg";

import { NavLink as Link } from "react-router-dom";
import CustomTitle from "../CustomTitle";
import { Helmet } from "react-helmet";
import { BlogSchema } from "./blogSchema.js";
export default function Blogs(props) {
  let SeoTitle = "hello";
  const helmetDescriptions = {
    titleName: props[0].seoTitle ? props[0].seoTitle : props[0].title,
    Description: props[0].seoDesc ? props[0].seoDesc : props[0].seoDesc,
  };

  const schemaFilter = BlogSchema.filter((item) => {
    return item.blogTitle === props[0].title;
  });

  return (
    <>
      <CustomTitle customValue={helmetDescriptions} />
      <nav aria-label="breadcrumb" id="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Media
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Our Blogs
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {props[0].title}
          </li>
        </ol>
      </nav>
      {/* ...........
      .............
      ............. */}
      <div className="container">
        <div
          className="card offset-lg-1"
          style={{ maxWidth: "1000px", border: "none", alignItems: "center" }}
        >
          <img
            src={`/${props[0].image}`}
            className="card-img-top"
            alt={props[0].title}
          />
          <div className="card-body row d-flex">
            <div className="col-md-3 d-none d-sm-block">
              <img
                className="py-4"
                src={logo}
                alt="Kangaroo Care Fertility Logo Small"
                style={{ maxWidth: "5rem", borderRadius: "50%" }}
              />
              <p>
                <strong className="text-blue">Admin</strong>
                <br />
                Adminstrator
              </p>
            </div>

            <div className="col-md-9" style={{ textAlign: "left" }}>
              <h1 className="my-2 text-blue"> {props[0].title}</h1>
              <div
                dangerouslySetInnerHTML={{ __html: props[0].detailed_text }}
              ></div>
            </div>
            {schemaFilter.length > 0 && (
              <iframe
                srcDoc={schemaFilter[0].schema}
                title="Faq Schema"
                width="0"
                height="0"
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
