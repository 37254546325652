import React from "react";
import CustomTitle from "../CustomTitle";
import "./GeneticCounsellingandTesting.css";
import simg from "../assets/img/fertility/Genetic-Counselling-and-Testing.jpg";
import { useLocation, Link } from "react-router-dom";
export default function Services() {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const helmetDescriptions = {
    titleName:
      "Genetic Counseling & Testing Center Bangalore | Best Fertility Clinic",
    Description:
      "Get the best genetic counseling by the specialists at Kangaroo Care Fertility Hospital Bangalore. Our specialists will help you understand the pros and cons of the treatment. Consult us today.!",
  };

  const geneticSchema = `<script type="application/ld+json">
  {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "What does PGT testing test for?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Preimplantation genetic testing (PGT) can help identify the embryos that are most suitable for transfer during IVF to give you the best chance of implantation and a successful pregnancy. This test works by identifying embryos with extra or missing chromosomes or pieces of chromosomes"
      }
    },{
      "@type": "Question",
      "name": "Does PGT harm the embryo?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Not as far as we know. Current research shows that the likelihood of a biopsied embryo implanting is similar to that of a non-biopsied embryo. Despite the removal of a few cells from the embryo, there have been no reports of any health problems as a result of embryo biopsy in children conceived after PGT."
      }
    },{
      "@type": "Question",
      "name": "How long does pre implantation genetic testing take?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Usually it takes around 7-10 days to obtain the result."
      }
    },{
      "@type": "Question",
      "name": "Can PGT be done in Repeated pregnancy losses?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "YES, PGT can be done in repeated pregnancy losses. It decreases the time to pregnancy in these couples."
      }
    },{
      "@type": "Question",
      "name": "What are the benefits of PGT?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "- Improved embryo selection
  - Prevent genetic transmission of unknown abnormalities
  - Optimized chance of a successful pregnancy
  - Quicker time to pregnancy
  - Reduced monetary burden"
      }
    }]
  }
  </script>


  `;
  return (
    <div>
      <CustomTitle
        href="https://kangaroocarefertility.com/treatments/genetic-counselling-and-testing "
        customValue={helmetDescriptions}
      />
      <iframe srcDoc={geneticSchema} title="schema" width="0" height="0" />
      <div className="main-content container mt-2">
        <nav aria-label="breadcrumb" className="mb-5">
          <ol className="breadcrumb">
            <li className="breadcrumb-item text-specifc-color">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item text-specifc-color">
              <span>Treatments</span>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Genetic Counselling and Testing
            </li>
          </ol>
        </nav>
      </div>

      {/* -------main content------------ */}

      {/* -----------------------------what is portion -------------------- */}
      <div className="container-fluid service my-5">
        <div className="row justify-content-center">
          <div className="col-md-6 mx-2 d-none d-sm-block">
            <div className="mb-2" id="service-left-img-gen">
              <img src={simg} alt="Genetic" />
            </div>
          </div>
          <div className="col-md-5 mb-2 mx-2" id="service-content-card">
            <h1 className="my-4 text-center text-blue ">
              Genetic Counselling and Testing
            </h1>
            <hr />
            <p>
              <i className="fas fa-check-circle me-2"></i>
              <strong>What is Genetic Counselling and Testing?</strong>
            </p>
            <p>
              Genetic counseling is a communication process about the medical
              facts, the contribution of heredity to certain conditions, the
              interpretation of test results, and the options available. It also
              involves supportive counselling to enable patients to make
              decisions and to make the best possible adjustment to the presence
              or risk of genetic disease.
            </p>
          </div>
        </div>

        {/* -----------------------section2 content process--------------------- */}

        <div className="row justify-content-center my-5">
          <div className="col-md-8 mx-2" id="service-content2-card">
            <div className="mb-2 p-4">
              <h2 className="text-blue">
                Pre-implantation Genetic Testing (PGT)
              </h2>
              <br />
              <div className="content1">
                <h3>
                  <i className="fas fa-check-circle me-2"></i>What is PGT?
                </h3>
              </div>
              <p>
                PGT is a sophisticated scientific technique which is used to
                test embryos for a specific known single gene condition or a
                chromosome variation.
              </p>
              <p>
                This procedure helps in selecting the chromosomally normal
                embryos or those without a specific disorder. These embryos can
                be transferred in the IVF/ICSI cycle.
              </p>
              <div className="content1">
                <h3>
                  <i className="fas fa-check-circle me-2"></i>When to suggest
                  PGT
                </h3>
              </div>
              <p>
                <li>
                  Either or both partners are carriers of single gene mutations
                  to prevent the same in the offspring/s.
                </li>
                <li>
                  Either partner has a translocation that can result in
                  chromosomal variations in the eggs or sperm that could lead to
                  either miscarriage or health problems in the child.
                </li>
                <li>
                  Where a pregnancy was affected by a chromosomal variation
                </li>
                <li>Advanced maternal age</li>
                <li>Recurrent miscarriage</li>
                <li>Repeated IVF failure</li>
              </p>
              <div className="content1">
                <h3>
                  <i className="fas fa-check-circle me-2"></i>Gene disorders
                  detected with PGT
                </h3>
              </div>
              <p>
                <li>Huntington’s disease</li>
                <li>Cystic fibrosis</li>
                <li>Thalassaemia</li>
                <li>Duchenne muscular dystrophy</li>
                <li>Fragile-X</li>
                <li>BRCA1/BRCA2 (hereditary breast/ovarian cancer)</li>
              </p>
            </div>
          </div>
          <div
            className="col-md-3 mb-2 mx-2 p-4 card  d-none d-md-block"
            id="service-link-card"
          >
            <h2 className="text-blue">Important Links</h2>
            <br />
            <ul className="ms-5">
              <li>
                <Link to="/treatments/ovulation-induction">
                  <span className="hand-link">&#9758;</span> &nbsp; Ovulation
                  Induction
                </Link>
              </li>
              <li>
                <Link to="/treatments/intrauterine">
                  <span className="hand-link">&#9758;</span> &nbsp; Intrauterine
                  Insemination (IUI)
                </Link>
              </li>
              <li>
                <Link to="/treatments/invitro-fertilization">
                  <span className="hand-link">&#9758;</span> &nbsp; In Vitro
                  Fertilization
                </Link>
              </li>
              <li>
                <Link to="/treatments/intracytoplasmic">
                  <span className="hand-link">&#9758;</span> &nbsp;
                  Intracytoplasmic Sperm Injection{" "}
                </Link>
              </li>
              <li>
                <Link to="/treatments/genetic-counselling-and-testing">
                  <span className="hand-link">&#9758;</span> &nbsp; Genetic
                  Counselling and Testing
                </Link>
              </li>
              <li>
                <Link to="/treatments/varicocele">
                  <span className="hand-link">&#9758;</span> &nbsp; Varicocele
                </Link>
              </li>
              <li>
                <Link to="/treatments/tesa-pesa">
                  <span className="hand-link">&#9758;</span> &nbsp; TESA/ PESA
                </Link>
              </li>
              <li>
                <Link to="/treatments/fertility-preservation">
                  <span className="hand-link">&#9758;</span> &nbsp; Fertility
                  Preservation
                </Link>
              </li>
              <li>
                <Link to="/treatments/Donor">
                  <span className="hand-link">&#9758;</span> &nbsp; Donor
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* -------------------------advantage cards---------------- */}
      {/* 
      <div className="container-fluid my-4">
        <div className="row justify-content-center" id="scards">
          <h2 className="text-center">Advantages of Ovulation Induction</h2>
          <br />
          <br />
          <div className="col-md-3 m-2 p-3 the-3-service-card">
            <div className="text-center">
              <img src={logo} alt="" />
              <p>
                <strong>Affordable</strong>
              </p>
            </div>
            <p>
              Ovulation Induction involves oral medications and sometimes
              injections and thus is not expensive.
            </p>
          </div>
          <div className="col-md-3 m-2 p-3 the-3-service-card">
            <div className="text-center">
              <img src={logo} alt="" />
              <p>
                <strong>Non-invasive</strong>
              </p>
            </div>
            <p>It does not involve any invasive procedures.</p>
          </div>
          <div className="col-md-3 m-2 p-3 the-3-service-card">
            <div className="text-center">
              <img src={logo} alt="" />
              <p>
                <strong>Suitability</strong>
              </p>
            </div>
            <p>
              It is most suitable in women with PCOS, anovulation, mild-moderate
              endometriosis, unexplained infertility.
            </p>
          </div>
        </div>
      </div> */}
      {/* ---------------------------faq------------------------ */}

      <div className="accordion container-fluid my-5 " id="accordionExample">
        <h2 className="text-center text-blue">FAQs</h2>
        <br />
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              • What does PGT testing test for?
            </button>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              Preimplantation genetic testing (PGT) can help identify the
              embryos that are most suitable for transfer during IVF to give you
              the best chance of implantation and a successful pregnancy. This
              test works by identifying embryos with extra or missing
              chromosomes or pieces of chromosomes
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingTwo">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              • Does PGT harm the embryo?
            </button>
          </h2>
          <div
            id="collapseTwo"
            className="accordion-collapse collapse"
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              Not as far as we know. Current research shows that the likelihood
              of a biopsied embryo implanting is similar to that of a
              non-biopsied embryo. Despite the removal of a few cells from the
              embryo, there have been no reports of any health problems as a
              result of embryo biopsy in children conceived after PGT.
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingThree">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              • How long does pre implantation genetic testing take?
            </button>
          </h2>
          <div
            id="collapseThree"
            className="accordion-collapse collapse"
            aria-labelledby="headingThree"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              Usually it takes around 7-10 days to obtain the result.
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingFour">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
              • Can PGT be done in Repeated pregnancy losses?
            </button>
          </h2>
          <div
            id="collapseFour"
            className="accordion-collapse collapse"
            aria-labelledby="headingFour"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              YES, PGT can be done in repeated pregnancy losses. It decreases
              the time to pregnancy in these couples.
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingFive">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFive"
              aria-expanded="false"
              aria-controls="collapseFive"
            >
              • What are the benefits of PGT?
            </button>
          </h2>
          <div
            id="collapseFive"
            className="accordion-collapse collapse"
            aria-labelledby="headingFive"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              - Improved embryo selection <br></br>- Prevent genetic
              transmission of unknown abnormalities<br></br>- Optimized chance
              of a successful pregnancy<br></br>- Quicker time to pregnancy
              <br></br>- Reduced monetary burden<br></br>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-light ">
        <div className="container">
          <div className="row">
            <div className="col text-center my-sm-5 my-3">
              <h2 className="heading2">
                Why choose Kangaroo Care Fertility for Genetic Counselling and
                Testing in Bangalore?
              </h2>
              <p>
                Kangaroo Care Fertility provides the best treatment for both
                male and female infertility problems to increase the chances of
                pregnancy. We have a team of best genetic counsellors in
                Bangalore.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid bg-light ">
        <div className="container">
          <div className="row">
            <div className="col text-center my-sm-5 my-3">
              <h2 className="heading2">
                Genetic Counseling and testing center in Vijayanagar Bangalore
              </h2>
              <p>
                Kangaroo Care Fertility is equipped with comprehensive
                assessment for both male and female infertility-related
                problems. A dedicated team of genetic counselors continuously
                strives to give the best to the patients with an aim to achieve
                positive results.
              </p>
              <p>
                We provide treatments like &nbsp;
                <a href="https://kangaroocarefertility.com/treatments/ovulation-induction">
                  Ovulation Induction,
                </a>
                &nbsp;{" "}
                <a href="https://kangaroocarefertility.com/treatments/invitro-fertilization">
                  IVF treatment,
                </a>
                &nbsp;
                <a href="https://kangaroocarefertility.com/treatments/intrauterine">
                  IUI,
                </a>
                &nbsp;
                <a href="https://kangaroocarefertility.com/treatments/intracytoplasmic">
                  ICSI,
                </a>
                &nbsp;
                <a href="https://kangaroocarefertility.com/treatments/varicocele">
                  varicocele treatment,
                </a>
                <a href="https://kangaroocarefertility.com/treatments/tesa-pesa">
                  &nbsp; TESA/PESA
                </a>
                &nbsp; for both female and male infertility problems.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
