import React from "react";
import CustomTitle from "./components/CustomTitle";
import "./facility.css";
import ivflab from "./components/assets/img/facility/fac/adv-embyo-lab.jpg";
import androlab from "./components/assets/img/facility/fac/andro-lab.jpg";
import ot from "./components/assets/img/facility/fac/ot-room.jpg";
import hyst from "./components/assets/img/facility/facility/Laparoscopy.jpg";
import opward from "./components/assets/img/facility/post-ward.jpg";
import scan from "./components/assets/img/facility/Scan-Room.jpg";
import semen from "./components/assets/img/facility/semen-room-new.jpg";
import couns from "./components/assets/img/facility/coun-room.jpg";
import { useLocation, Link } from "react-router-dom";
export default function Facility() {
  const helmetDescriptions = {
    titleName: "Advanced Lab Facilities at Kangaroo Care Fertility Hospital",
    Description:
      "Kangaroo Care Fertility has highly advanced labs to carry out fertility treatment procedures and testing. Contact us for the best fertility treatment in Bangalore",
  };
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <div>
      <CustomTitle
        href="https://kangaroocarefertility.com/facility"
        customValue={helmetDescriptions}
      />
      {/* <div className="banner-header-facility" >
          <h2 className="text-center"> Facilities </h2>
        </div> */}
      <br />
      {/* <h6 className="offset-1 my-4 "  style={{ textAlign: "left", color: "black" }}>HOME &gt; MEDIA &gt; Blog</h6> */}
      <nav aria-label="breadcrumb" id="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>

          <li className="breadcrumb-item active" aria-current="page">
            Facilities
          </li>
        </ol>
      </nav>
      <h1 className="h1-top-text">
        Top Facilities for Fertility Treatment in Bangalore
      </h1>
      {/* -----------------card------------- */}

      <div className="container" id="facility-img-contnent">
        <div className="row align-items-center mb-3">
          <div className="col-md-4 mb-2">
            <img src={ivflab} alt="doctor using a microscope" />
          </div>
          <div className="col-md-8">
            <h2 className="text-blue">Advanced Embryology Lab</h2>
            <p>
              The IVF lab is where fertilization occurs, where the embryos are
              cultured before transfer, and where delicate, highly sophisticated
              procedures such as intracytoplasmic sperm injection (ICSI),
              assisted hatching, and genetic testing are performed. At Kangaroo
              Care fertility, we have an advanced and modern IVF Lab that
              supports all the modern strategies of IVF in a safe environment.
              The fertility is a process that may take weeks and having an
              advanced modular Lab will help us treat and serve you better for
              assured results.
            </p>
          </div>
        </div>
        <div className="row align-items-center mb-3">
          <div className="col-md-8 mb-2">
            <h2 className="text-blue">Andrology Lab</h2>
            <p>
              Andrology is the science of male reproduction including the sperm
              production, diseases and the abnormalities. Our sophisticated
              Andrology lab offers a full range of specialized tests and
              services for evaluation and treatment (IUI) of male infertility by
              highly skilled lab personnel, all of whom have completed their
              training in infertility lab techniques.
            </p>
          </div>
          <div className="col-md-4">
            <img src={androlab} alt="andrology lab" />
          </div>
        </div>
        <div className="row align-items-center mb-3">
          <div className="col-md-4 mb-2">
            <img
              src={ot}
              alt="Women lying in operation theatre and doctors surrounding her"
            />
          </div>
          <div className="col-md-8">
            <h2 className="text-blue">
              State of the art Operating Theater room (OT)
            </h2>
            <p>
              We have the modernized OT equipments for both the major and the
              minor cases, all under one roof. Our OT is designed to be a safe
              for the various fertility procedures such as egg pick up, embryo
              transfers, etc.
            </p>
          </div>
        </div>
        <div className="row align-items-center mb-3">
          <div className="col-md-8 mb-2">
            <h2 className="text-blue">Hysteroscopy and Laparoscopy</h2>
            <p>
              These are the minimally invasive procedures done under
              anaesthesia. They help in diagnosing a multitude of uterine and
              ovarian abnormalities and anomalies. They aid in the treatment of
              infertility.
            </p>
          </div>
          <div className="col-md-4">
            <img src={hyst} alt="Doctors performing surgery" />
          </div>
        </div>

        {/* <div className="row align-items-center mb-3">
         <div className="col-md-4 mb-2">
            <img src={blog1} style={{width:"100%",height:'auto'}}/>
         </div>
         <div className="col-md-8">
         <h2 className="text-blue">Others-</h2>
             <ul>
                 <li>Post operative wards (PHOTO)</li>
                 <li>Scan Room(PHOTO)</li>
                 <li>Semen Collection room(PHOTO)</li>
                 <li>Counselling Room(PHOTO)</li>
             </ul>
         </div>
      </div> */}
      </div>
      {/* ---------------------last four card------------------ */}
      <div className="container-fluid my-4">
        <h2 className="text-center py-2 text-blue">Others</h2>
        <div className="container" id="fertolity-card">
          <div className="row justify-content-center">
            <div
              className="card col-lg-3 col-md-2 col-sm-1 mx-2 mb-3"
              style={{ width: "16rem" }}
            >
              <img
                src={couns}
                className="card-img-top"
                alt="Patient counseling room"
              />
              <div className="card-body">
                <h3 className="text-blue text-center">Counselling Room</h3>
                {/* <p className="card-text">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p> */}
              </div>
            </div>
            <div
              className="card col-lg-3 col-md-2 col-sm-1 mx-2 mb-3"
              style={{ width: "16rem" }}
            >
              <img src={scan} className="card-img-top" alt="Scanning roon" />
              <div className="card-body">
                <h3 className="text-blue text-center">Scan Room</h3>
                {/* <p className="card-text">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p> */}
              </div>
            </div>
            <div
              className="card col-lg-3 col-md-2 col-sm-1 mx-2 mb-3"
              style={{ width: "16rem" }}
            >
              <img
                src={semen}
                className="card-img-top"
                alt="men sperm collection"
              />
              <div className="card-body">
                <h3 className="text-blue text-center">
                  Semen Collection Room{" "}
                </h3>
                {/* <p className="card-text">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p> */}
              </div>
            </div>
            <div
              className="card col-lg-3 col-md-2 col-sm-1 mx-2 mb-3"
              style={{ width: "16rem" }}
            >
              <img
                src={opward}
                className="card-img-top"
                alt="Postoperative Wards"
              />
              <div className="card-body">
                <h3 className="text-blue text-center">Post Operative Wards</h3>
                {/* <p className="card-text">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
