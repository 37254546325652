import React from "react";
import doc4 from "../assets/img/doctor/lakshmi-dr.jpg";
import { useLocation, Link } from "react-router-dom";
import CustomTitle from "../CustomTitle";

export default function Lakshmi() {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const helmetDescriptions = {
    titleName:
      "Dr. Lakshmi Nagendra | Trusted Endocrinologist for Hormonal Carex",
    Description:
      "Dr. Lakshmi Nagendra offers expert care in managing diabetes, thyroid, and hormonal disorders. Book your consultation for personalized endocrine health solutions.",
  };

  return (
    <div id="each-doctor">
      <CustomTitle customValue={helmetDescriptions} />
      {/* <div className="py-md-5" style={{ backgroundImage: "url(" + blogHeader + ")", backgroundPosition: 'center', backgroundSize: 'cover', height: '400px' }}>
        <div className="banner-header text-center py-5">
            <h2 style={{ color: '#fff', position: "relative", top: "100px" }}>Dr. DIVYASREE D</h2>
        </div>
    </div> */}
      <br />
      {/* <h6 className="offset-1 my-4 "  style={{ textAlign: "left", color: "black" }}>HOME &gt; MEDIA &gt; Blog</h6> */}
      <nav aria-label="breadcrumb" id="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>

          <li className="breadcrumb-item active" aria-current="page">
            Doctor
          </li>
        </ol>
      </nav>
      {/* -------------------------doctor detail--------------- */}

      <div className="container mt-4">
        <div className="row">
          <div className="col-md-5">
            <div className="card" style={{ maxWidth: "30rem" }}>
              <img src={doc4} className="card-img-top" alt="Dr. Divyashree D" />
            </div>
            <br />
            <br />
            <br />
            <h3>More Info</h3>
            <br />
            <p>
              MBBS, MD General Medicine, DM Endocrinology (GOLD MEDALLIST),
              D.N.B Endocrinology, MRCP (UK)
            </p>
            <br />
            <p>
              Department : <span>Infertility And Ivf</span>
            </p>
            <br />
            {/* <p>Consultations : 15 Minutes per Consultation</p>
            <br /> */}
            <p>Location : Mysore</p>
            <br />

            <span className="badge bg-primary p-2">
              Available on Prior Appointment
            </span>
            <br />
            <p> -Toll-free number @ 1800-425-4500</p>
            <br />
          </div>
          <div className="col-md-7">
            <h1 className="text-blue">Dr. LAKSHMI NAGENDRA</h1>
            <p>
              <strong>Endocrinologist</strong>
            </p>
            <h3>QUALIFICATION</h3>

            <span>
              <ol>
                <li>
                  DM Endocrinology (GOLD MEDALLIST):Amrita Institute of Medical
                  Sciences, Kochi, IndiaSeptember 2017-September 2020
                </li>
                <li>
                  D.N.B Endocrinology: National Board of Examinations, New Delhi
                  March 2021
                </li>
                <li>
                  MRCP (UK): Royal College of Physicians of the United Kingdom
                  September 2022
                </li>
                <li>
                  MD General Medicine: K.S. Hegde Medical Academy, NITTE
                  University, Mangalore, India 2014-2017
                </li>
                <li>
                  MBBS: Sri Devaraj Urs Medical Academy of Higher Education and
                  Research, Kolar, India 2007-2012
                </li>
              </ol>
            </span>
            <br />

            <br />
            <h3>WORK EXPERIENCE</h3>

            <span>
              <ol>
                <li>
                  PG Resident, MD General Medicine, Department of Internal
                  Medicine: K.S. Hegde Medical Academy, Mangalore May 2014-April
                  2017
                </li>
                <li>
                  DM Endocrinology PG Resident, Department of Endocrinology:
                  Amrita Institute of Medical Sciences, Kochi September
                  2017-September 2020
                </li>
                <li>
                  Assistant Professor, Department of Endocrinology: K.S. Hegde
                  Medical Academy, Mangalore November 2020-October 2022
                </li>
                <li>
                  Assistant Professor, Department of Endocrinology: JSS Academy
                  of Higher Education and Research, Mysore October 2022-December
                  2023
                </li>
                <li>
                  Associate Professor and Head of Department, Department of
                  Endocrinology: JSS Academy of Higher Education and Research,
                  Mysore January 2024-till date
                </li>
              </ol>
            </span>
            <br />
            <br />
            <h3>AWARDS & ACHIEVEMENTS</h3>

            <span>
              <ol>
                <li>Pierre Meunier Young Scientist Award, ESCEO-IOF, 2024</li>
                <li>
                  RSSDI ”Young Endocrinologist Par Excellence” Award, 2023
                </li>
                <li>Endocrine Society of India Yuvarathna Award, 2023</li>
                <li>
                  Endocrine Society of India AV Gandhi Award for Excellence in
                  Endocrinology, 2020
                </li>
              </ol>
            </span>
            <br />
            <br />
            <h3>PUBLICATION</h3>
            <ol>
              <li>
                Nagendra L, Fernandez CJ, Pappachan JM. Simultaneous
                pancreas-kidney transplantation for end-stage renal failure in
                type 1 diabetes mellitus: Current perspectives. World Journal of
                Transplantation. 2023 Sep 9;13(5):208.
              </li>
              <li>
                Nagendra L, Mahajan K, Gupta G, Dutta D. Impact of early
                initiation of proprotein convertase subtilisin/kexin type 9
                inhibitors in patients with acute coronary syndrome: A
                systematic review meta-analysis. Indian Heart Journal. 2023 Sep
                29.
              </li>
              <li>
                Nagendra L, Pappachan JM, Fernandez CJ. Artificial intelligence
                in diagnosing thyroid cancer: Recent advances and future
                directions. Artificial Intelligence in Cancer. 2023 Sep
                8;4(1):1-0.
              </li>
              <li>
                Nagendra L. The View from Here: A Woman Early Career
                Endocrinologist in India. Indian Journal of Endocrinology and
                Metabolism. 2023 Mar;27(2):115.
              </li>
              <li>
                Nagendra L, Bhattacharya S, Kalra S, Kapoor N. Metformin in
                COVID-19: Is There a Role Beyond Glycemic Control? International
                Journal of Endocrinology and Metabolism. 2023 Apr 30;21(2).
              </li>
              <li>
                Boro H, Bundela V, Mannar V, Nagendra L, Jain V, Jain B, Kumar
                S, Agstam S. Novel homozygous leptin receptor mutation in an
                infant with monogenic obesity. Pediatric Endocrinology Diabetes
                and Metabolism. 2023 Jun;29(2):118-23.
              </li>
              <li>
                Sharma B, Dey A, George J, Nagendra L, Mittal S, Tewari A, Birla
                A, Prasad A, Aushili M. Low-Dose Glimepiride and Metformin
                Fixed-Dose Combination in treating Maturity-Onset Diabetes of
                the Young Type (MODY): A Cross-Sectional Survey. Clinical
                Diabetology. 2023;12(4):275-7.
              </li>
              <li>
                Nagendra L, Harish BG, Sharma M, Dutta D. Semaglutide and
                cancer: A systematic review and meta-analysis. Diabetes &
                Metabolic Syndrome: Clinical Research & Reviews. 2023 Jul
                26:102834.
              </li>
            </ol>

            <br />
            <br />

            {/* <ol></ol> */}
            {/* <p>
              Dr. Divyasree is practicing obstetrician and gynecologist with
              experience of more than 10 yrs and exclusive experience in
              treating infertile couple for the past 3 years.
            </p>
            <p>
              She completed her MBBS from prestigious Gandhi medical college,
              Hyderabad and did her post graduation in Obstetrics and Gynecology
              from CAIMS Karimnagar, Andra Pradesh. She has done her fellowship
              in reproductive medicine from CRAFTS hospital and research
              institute, Kerala. She is trained in minimal invasive surgeries,
              at Bangalore.
            </p>
            <p>
              Dr. Divyasree is specialized in treating infertile couple with
              PCOS, poor ovarian reserve, recurrent IVF failures and recurrent
              pregnancy loss.
            </p>
            <p>
              She has given good pregnancy rates and take home baby rate in
              infertile couple
            </p>
            <h3>QUALIFICATION</h3>
            <p>MBBS, MS (OBG)</p>
            <p>Fellowship in reproductive medicine</p>
            <h3>WORK EXPERIENCE</h3>
            <p>
              Consultant at Garbhagudi IVF center, Bangalore from 2018 till
              2021.
            </p>
            <p>
              Reproductive medicine unit at CRAFTS hospital, Kerala 2017 t0
              2018.
            </p>
            <p>
              Assistant professor in dept of OBG at PESIMSR, Kuppam.2012 to
              2017.
            </p>
            <h3>RESEARCH AND PUBLICATIONS:</h3>
            <p>
              She has presented and published various clinical papers and case
              reports in national journals and conferences.
            </p>
            <p>
              Testicular sperms versus macs in patients with high dna
              fragmentation index and their ICSI outcome - presentation at
              national fertility conference 2018.
            </p>
            <p>
              Laparoscopic management of large ovarian cyst – published in
              international journal Reprod contracept Obstet Gynecol 2017.
            </p>
            <p>
              Disengagement of the deeply engaged fetal head during caesarean
              section in advanced labour : patwardhan versus push method
              published in international journal reprod contracept obstet
              gynecol 2015.
            </p>
            <h3>MEMBERSHIP:</h3>
            <p>Karnataka Medical Council</p>
            <p>She is a life time member in Indian fertility society.</p> */}
          </div>
        </div>
      </div>
    </div>
  );
}
