import React from "react";
import doc3 from "../assets/img/doctor/dr-chandan-new.jpg";
import { useLocation, Link } from "react-router-dom";
import CustomTitle from "../CustomTitle";

export default function Chandan() {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const helmetDescriptions = {
    titleName: "Dr. Chandan | Senior Embryologist | Kangaroo Care Fertility",
    Description:
      "Meet Dr. Chandan, a Senior Embryologist dedicated to guiding you through the complexities of fertility treatments with compassion and expertise",
  };

  return (
    <div id="each-doctor">
      <CustomTitle customValue={helmetDescriptions} />
      {/* <div
        className="py-md-5"
        style={{
          backgroundImage: "url(" + blogHeader + ")",
          backgroundPosition: "center",
          backgroundSize: "cover",
          height: "400px",
        }}
      >
        <div className="banner-header text-center py-5">
          <h2 style={{ position: "relative", top: "100px", color: "white" }}>
            Dr. CHANDAN
          </h2>
        </div>
      </div> */}
      <br />
      {/* <h6 className="offset-1 my-4 "  style={{ textAlign: "left", color: "black" }}>HOME &gt; MEDIA &gt; Blog</h6> */}
      <nav aria-label="breadcrumb" id="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>

          <li className="breadcrumb-item active" aria-current="page">
            Doctor
          </li>
        </ol>
      </nav>
      {/* -------------------------doctor detail--------------- */}

      <div className="container mt-4">
        <div className="row">
          <div className="col-md-5">
            <div className="card" style={{ maxWidth: "30rem" }}>
              <img src={doc3} className="card-img-top" alt="Dr. Chandan" />
            </div>
            <br />
            <br />
            <br />
            <h3>More Info</h3>
            <br />
            <p>MSc, PHD EmbCol PGD</p>
            <br />
            <p>
              Department : <span>Infertility And Ivf</span>
            </p>
            <br />
            {/* <p>Consultations :  15 Minutes per Consultation</p>
            <br /> */}
            <p>Location : Bangalore</p>
            <br />

            <span className="badge bg-primary p-2">
              Available on Prior Appointment
            </span>
            <br />
            <p> - Toll-free number @ 1800-425-4500</p>
            <br />
          </div>

          <div className="col-md-7">
            <h1 className="text-blue">Dr. CHANDAN</h1>
            <p>
              <strong>SENIOR EMBRYOLOGIST</strong>
            </p>
            <p>
              Dr. Chandan is practicing at Kangaroo Care Women & Children
              Hospital as Senior Embryologist.
            </p>
            <p>
              Dr. Chandan has completed his MSc from Bangalore University and
              PhD from Prescott University. He is an EMBCOL certified and
              registered embryologist by American College of Embryology USA. He
              was recognised by IIFA and honoured as top 100 healthcare achiever
              at Dubai.
            </p>
            <p>
              His interest in teaching embryology enabled him to open an
              Embryology Training academy in Srilanka and he is the Director of
              Lanka embryology training institute. He is also tutoring for PG
              diploma in embryology students at Bangalore university.
            </p>
            <p>
              He got an opportunity to get trained in Crest university
              Singapore, Bangkok for his upgradation in embryology and PGD
              embryo biopsy.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
