import React from "react";
import "./AboutUs.css";

import dimg1 from "./assets/img/aboutus/dimg111.jpg";
import bimg1 from "./assets/img/aboutus/bimg1.jpg";
import bimg2 from "./assets/img/aboutus/bimg2.jpg";
import bimg3 from "./assets/img/aboutus/bimg3.jpeg";
import bimg4 from "./assets/img/aboutus/cost-efficent.jpg";
import doc0 from "./assets/img/doctor/doc01.png";
import shivu from "./assets/img/doctor/shivu.jpg";
import major from "./assets/img/doctor/major.jpg";
import reshma from "./assets/img/doctor/reshma.jpg";
import vidhya from "./assets/img/doctor/vidhya.jpg";
import usha from "./assets/img/doctor/usha.jpg";
import rama from "./assets/img/doctor/rama.jpg";
import praveen from "./assets/img/doctor/praveen.jpg";
import javgal from "./assets/img/doctor/javgal.jpg";
import CustomTitle from "./CustomTitle";
import { useLocation } from "react-router-dom";
export default function AboutUs() {
  const helmetDescriptions = {
    titleName: "About Us | Kangaroo Care Fertility Hospital",
    Description:
      "At Kangaroo Care Fertility, we aim to provide the best and cost effective treatments to our patients in the most ethical and transperant way. Book an appointment.!",
  };
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <div>
      <CustomTitle href="https://kangaroocarefertility.com/about-us" customValue={helmetDescriptions} />
      <div>
        <div
          className="container-fluid "
          style={{
            backgroundImage: "url(" + dimg1 + ")",
            backgroundSize: "cover",
          }}
        >
          <div
            className="offset-md-7 col-md-4 offset-sm-3 col-sm-10  py-3 px-2 rounded  "
            style={{
              textAlign: "justify",
              backgroundColor: "rgba(224, 219, 219, 0.7)",
            }}
            data-aos="fade-up"
          >
            <h1 className="row2-heading-col1 text-center text-blue">
              About Us
            </h1>
            <br></br>
            <div className=" container body-text" id="makeMeSomeHeight">
              <p className="">
                We want to personally welcome you to our family of Kangaroo Care
                Hospitals. You can find your “Journey to Joy” at our Kangaroo
                Care Fertility, with its pleasing ambience, welcoming staff and
                highly experienced fertility specialists. We have a state of art
                embryology and andrology lab with the latest specialized
                equipments. It is the best in Vijaynagar, Bangalore.
              </p>
              <p>
                At Kangaroo Care Fertility, we tirelessly work to provide the
                best, tailored and a cost effective treatment to our patients.
                We provide the utmost care in the most ethical and transparent
                way possible. Together we can create happiness.
              </p>
            </div>
          </div>
        </div>
        {/* -------------section-2---------------- */}

        <div
          className="container-fluid my-5"
          id="about-us-fertility"
          data-aos="fade-up"
        >
          <div className="container mt-3">
            <div className="row justify-content-center">
              <div className="col-md-5 p-3 mx-md-3 mb-2" id="about-sec-1">
                <h2 className=" text-center mb-4 text-blue">
                  Our Mission & Vision
                </h2>
                <hr />
                <div className="mb-2">
                  <p>
                    At Kangaroo care, we believe in providing everyone a Quality
                    of Care such that our measure of success is in the number of
                    smiles we leave behind.
                  </p>
                  <div className="mx-3">
                    <p>
                      <strong> Patients:</strong> To provide the highest level
                      of service and to give them an enjoyable and memorable
                      experience. We consider all our patients as our own family
                      and treat them the way we would want to be treated
                      elsewhere.
                    </p>
                    <p className="col">
                      <strong>Staff:</strong> Our employees are the jewels in
                      our crown. Each one of them helps us deliver the best
                      service and experience.
                    </p>
                  </div>
                  <p>
                    We share utmost respect, care, and courtesy with each one of
                    our staff.
                  </p>
                  <p>
                    We at Kangaroo care would like to be India's most trusted
                    brand in women and child health. We wish to be front-runners
                    in this sector by providing premier quality and high
                    standard healthcare to every mother and child. Our aim is
                    that no mother or child should be deprived of tertiary care
                    in our surroundings.
                  </p>
                </div>
              </div>
              <div className="col-md-5 p-3 mx-md-2 mb-2" id="about-sec-2">
                <h2 className="text-center mb-4 text-blue">Core Values</h2>
                <hr />
                <p>
                  {" "}
                  <i class="fas fa-check-circle me-2"></i> Ethical conduct.
                </p>
                <p>
                  {" "}
                  <i class="fas fa-check-circle me-2"></i> Professional
                  integrity and transparency.
                </p>
                <p>
                  <i class="fas fa-check-circle me-2"></i> Patient-centric
                  quality care with commitment and compassion.
                </p>
                <p>
                  <i class="fas fa-check-circle me-2"></i> We believe in
                  following standards and protocols to ensure that all the
                  patients get the best results.
                </p>
                <p>
                  <i class="fas fa-check-circle me-2"></i> We aim to be a
                  leading source of the research center and pass the benefit to
                  patients with the newer advances and strive for continuous
                  improvement.
                </p>
                <p>
                  <i class="fas fa-check-circle me-2"></i> We will be a learning
                  hospital where we educate and empower the next generation of
                  health care professionals to become leaders in Women and Child
                  health care to their full potential.
                </p>
                <p>
                  <i class="fas fa-check-circle me-2"></i>Accountability: Strict
                  financial control which is based on uncompromising honesty so
                  that we achieve our higher purpose.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* ------------------board of--------------------- */}
        <div>
          <div
            className="container-fluid text-center py-4 bg-light"
            data-aos="fade-up"
          >
            {/* <p style={{ color: "#771fbb", fontSize: "32px", fontWeight: "bold" }}>
              Board of Directors
            </p> */}
            <h2 className="text-center text-blue">Board of Directors</h2>

            <div className="container" id="owl-me-carousel-board">
              <div className="row justify-content-center">
                <div className="col-lg-3 col-sm-6" id="cardOwl">
                  <div className="card m-2">
                    <img
                      src={doc0}
                      className="card-img-top"
                      alt="Dr. Shekar Subbaiah Neonatologist & CEO"
                    />
                    <div className="card-body">
                      <h3 className="card-title text-blue">
                        Dr. SHEKAR SUBBAIAH
                      </h3>
                      <p className="card-text">Founder</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6" id="cardOwl">
                  <div className="card m-2">
                    <img
                      src={rama}
                      className="card-img-top"
                      alt="Dr. Ramapriya Kalkunte Founder"
                    />
                    <div className="card-body">
                      <h3 className="card-title text-blue">
                        Dr. RAMAPRIYA KALKUNTE
                      </h3>
                      <p className="card-text">Founder</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6" id="cardOwl">
                  <div className="card m-2">
                    <img
                      src={praveen}
                      className="card-img-top"
                      alt="Dr. Praveen John Founder"
                    />
                    <div className="card-body">
                      <h3 className="card-title text-blue">Dr. PRAVEEN JOHN</h3>
                      <p className="card-text">Founder</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-sm-6" id="cardOwl">
                  <div className="card m-2">
                    <img
                      src={javgal}
                      className="card-img-top"
                      alt="Dr. Javagal S Suraj Founder"
                    />
                    <div className="card-body">
                      <h3 className="card-title text-blue">
                        Dr. JAVAGAL S SURAJ
                      </h3>
                      <p className="card-text">
                        Representatives of Co-investors
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* -----why choose--------------- */}
        <div className=" container-fluid row my-5" data-aos="fade-up">
          <div className="container-fluid col-12 text-center">
            <h2 className="text-blue">About Our Center</h2>
          </div>
        </div>
      </div>

      <div className="container mb-5" id="About-us-SectionForP">
        <div className="row justify-content-center">
          <div
            className="card col-lg-3 col-md-2 col-sm-1 mx-2 mb-3"
            style={{ width: "15rem" }}
          >
            <img
              src={bimg3}
              className="card-img-top mt-1"
              alt="Kangaroo Care Fertility waiting room"
              style={{ height: "170px" }}
            />
            <div className="card-body">
              <h3 className="card-title">
                Excellent Ambience and Courteous Staff
              </h3>
            </div>
          </div>
          <div
            className="card col-lg-3 col-md-2 col-sm-1 mx-2 mb-3"
            style={{ width: "15rem" }}
          >
            <img
              src={bimg1}
              className="card-img-top mt-1"
              alt="lady in medical laboratory"
              style={{ height: "170px" }}
            />
            <div className="card-body">
              <h3 className="card-title">Highly Qualified Specialists</h3>
            </div>
          </div>

          <div
            className="card col-lg-3 col-md-2 col-sm-1 mx-2 mb-3 "
            style={{ width: "15rem" }}
          >
            <img
              src={bimg2}
              className="card-img-top mt-1"
              alt="Fertiltiy testing laboratory "
              style={{ height: "170px" }}
            />
            <div className="card-body">
              <h3 className="card-title">State Of The Art Lab</h3>
            </div>
          </div>

          <div
            className="card col-lg-3 col-md-2 col-sm-1 mx-2 mb-3"
            style={{ width: "15rem" }}
          >
            <img
              src={bimg4}
              className="card-img-top mt-1"
              alt="Cost Efficient"
              style={{ height: "170px" }}
              id="ethical"
            />
            <div className="card-body">
              <h3 className="card-title">
                Ethical Services at Affordable Prices
              </h3>
            </div>
          </div>
        </div>
      </div>

      {/* --------------------operational team----------------------- */}
      <div data-aos="fade-up">
        <div className="container-fluid text-center py-4 bg-light">
          {/* <p style={{ color: "#771fbb", fontSize: "32px", fontWeight: "bold" }}>
            Operational Team
          </p> */}
          <h2 className="text-center text-blue">Operational Team</h2>

          <div className="container" id="owl-me-carousel-op">
            <div className="row justify-content-center">
              <div className="col-lg-4 col-sm-6" id="cardOwl">
                <div className="card m-2">
                  <img
                    src={shivu}
                    className="card-img-top"
                    alt="Shivu Senior Manager"
                  />
                  <div className="card-body">
                    <h3 className="card-title text-blue">SHIVU</h3>
                    <p className="card-text">
                      Senior Manager - Project
                      <br />
                      Bangalore
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6" id="cardOwl">
                <div className="card m-2">
                  <img
                    src={major}
                    className="card-img-top"
                    alt="Major Joyshree Head Nursing"
                  />
                  <div className="card-body">
                    <h3 className="card-title text-blue">MAJOR JOYSHREE</h3>
                    <p className="card-text">
                      Head - Nursing
                      <br />
                      Bangalore
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6" id="cardOwl">
                <div className="card m-2">
                  <img
                    src={reshma}
                    className="card-img-top"
                    alt="Reshma Senior Manager"
                  />
                  <div className="card-body">
                    <h3 className="card-title text-blue">RESHMA</h3>
                    <p className="card-text">
                      Senior Manager - Account <br />
                      Bangalore
                    </p>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-4 col-sm-6" id="cardOwl">
                <div className="card m-2">
                  <img src={vidhya} className="card-img-top" alt="Vidhya Shree R Senior Manager" />
                  <div className="card-body">
                    <h3 className="card-title text-blue">VIDHYA SHREE R</h3>
                    <p className="card-text">
                      Senior Manager - Human Resourse<br />Bangalore
                    </p>

                  </div>
                </div>
              </div> */}
              <div className="col-lg-4 col-sm-6" id="cardOwl">
                <div className="card m-2">
                  <img
                    src={usha}
                    className="card-img-top"
                    alt="Usha Reddy Fertility Incharge"
                  />
                  <div className="card-body">
                    <h3 className="card-title text-blue">USHA REDDY</h3>
                    <p className="card-text">
                      Fertility Incharge
                      <br />
                      Bangalore
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
