export const BlogSchema = [
  {
    id: 1,
    blogTitle: "Common Misconceptions About Infertility!",
    schema: `<script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://kangaroocarefertility.com/media/blogs/common-misconceptions-about-fertility/4"
      },
      "headline": "Common Misconceptions About Infertility",
      "description": "Infertility! A complex condition where a woman tries to conceive for over a year or longer with no positive results. About 2 in 10 couples from developing countries are affected by infertility. Fertility problems can occur in both women and men. There are certain misconceptions related to infertility that should be cleared. Here we have listed a few common myths about infertility,",
      "image": "https://kangaroocarefertility.com/static/media/common-myths-banner.d5983e81.jpg",  
      "author": {
        "@type": "Organization",
        "name": "Kangaroo Care Fertility",
        "url": "https://kangaroocarefertility.com/"
      },  
      "publisher": {
        "@type": "Organization",
        "name": "Kangaroo Care Fertility",
        "logo": {
          "@type": "ImageObject",
          "url": "https://kangaroocarefertility.com/static/media/kcf_logo.0b66caa0.png"
        }
      },
      "datePublished": "2021-09-16"
    }
    </script>`,
  },
  {
    id: 2,
    blogTitle: "Tips To Boost Your Fertility",
    schema: `<script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://kangaroocarefertility.com/media/blogs/tips-to-boost-your-fertility/5"
      },
      "headline": "Tips To Boost Your Fertility",
      "description": "Parenthood! Every couple’s happiest moment. But due to certain health conditions, couples may have problems with less chance or no chance of experiencing parenthood. There are various infertility treatments for both men and women. Along with the treatment you have to boost your fertility by making small changes in your lifestyle and increase the chances of conception.",
      "image": "https://kangaroocarefertility.com/static/media/blog5.b6ab6b5a.jpg",  
      "author": {
        "@type": "Organization",
        "name": "Kangaroo Care Fertility",
        "url": "https://kangaroocarefertility.com/"
      },  
      "publisher": {
        "@type": "Organization",
        "name": "Kangaroo Care Fertility",
        "logo": {
          "@type": "ImageObject",
          "url": "https://kangaroocarefertility.com/static/media/kcf_logo.0b66caa0.png"
        }
      },
      "datePublished": "2021-09-16"
    }
    </script>`,
  },
  {
    id: 3,
    blogTitle: "Best Food Habits to Enhance Fertility",
    schema: `    <script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://kangaroocarefertility.com/media/blogs/best-food-habits-to-enhance-fertility/6"
      },
      "headline": "Best Food Habits to Enhance Fertility",
      "description": "Every 1 out 10 couples suffers from infertility problems. When it comes to the infertility rate in India, approximately 40% is related to female factors, 40% to malefactors, and 20% to unknown causes. Everything from age, lifestyle, and genetics plays an important role to play when it comes to healthy fertility. Nutrition and healthy body weight play a key role in a couple’s ability to get pregnant. Here are the best foods to improve your fertility:",
      "image": "https://kangaroocarefertility.com/static/media/blog-diet-banner.c6176be3.jpg",  
      "author": {
        "@type": "Organization",
        "name": "Kangaroo Care Fertility",
        "url": "https://kangaroocarefertility.com/"
      },  
      "publisher": {
        "@type": "Organization",
        "name": "Kangaroo Care Fertility",
        "logo": {
          "@type": "ImageObject",
          "url": "https://kangaroocarefertility.com/static/media/kcf_logo.0b66caa0.png"
        }
      },
      "datePublished": "2021-09-16"
    }
    </script>
    `,
  },
  {
    id: 4,
    blogTitle: "Age and Fertility",
    schema: `<script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://kangaroocarefertility.com/media/blogs/age-and-fertility/7"
      },
      "headline": "AGE AND FERTILITY.!",
      "description": "The reproductive potential of females is best between 20 – 30 years of age.
    Females are born with a set of eggs and there is no new production after birth.
    These eggs degenerate over time and the number starts declining from 32 years and more so from 37 years of age. As the age increases the egg number as well as egg quality decreases.",
      "image": "https://kangaroocarefertility.com/static/media/age-&-fertility-banner.a16cdc77.jpg",  
      "author": {
        "@type": "Organization",
        "name": "Kangaroo Care Fertility",
        "url": "https://kangaroocarefertility.com/"
      },  
      "publisher": {
        "@type": "Organization",
        "name": "Kangaroo Care Fertility",
        "logo": {
          "@type": "ImageObject",
          "url": "https://kangaroocarefertility.com/static/media/kcf_logo.0b66caa0.png"
        }
      },
      "datePublished": "2021-11-18"
    }
    </script>
    `,
  },

  {
    id: 5,
    blogTitle: "All You Need To Know About IVF & ICSI Treatment",
    schema: `<script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://kangaroocarefertility.com/media/blogs/all-you-need-to-know-about-ivf-icsi-treatment/11"
      },
      "headline": "All You Need To Know About IVF & ICSI Treatment",
      "description": "Parenthood! One of the happiest moments every couple experiences in their life. While in some cases there can be complications with conceiving due to fertility problems. If a couple has been trying to conceive naturally for over a year or more with no successful pregnancy then it can be termed infertility.",
      "image": "https://kangaroocarefertility.com/static/media/blog-101.737a14dc.jpg",  
      "author": {
        "@type": "Organization",
        "name": "Kangaroo Care Fertility",
        "url": "https://kangaroocarefertility.com/"
      },  
      "publisher": {
        "@type": "Organization",
        "name": "Kangaroo Care Fertility",
        "logo": {
          "@type": "ImageObject",
          "url": "https://kangaroocarefertility.com/static/media/kcf_logo.0b66caa0.png"
        }
      },
      "datePublished": "2022-02-22"
    }
    </script>`,
  },
  {
    id: 6,
    blogTitle: "Causes of Female Infertility",
    schema: ` <script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://kangaroocarefertility.com/media/blogs/causes-of-female-infertility/12"
      },
      "headline": "Causes of Female Infertility",
      "description": "For normal pregnancy to happen there are 4 basic requirements.
      A good number of follicles/eggs in each ovary. Women should be ovulating each cycle.
      Both tubes should be patent.
      The uterus should be normal.
      husband semen parameters should be normal.",
      "image": "https://kangaroocarefertility.com/static/media/12-banner.f6fb2d7a.jpg",  
      "author": {
        "@type": "Organization",
        "name": "Kangaroo Care",
        "url": "https://kangaroocarefertility.com/"
      },  
      "publisher": {
        "@type": "Organization",
        "name": "Kangaroo Care",
        "logo": {
          "@type": "ImageObject",
          "url": "https://kangaroocarefertility.com/static/media/kcf_logo.0b66caa0.png"
        }
      },
      "datePublished": "2022-03-23"
    }
    </script>`,
  },
  {
    id: 7,
    blogTitle: "HOW DOES PCOS AFFECT FERTILITY",
    schema: ` <script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://kangaroocarefertility.com/media/blogs/how-does-pcos-affect-fertility/13"
      },
      "headline": "HOW DOES PCOS AFFECT FERTILITY",
      "description": "Polycystic ovary syndrome (PCOS) is a disorder that is associated with an imbalance in female sex hormones. This imbalance can lead to a variety of symptoms and may affect a woman's fertility.",
      "image": "https://kangaroocarefertility.com/static/media/7-banner.363a127b.jpg",  
      "author": {
        "@type": "Organization",
        "name": "Kangaroo Care",
        "url": "https://kangaroocarefertility.com/"
      },  
      "publisher": {
        "@type": "Organization",
        "name": "Kangaroo Care",
        "logo": {
          "@type": "ImageObject",
          "url": "https://kangaroocarefertility.com/static/media/kcf_logo.0b66caa0.png"
        }
      },
      "datePublished": "2022-03-31"
    }
    </script>`,
  },
  {
    id: 8,
    blogTitle: "Cancer and Fertility Preservation",
    schema: ` <script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://kangaroocarefertility.com/media/blogs/cancer-and-fertility-preservation/14"
      },
      "headline": "Cancer and Fertility Preservation",
      "description": "description": "Fertility preservation (FP) is a critical issue for people of reproductive age, both men and women, or prepubescent boys and girls whose future fertility may be compromised by conditions such as cancers, genetic disorders associated with premature ovarian insufficiencies (POI) like turners, Klinefelter and some non-malignant condition requiring chemotherapy for treatment like benign ovarian tumors, endometriosis, autoimmune disease, hematopoietic or bone marrow transplantation. Delayed marriage especially in women as the ovarian reserve decreases after 32yrs and rapidly after 37yrs.",
      "image": "https://kangaroocarefertility.com/static/media/8-banner.4b44d1de.jpeg",  
      "author": {
        "@type": "Organization",
        "name": "Kangaroo Care",
        "url": "https://kangaroocarefertility.com/"
      },  
      "publisher": {
        "@type": "Organization",
        "name": "Kangaroo Care",
        "logo": {
          "@type": "ImageObject",
          "url": "https://kangaroocarefertility.com/static/media/kcf_logo.0b66caa0.png"
        }
      },
      "datePublished": "2022-04-13"
    }
    </script>`,
  },
  {
    id: 9,
    blogTitle: "Who Need to Go for IVF Treatment",
    schema: ` <script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://kangaroocarefertility.com/media/blogs/who-need-to-go-for-ivf-treatment/15"
      },
      "headline": "Who Need to Go for IVF Treatment",
      "description": "In vitro fertilization (IVF) is the most common and effective type of assisted reproductive technology to help people get pregnant. IVF can help achieve pregnancy when other treatments don't work and also for patients, including those with the following",
      "image": "https://kangaroocarefertility.com/static/media/blog-15.7abf6a66.jpeg",  
      "author": {
        "@type": "Organization",
        "name": "Kangaroo Care",
        "url": "https://kangaroocarefertility.com/"
      },  
      "publisher": {
        "@type": "Organization",
        "name": "Kangaroo Care",
        "logo": {
          "@type": "ImageObject",
          "url": "https://kangaroocarefertility.com/static/media/kcf_logo.0b66caa0.png"
        }
      },
      "datePublished": "2022-04-26"
    }
    </script>`,
  },
  {
    id: 10,
    blogTitle: "Fibroids On Female Fertility And Pregnancy",
    schema: `<script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://kangaroocarefertility.com/media/blogs/fibroids-on-female-fertility-and-pregnancy/16"
      },
      "headline": "Fibroids On Female Fertility And Pregnancy",
      "description": "Fibroids are benign muscular tumors, arising from the uterine musculature.
    Fibroids affect 30-70% of women of reproductive age causing menstrual irregularities, heavy menstrual flow, pain abdomen, infertility, and pressure symptoms on the bladder and bowel.
    Fibroids are present in 5 to 10% of infertile patients and are the sole cause of infertility in 1 to 2.5% of cases.
    Fibroids cause infertility in various ways like blocking the transport of the sperms, blocking the tubes, preventing implantation of the embryo, altered endometrial development, and hormonal milieu.
    Removal of fibroids can improve pregnancy rates by up to 40%.",
      "image": "https://kangaroocarefertility.com/blogs/blog-16.jpeg",  
      "author": {
        "@type": "Organization",
        "name": "Kangaroo Care Fertility",
        "url": "https://kangaroocarefertility.com/"
      },  
      "publisher": {
        "@type": "Organization",
        "name": "Kangaroo Care Fertility",
        "logo": {
          "@type": "ImageObject",
          "url": ""
        }
      },
      "datePublished": "2022-05-25"
    }
    </script>`,
  },
  {
    id: 11,
    blogTitle: "Endometriosis - Symptoms, Causes & Treatment",
    schema: `<script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://kangaroocarefertility.com/media/blogs/endometriosis-symptoms-causes-and-treatment/17"
      },
      "headline": "Endometriosis - Symptoms, Causes & Treatment",
      "description": "Endometriosis is a disorder in which certain tissues resembling the endometrium - the lining of the uterus - grow outside the uterine cavity. It is rare for an endometrial implant to spread beyond your pelvic area, but it is not impossible.",
      "image": "https://kangaroocarefertility.com/blogs/blog-17.jpeg",  
      "author": {
        "@type": "Organization",
        "name": "Kangaroo Care Fertility",
        "url": "https://kangaroocarefertility.com/"
      },  
      "publisher": {
        "@type": "Organization",
        "name": "Kangaroo Care Fertility",
        "logo": {
          "@type": "ImageObject",
          "url": ""
        }
      },
      "datePublished": "2022-06-01"
    }
    </script> `,
  },
  {
    id: 12,
    blogTitle: "Hypothyroidism & Pregnancy..!",
    schema: `
    <script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocarefertility.com/media/blogs/hypothyroidism-pregnancy/8"
  },
  "headline": "Hypothyroidism & Pregnancy..!",
  "description": "Hypothyroidism & Pregnancy..! Well, many of you must be wondering how that is connected, but the fact is that around 2% to 4% of childbearing age women have low thyroid hormone levels. Many women are affected by the fertility issues caused by hypothyroidism.


Thyroid dysfunction is most common in females than in males. Hyperthyroidism and hypothyroidism have extreme effects on estrogen and androgen metabolism. Hypothyroidism causes delayed onset of puberty, menstrual abnormalities, anovulatory cycles, miscarriages, and infertility.",
  "image": "https://kangaroocarefertility.com/static/media/hypothyroidism.f7ca7ace.jpeg",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "url": "https://kangaroocarefertility.com/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "logo": {
      "@type": "ImageObject",
      "url": "https://kangaroocarefertility.com/static/media/kcf_logo.0b66caa0.png"
    }
  },
  "datePublished": "2021-12-14"
}
</script>`,
  },
  {
    id: 13,
    blogTitle: "CAUSES OF INFERTILITY IN MALE",
    schema: `
    <script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocarefertility.com/media/blogs/causes-of-infertility-in-male/9"
  },
  "headline": "CAUSES OF INFERTILITY IN MALE",
  "description": "Infertility problems occur mostly in women..! It is a myth that many people think so, but the fact is out of every 10 couples, at least 4 couples have male infertility problems.
For normal fertility in males, there should be normal sexual function and the sperm parameters should be normal.",
  "image": "https://kangaroocarefertility.com/static/media/infertility-male.c1890a02.jpg",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroo Care Fertility",
    "url": "https://kangaroocarefertility.com/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Kangaroo Care Fertility",
    "logo": {
      "@type": "ImageObject",
      "url": ""
    }
  },
  "datePublished": "2021-12-29"
}
</script>`,
  },
  {
    id: 14,
    blogTitle: "EFFECT OF WEIGHT ON FERTILITY AND PREGNANCY.",
    schema: ` <script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://kangaroocarefertility.com/media/blogs/effect-of-weight-on-fertility-and-pregnancy/10"
      },
      "headline": "EFFECT OF WEIGHT ON FERTILITY AND PREGNANCY.",
      "description": "Both overweight and underweight effects reproductive function in females.",
      "image": "https://kangaroocarefertility.com/static/media/blog-11.9fc7fce4.jpeg",  
      "author": {
        "@type": "Organization",
        "name": "Kangaroo Care Fertility",
        "url": "https://kangaroocarefertility.com/"
      },  
      "publisher": {
        "@type": "Organization",
        "name": "Kangaroo Care Fertility",
        "logo": {
          "@type": "ImageObject",
          "url": ""
        }
      },
      "datePublished": "2022-01-25"
    }
    </script>`,
  },
  {
    id: 15,
    blogTitle: "A Step-by-Step Overview of the IVF Process",
    schema: `<script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://kangaroocarefertility.com/media/blogs/a-step-by-step-overview-of-the-ivf-process/18"
      },
      "headline": "A Step-by-Step Overview of the IVF Process",
      "description": "It is an advanced ART procedure, commonly called as test tube baby, where in the eggs are collected from female partner after stimulating the ovaries with hormonal injection for 10-12 days starting from day 2 of her menstrual cycle and monitoring the growth of the follicles with ultra sound.",
      "image": "https://kangaroocarefertility.com/blogs/blog-18.jpg",  
      "author": {
        "@type": "Organization",
        "name": "Kangaroo care Fertility"
      },  
      "publisher": {
        "@type": "Organization",
        "name": "Kangaroo care Fertility",
        "logo": {
          "@type": "ImageObject",
          "url": "https://kangaroocarefertility.com/static/media/kcf_logo.0b66caa0.png"
        }
      },
      "datePublished": "2022-09-06"
    }
    </script>`,
  },
  {
    id: 16,
    blogTitle: "The 5 Best Ways to Make Your Periods Eco-Friendly",
    schema: `<script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://kangaroocarefertility.com/media/blogs/the-five-best-ways-to-make-your-periods-eco-friendly/19"
      },
      "headline": "The 5 Best Ways to Make Your Periods Eco-Friendly",
      "description": "Periods are red, but you can go green! For the longest time, different people have indulged in discussing menstrual hygiene.",
      "image": "https://kangaroocarefertility.com/blogs/blog-19.jpg",  
      "author": {
        "@type": "Organization",
        "name": "Kangaroo Care Fertility"
      },  
      "publisher": {
        "@type": "Organization",
        "name": "Kangaroo Care Fertility",
        "logo": {
          "@type": "ImageObject",
          "url": "https://kangaroocarefertility.com/static/media/kcf_logo.0b66caa0.png"
        }
      },
      "datePublished": "2022-09-16"
    }
    </script>
    `,
  },

  {
    id: 17,
    blogTitle: "What is the difference between IUI and IVF?",
    schema: `<script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://kangaroocarefertility.com/media/blogs/what-is-the-difference-between-iui-and-ivf/31"
      },
      "headline": "What is the difference between IUI and IVF?",
      "description": "Infertility affects millions of couples worldwide, leaving them seeking various fertility treatment options to realize their dream of parenthood.",
      "image": "https://kangaroocarefertility.com/blogs/blog-31.png",  
      "author": {
        "@type": "Organization",
        "name": "Kangaroo Care",
        "url": "https://kangaroocarefertility.com/"
      },  
      "publisher": {
        "@type": "Organization",
        "name": "Kangaroo Care",
        "logo": {
          "@type": "ImageObject",
          "url": "https://kangaroocarefertility.com/static/media/kcf_logo.0b66caa0.png"
        }
      },
      "datePublished": "2023-08-26"
    }
    </script>
    
    `,
  },

  {
    id: 18,
    blogTitle: "What are the treatments for secondary infertility in men?",
    schema: `
    <script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocarefertility.com/media/blogs/what-are-the-treatments-for-secondary-infertility-in-men/30"
  },
  "headline": "What are the treatments for secondary infertility in men?",
  "description": "What are the treatments for secondary infertility in men? Infertility can be a challenging and emotional journey for couples hoping to conceive.",
  "image": "https://kangaroocarefertility.com/blogs/blog-30.png",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "url": "https://kangaroocarefertility.com/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "logo": {
      "@type": "ImageObject",
      "url": "https://kangaroocarefertility.com/static/media/kcf_logo.0b66caa0.png"
    }
  },
  "datePublished": "2023-08-11"
}
</script>

    `,
  },

  {
    id: 19,
    blogTitle: "Retrieve Eggs Ivf Process",
    schema: `
    <script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocarefertility.com/media/blogs/retrieve-eggs-ivf-process/29"
  },
  "headline": "Retrieve Eggs Ivf Process",
  "description": "The egg retrieval procedure in IVF is one of the crucial procedures. It also impacts the success rate of IVF.",
  "image": "https://kangaroocarefertility.com/blogs/Retrieve-Eggs-Ivf-Process.jpg",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "url": "https://kangaroocarefertility.com/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "logo": {
      "@type": "ImageObject",
      "url": "https://kangaroocarefertility.com/"
    }
  },
  "datePublished": "2023-07-24"
}
</script>

    `,
  },

  {
    id: 20,
    blogTitle: "Ivf Pregnancy Test",
    schema: `
    
<script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocarefertility.com/media/blogs/ivf-pregnancy-test/28"
  },
  "headline": "Ivf Pregnancy Test",
  "description": "In this Blog, Ivf Pregnancy Test. One common question that runs through every mind is when to take a pregnancy test during the IVF cycle",
  "image": "https://kangaroocarefertility.com/blogs/ivf-pregnancy-test.png",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "url": "https://kangaroocarefertility.com/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "logo": {
      "@type": "ImageObject",
      "url": "https://kangaroocarefertility.com/static/media/kcf_logo.0b66caa0.png"
    }
  },
  "datePublished": "2023-07-10"
}
</script>
    `,
  },
  {
    id: 21,
    blogTitle: "What are tests for infertility?",
    schema: `
    <script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocarefertility.com/media/blogs/what-are-tests-for-infertility/27"
  },
  "headline": "What are tests for infertility?",
  "description": "Infertility in women can be of different factors like genetic disorders, history of issues in the reproductive system, consuming alcohol, smoking, and more.",
  "image": "https://kangaroocarefertility.com/blogs/test-for-infertility.png",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "url": "https://kangaroocarefertility.com/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "logo": {
      "@type": "ImageObject",
      "url": "https://kangaroocarefertility.com/static/media/kcf_logo.0b66caa0.png"
    }
  },
  "datePublished": "2023-06-26"
}
</script>

    `,
  },
  {
    id: 22,
    blogTitle: "How do I Track the Different Stages of IVF?",
    schema: `
    <script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocarefertility.com/media/blogs/how-do-i-track-the-different-stages-of-ivf/26"
  },
  "headline": "How do I Track the Different Stages of IVF?",
  "description": "Every woman is unique & beautiful in their way. So, predicting the stages of IVF depends entirely on how your body reacts to the IVF treatment.",
  "image": "https://kangaroocarefertility.com/blogs/dif-stage-of-ivf.png",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "url": "https://kangaroocarefertility.com/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "logo": {
      "@type": "ImageObject",
      "url": "https://kangaroocarefertility.com/static/media/kcf_logo.0b66caa0.png"
    }
  },
  "datePublished": "2023-06-08"
}
</script>

    `,
  },
  {
    id: 23,
    blogTitle: "How to improve your chances of getting pregnant after IVF",
    schema: `
    <script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocarefertility.com/media/blogs/how-to-improve-your-chances-of-getting-pregnant-after-ivf/25"
  },
  "headline": "How to improve your chances of getting pregnant after IVF",
  "description": "How to improve your chances of getting pregnant after IVF. Are you looking to improve your chances of getting pregnant after IVF treatment? If so, you're not alone.",
  "image": "https://kangaroocarefertility.com/blogs/chances-of-getting-pregnancy.png",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "url": "https://kangaroocarefertility.com/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "logo": {
      "@type": "ImageObject",
      "url": "https://kangaroocarefertility.com/static/media/kcf_logo.0b66caa0.png"
    }
  },
  "datePublished": "2023-05-23"
}
</script>


    `,
  },
  {
    id: 24,
    blogTitle: "Common problems that couples face during IVF treatment",
    schema: `
    <script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocarefertility.com/media/blogs/common-problems-that-couples-face-during-ivf-treatment/24"
  },
  "headline": "Common problems that couples face during IVF treatment",
  "description": "Bringing a child into the world is a cherished dream for many couples. However, for those facing infertility issues, the journey to parenthood can be filled with challenges.",
  "image": "https://kangaroocarefertility.com/blogs/common-problems-that-couples-face-during-ivf-treatment.png",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "url": "https://kangaroocarefertility.com/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "logo": {
      "@type": "ImageObject",
      "url": "https://kangaroocarefertility.com/static/media/kcf_logo.0b66caa0.png"
    }
  },
  "datePublished": "2023-05-07"
}
</script>

    `,
  },
  {
    id: 25,
    blogTitle: "Basics of Fertility: Is Male Infertility Treatable?",
    schema: `
    <script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocarefertility.com/media/blogs/basics-of-fertility-is-male-infertility-treatable/23"
  },
  "headline": "Basics of Fertility: Is Male Infertility Treatable?",
  "image": "https://kangaroocarefertility.com/blogs/fertility-male-female.png",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroo  Care",
    "url": "https://kangaroocarefertility.com/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Kangaroo  Care",
    "logo": {
      "@type": "ImageObject",
      "url": "https://kangaroocarefertility.com/static/media/kcf_logo.0b66caa0.png"
    }
  },
  "datePublished": "2023-05-04"
}
</script>

    `,
  },
  {
    id: 26,
    blogTitle: "What is Genetic Counselling and Testing?",
    schema: `
    <script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocarefertility.com/media/blogs/what-is-genetic-counselling-and-testing/32"
  },
  "headline": "What is Genetic Counselling and Testing?",
  "description": "Advances in medical science have given us a deeper understanding of genetics' role in our health. Genetic counseling and testing are powerful tools that offer insights into our genetic makeup, potential health risks, and the impact they might have on our families and us.",
  "image": "https://kangaroocarefertility.com/blogs/blog-32.png",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "url": "https://kangaroocarefertility.com/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "logo": {
      "@type": "ImageObject",
      "url": "https://kangaroocarefertility.com/static/media/kcf_logo.0b66caa0.png"
    }
  },
  "datePublished": "2023-10-12"
}
</script>


    `,
  },
  {
    id: 27,
    blogTitle: "Process of Ovulation Induction",
    schema: `
    <script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocarefertility.com/media/blogs/process-of-ovulation-induction/33"
  },
  "headline": "Process of Ovulation Induction",
  "description": "For individuals and couples hoping to conceive, the journey can sometimes include fertility challenges that require medical intervention.",
  "image": "https://kangaroocarefertility.com/blogs/blog-33.png",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "url": "https://kangaroocarefertility.com/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Kangaroo Care",
    "logo": {
      "@type": "ImageObject",
      "url": "https://kangaroocarefertility.com/static/media/kcf_logo.0b66caa0.png"
    }
  },
  "datePublished": "2023-09-20"
}
</script>

    `,
  },

  {
    id: 28,
    blogTitle: "What are the benefits of IUI?",
    schema: `<script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://kangaroocarefertility.com/media/blogs/what-are-the-benefits-of-iui/37"
      },
      "headline": "What are the benefits of IUI?",
      "description": "In the realm of fertility treatments, Intrauterine Insemination (IUI) stands out as a popular and effective option for couples facing challenges in conceiving naturally.",
      "image": "https://kangaroocarefertility.com/blogs/blog-37.jpg",  
      "author": {
        "@type": "Organization",
        "name": "Kangaroo Care Fertility",
        "url": "https://kangaroocarefertility.com/"
      },  
      "publisher": {
        "@type": "Organization",
        "name": "Kangaroo Care Fertility",
        "logo": {
          "@type": "ImageObject",
          "url": "https://kangaroocarefertility.com/static/media/kcf_logo.0b66caa0.png"
        }
      },
      "datePublished": "2023-11-23"
    }
    </script>
    `,
  },
  {
    id: 29,
    blogTitle: "What are the most common fertility issues in men?",
    schema: `<script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://kangaroocarefertility.com/media/blogs/what-are-the-most-common-fertility-issues-in-men/36"
      },
      "headline": "What are the most common fertility issues in men?",
      "description": "Regarding fertility, both men and women play integral roles in the conception process. While discussions often center around female fertility, it's equally important to address common issues affecting male fertility.",
      "image": "https://kangaroocarefertility.com/blogs/blog-37.jpg",  
      "author": {
        "@type": "Organization",
        "name": "Kangaroo Care Fertility",
        "url": "https://kangaroocarefertility.com/"
      },  
      "publisher": {
        "@type": "Organization",
        "name": "Kangaroo Care Fertility",
        "logo": {
          "@type": "ImageObject",
          "url": "https://kangaroocarefertility.com/static/media/kcf_logo.0b66caa0.png"
        }
      },
      "datePublished": "2023-11-12"
    }
    </script>
    
    `,
  },
  {
    id: 30,
    blogTitle: "How to Increase Sperm Count: A Guide to Men's Health",
    schema: `<script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://kangaroocarefertility.com/media/blogs/how-to-increase-sperm-count-a-guide-to-mens-health/41"
      },
      "headline": " How to Increase Sperm Count: A Guide to Men's Health ",
      "description": "The road to parenthood requires an understanding of the complexities involved in reproductive health issues, not only for women but also for men.",
      "image": "https://kangaroocarefertility.com/blogs/blog-41.jpg",  
      "author": {
        "@type": "Organization",
        "name": "Kangaroo Care Fertility",
        "url": "https://kangaroocarefertility.com/"
      },  
      "publisher": {
        "@type": "Organization",
        "name": "Kangaroo Care Fertility",
        "logo": {
          "@type": "ImageObject",
          "url": "https://kangaroocarefertility.com/static/media/kcf_logo.0b66caa0.png"
        }
      },
      "datePublished": "2024-01-24"
    }
</script>    
    `,
  },
  {
    id: 31,
    blogTitle: "Causes for Infertility",
    schema: `<script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://kangaroocarefertility.com/media/blogs/causes-for-infertility/40"
      },
      "headline": "Unlocking Infertility Causes: Navigating Complex Repro Challenges ",
      "description": "  Discover solutions to infertility hurdles with our expert guidance. Uncover the causes and navigate complex reproductive challenges effortlessly.",
      "image": "https://kangaroocarefertility.com/blogs/blog-40.jpg",  
      "author": {
        "@type": "Organization",
        "name": "Kangaroo Care Fertility",
        "url": "https://kangaroocarefertility.com/"
      },  
      "publisher": {
        "@type": "Organization",
        "name": "Kangaroo Care Fertility",
        "logo": {
          "@type": "ImageObject",
          "url": "https://kangaroocarefertility.com/static/media/kcf_logo.0b66caa0.png"
        }
      },
      "datePublished": "2024-01-24"
    }
</script>
    
    `,
  },
  {
    id: 32,
    blogTitle: "Fertility health tips",
    schema: `<script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://kangaroocarefertility.com/media/blogs/fertility-health-tips/39"
      },
      "headline": "Optimizing Fertility: Top Tips for Your Reproductive Wellness  ",
      "description": "  Unlock fertility wellness at Kangaroo Care Fertility! Explore expert tips for a healthier fertility journey. Your path to parenthood starts here.",
      "image": "https://kangaroocarefertility.com/blogs/blog-39.jpg",  
      "author": {
        "@type": "Organization",
        "name": "Kangaroo Care Fertility",
        "url": "https://kangaroocarefertility.com/"
      },  
      "publisher": {
        "@type": "Organization",
        "name": "Kangaroo Care Fertility",
        "logo": {
          "@type": "ImageObject",
          "url": "https://kangaroocarefertility.com/static/media/kcf_logo.0b66caa0.png"
        }
      },
      "datePublished": "2023-12-22"
    }
</script>
    
    `,
  },
  {
    id: 33,
    blogTitle: "Myths about infertility",
    schema: `<script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://kangaroocarefertility.com/media/blogs/myths-about-infertility/38"
      },
      "headline": " Fertility Unveiled: Busting 5 Myths You Thought Were True  ",
      "description": " Uncover fertility truths and dispel myths at Kangaroo Care Fertility. Explore our blog for expert insights on infertility. Your journey starts here.",
      "image": "https://kangaroocarefertility.com/blogs/blog-38.jpg",  
      "author": {
        "@type": "Organization",
        "name": "Kangaroo Care Fertility",
        "url": "https://kangaroocarefertility.com/"
      },  
      "publisher": {
        "@type": "Organization",
        "name": "Kangaroo Care Fertility",
        "logo": {
          "@type": "ImageObject",
          "url": "https://kangaroocarefertility.com/static/media/kcf_logo.0b66caa0.png"
        }
      },
      "datePublished": "2023-12-08"
    }
</script>
    
    `,
  },
  {
    id: 34,
    blogTitle: "How to Improve Cervical Mucus Fertility",
    schema: `<script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://kangaroocarefertility.com/media/blogs/how-to-improve-cervical-mucus-fertility/22"
      },
      "headline": " Boost Fertility: Enhance Cervical Mucus with Proven Methods  ",
      "description": "The process of 'freezing' embryos enables people to keep them for later use. Additionally, unfertilized eggs can be frozen.",
      "image": "https://kangaroocarefertility.com/blogs/blog-22.png",  
      "author": {
        "@type": "Organization",
        "name": "Kangaroo Care Fertility",
        "url": "https://kangaroocarefertility.com/"
      },  
      "publisher": {
        "@type": "Organization",
        "name": "Kangaroo Care Fertility",
        "logo": {
          "@type": "ImageObject",
          "url": "https://kangaroocarefertility.com/static/media/kcf_logo.0b66caa0.png"
        }
      },
      "datePublished": "2023-04-05"
    }
</script>
    
    `,
  },
  {
    id: 35,
    blogTitle: "Embryo freezing: What you need to know",
    schema: `<script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://kangaroocarefertility.com/media/blogs/embryo-freezing-what-you-need-to-know/21"
      },
      "headline": "  Unlocking Fertility: Essential Insights on Embryo Freezing   ",
      "description": "The process of 'freezing' embryos enables people to keep them for later use. Additionally, unfertilized eggs can be frozen.",
      "image": "https://kangaroocarefertility.com/blogs/blog-21.jpg",  
      "author": {
        "@type": "Organization",
        "name": "Kangaroo Care Fertility",
        "url": "https://kangaroocarefertility.com/"
      },  
      "publisher": {
        "@type": "Organization",
        "name": "Kangaroo Care Fertility",
        "logo": {
          "@type": "ImageObject",
          "url": "https://kangaroocarefertility.com/static/media/kcf_logo.0b66caa0.png"
        }
      },
      "datePublished": "2022-10-31"
    }
</script>

    `,
  },
  {
    id: 36,
    blogTitle: "Treatment Options For Male Infertility",
    schema: `<script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://kangaroocarefertility.com/media/blogs/treatment-options-for-male-infertility/20"
      },
      "headline": "  Revolutionizing Fertility: Male Infertility Treatment Choices ",
      "description": "The treatment options for male infertility depends upon the underlying cause.",
      "image": "https://kangaroocarefertility.com/blogs/blog-20.jpg",  
      "author": {
        "@type": "Organization",
        "name": "Kangaroo Care Fertility",
        "url": "https://kangaroocarefertility.com/"
      },  
      "publisher": {
        "@type": "Organization",
        "name": "Kangaroo Care Fertility",
        "logo": {
          "@type": "ImageObject",
          "url": "https://kangaroocarefertility.com/static/media/kcf_logo.0b66caa0.png"
        }
      },
      "datePublished": "2022-10-13"
    }
</script>

    `,
  },
  {
    id: 37,
    blogTitle: "Poor Ovarion Reserve(POR)",
    schema: `<script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://kangaroocarefertility.com/media/blogs/poor-ovarion-reserve/3"
      },
      "headline": "  Unlocking Potential: Poor Ovarian Reserve (POR) Solutions ",
      "description": "POR indicates a reduction in quantity and quality of oocytes (eggs) in women of reproductive age group.",
      "image": "https://kangaroocarefertility.com/blogs/blog-por.jpg",  
      "author": {
        "@type": "Organization",
        "name": "Kangaroo Care Fertility",
        "url": "https://kangaroocarefertility.com/"
      },  
      "publisher": {
        "@type": "Organization",
        "name": "Kangaroo Care Fertility",
        "logo": {
          "@type": "ImageObject",
          "url": "https://kangaroocarefertility.com/static/media/kcf_logo.0b66caa0.png"
        }
      },
      "datePublished": "2021-05-29"
    }
</script>

    `,
  },
  {
    id: 38,
    blogTitle: "Polycystic Ovary Syndrome (PCOS)",
    schema: `<script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://kangaroocarefertility.com/media/blogs/polycystic-ovary-syndrome/2"
      },
      "headline": "  Unlocking Wellness: Managing Polycystic Ovary Syndrome (PCOS) ",
      "description": "Symptoms of polycystic ovarian syndrome include polycystic ovaries, hormonal imbalance, and irregular periods. Research available therapies.",
      "image": "https://kangaroocarefertility.com/blogs/blog-pcos.jpg",  
      "author": {
        "@type": "Organization",
        "name": "Kangaroo Care Fertility",
        "url": "https://kangaroocarefertility.com/"
      },  
      "publisher": {
        "@type": "Organization",
        "name": "Kangaroo Care Fertility",
        "logo": {
          "@type": "ImageObject",
          "url": "https://kangaroocarefertility.com/static/media/kcf_logo.0b66caa0.png"
        }
      },
      "datePublished": "2021-05-29"
    }
</script>
    
    `,
  },
  {
    id: 39,
    blogTitle: "Andrology",
    schema: `<script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://kangaroocarefertility.com/media/blogs/andrology1/1"
      },
      "headline": "  Andrology Unveiled: Dive into Male Fertility Excellence ",
      "description": " Unlock male fertility potential with insights into andrology and semen analysis. Optimize reproductive health for a fulfilling family journey.",
      "image": "https://kangaroocarefertility.com/blogs/blog-andrology.jpg",  
      "author": {
        "@type": "Organization",
        "name": "Kangaroo Care Fertility",
        "url": "https://kangaroocarefertility.com/"
      },  
      "publisher": {
        "@type": "Organization",
        "name": "Kangaroo Care Fertility",
        "logo": {
          "@type": "ImageObject",
          "url": "https://kangaroocarefertility.com/static/media/kcf_logo.0b66caa0.png"
        }
      },
      "datePublished": "2021-05-29"
    }
</script>
    
    `,
  },
  {
    id: 40,
    blogTitle:
      "The Right Choice: A Fertility Specialist or a Gynecologist - When and Why?",
    schema: `<script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocarefertility.com/media/blogs/the-right-choice-a-fertility-specialist-or-a-gynecologist-when-and-why/46"
  },
  "headline": "The Right Choice: A Fertility Specialist or a Gynecologist - When and Why?",
  "description": "The journey of parenthood is an exhilarating yet complex endeavor for many individuals and couples. Understanding the role of fertility specialists in this process is crucial in navigating the path to conception with knowledge,confidence, and support. This comprehensive guide explores fertility specialists' defining characteristics, roles, and importance.",
  "image": "https://kangaroocarefertility.com/blogs/blog-46.jpg",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroocare Fertility",
    "url": "https://kangaroocarefertility.com/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Kangaroocare Fertility",
    "logo": {
      "@type": "ImageObject",
      "url": "https://kangaroocarefertility.com/static/media/kcf_logo.0b66caa0.png"
    }
  },
  "datePublished": "2024-02-05"
}
</script>
    
    `,
  },
  {
    id: 41,
    blogTitle: "Fertility Under Pressure: Stress and Infertility Explained",
    schema: `<script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocarefertility.com/media/blogs/fertility-under-pressure-stress-and-infertility-explained/45"
  },
  "headline": "Fertility Under Pressure: Stress and Infertility Explained",
  "description": "Embarking on the journey to parenthood is a deeply personal and often eagerly anticipated milestone for many individuals and couples. However, for some, this path may be fraught with challenges, chief among them being infertility.Infertility, characterized by the inability to conceive after a prolonged period of trying, can be a complex and emotionally taxing experience.",
  "image": "https://kangaroocarefertility.com/blogs/blog-45.jpg",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroocare Fertility",
    "url": "https://kangaroocarefertility.com/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Kangaroocare Fertility",
    "logo": {
      "@type": "ImageObject",
      "url": "https://kangaroocarefertility.com/static/media/kcf_logo.0b66caa0.png"
    }
  },
  "datePublished": "2024-02-05"
}
</script>
    
    `,
  },
  {
    id: 42,
    blogTitle: "Infertility Journey: Finding Hope, Healing, and Parenthood",
    schema: `<script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocarefertility.com/media/blogs/infertility-journey-finding-hope-healing-and-parenthood/44"
  },
  "headline": "Infertility Journey: Finding Hope, Healing, and Parenthood",
  "description": "Infertility can be a profoundly challenging journey for individuals or couples hoping to conceive. Understanding the complexities surrounding infertility is crucial in navigating this path effectively. Continue reading the blog to learn more about infertility, including its causes, symptoms, emotional impact, and available treatment options. By gaining insight into this topic, individuals can find solace, support, and hope as they journey to parenthood.",
  "image": "https://kangaroocarefertility.com/blogs/blog-44.jpg",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroocare Fertility",
    "url": "https://kangaroocarefertility.com/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Kangaroocare Fertility",
    "logo": {
      "@type": "ImageObject",
      "url": "https://kangaroocarefertility.com/static/media/kcf_logo.0b66caa0.png"
    }
  },
  "datePublished": "2024-02-05"
}
</script>
    
    `,
  },
  {
    id: 43,
    blogTitle: "PCOS and Fertility: Myths and Facts",
    schema: `<script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocarefertility.com/media/blogs/pcos-and-fertility-myths-and-facts/43"
  },
  "headline": "PCOS and Fertility: Myths and Facts",
  "description": "Polycystic Ovary Syndrome (PCOS) is a prevalent hormonal disorder affecting women during their reproductive years,characterized by various symptoms such as irregular menstrual cycles, excess androgen levels, and polycystic ovaries.Despite its common occurrence, there remains considerable confusion and misinformation surrounding its effects onfertility. This blog seeks to dispel myths and offer clarity regarding the relationship between PCOS and fertility.",
  "image": "https://kangaroocarefertility.com/blogs/blog-43.jpg",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroocare Fertility",
    "url": "https://kangaroocarefertility.com/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Kangaroocare Fertility",
    "logo": {
      "@type": "ImageObject",
      "url": "https://kangaroocarefertility.com/static/media/kcf_logo.0b66caa0.png"
    }
  },
  "datePublished": "2024-02-05"
}
</script>
    
    `,
  },
  {
    id: 44,
    blogTitle:
      "Aging and Infertility in Men: Exploring the Causes and Solutions",
    schema: `<script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocarefertility.com/media/blogs/aging-and-infertility-in-men-exploring-the-causes-and-solutions/42"
  },
  "headline": "Aging and Infertility in Men: Exploring the Causes and Solutions",
  "description": "Infertility is a common problem that affects millions of couples worldwide, and while it's often associated with women,it's essential to recognize that men can also experience fertility problems. In this blog, we'll delve into the topic ofaging and infertility in men, exploring the causes and potential solutions for this increasingly common issue.",
  "image": "https://kangaroocarefertility.com/blogs/blog-42.jpg",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroocare Fertility",
    "url": "https://kangaroocarefertility.com/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Kangaroocare Fertility",
    "logo": {
      "@type": "ImageObject",
      "url": "https://kangaroocarefertility.com/static/media/kcf_logo.0b66caa0.png"
    }
  },
  "datePublished": "2024-02-05"
}
</script>
    
    `,
  },
  {
    id: 45,
    blogTitle: "Polycystic Ovary Syndrome (PCOS)",
    schema: `<script type="application/ld+json">
    {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://kangaroocarefertility.com/media/blogs/polycystic-ovary-syndrome/2"
      },
      "headline": "  Unlocking Wellness: Managing Polycystic Ovary Syndrome (PCOS) ",
      "description": "Symptoms of polycystic ovarian syndrome include polycystic ovaries, hormonal imbalance, and irregular periods. Research available therapies.",
      "image": "https://kangaroocarefertility.com/blogs/blog-pcos.jpg",  
      "author": {
        "@type": "Organization",
        "name": "Kangaroo Care Fertility",
        "url": "https://kangaroocarefertility.com/"
      },  
      "publisher": {
        "@type": "Organization",
        "name": "Kangaroo Care Fertility",
        "logo": {
          "@type": "ImageObject",
          "url": "https://kangaroocarefertility.com/static/media/kcf_logo.0b66caa0.png"
        }
      },
      "datePublished": "2021-05-29"
    }
</script>
    
    `,
  },
  {
    id: 46,
    blogTitle:
      "Egg Donor for IVF: Understanding the Process and Its Importance",
    schema: `<script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocarefertility.com/media/blogs/egg-donor-for-ivf-understanding-the-process-and-its-importance/47"
  },
  "headline": "Egg Donor for IVF: Understanding the Process and Its Importance",
  "description": "In the aspect of reproductive assistance technology, In Vitro Fertilization (IVF) serves as a light of hope for manypeople who have problems corresponding with fertility. The IVF (in vitro fertilization) has presented varioustechniques, but the use of donor eggs (the egg that is donated) has stood out as a powerful method that opens the pathfor a new life. The focus of this article is thus on the complexities behind the donor egg IVF, where the emphasis islaid on its importance and what steps to follow when going through the process.",
  "image": "https://kangaroocarefertility.com/blogs/blog-47.jpg",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroocare Fertility",
    "url": "https://kangaroocarefertility.com/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Kangaroocare Fertility",
    "logo": {
      "@type": "ImageObject",
      "url": "https://kangaroocarefertility.com/static/media/kcf_logo.0b66caa0.png"
    }
  },
  "datePublished": "2024-03-18"
}
</script>
    
    `,
  },
  {
    id: 47,
    blogTitle:
      "Varicocele and Male Fertility: Navigating the Path to Parenthood",
    schema: `<script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocarefertility.com/media/blogs/varicocele-and-male-fertility-navigating-the-path-to-parenthood/48"
  },
  "headline": "Varicocele and Male Fertility: Navigating the Path to Parenthood",
  "description": "Enlarged veins in the scrotum, known as varicocele infertility, is a frequently found problem that very often remainsunnoticed in male infertility medical measures. Notwithstanding, varicocele might not manifest itself with apparentsymptoms.",
  "image": "https://kangaroocarefertility.com/blogs/blog-48.jpg",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroocare Fertility",
    "url": "https://kangaroocarefertility.com/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Kangaroocare Fertility",
    "logo": {
      "@type": "ImageObject",
      "url": "https://kangaroocarefertility.com/static/media/kcf_logo.0b66caa0.png"
    }
  },
  "datePublished": "2024-03-18"
}
</script>
    
    `,
  },
  {
    id: 48,
    blogTitle:
      "Understanding the Ovarian Follicle and its Importance in Fertility",
    schema: `<script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocarefertility.com/media/blogs/understanding-the-ovarian-follicle-and-its-importance-in-fertility/49"
  },
  "headline": "Understanding the Ovarian Follicle and its Importance in Fertility",
  "description": "The path to conception is complicated but also naturally beautiful. It is a process governed by the body. As if in acomplex and remarkable machine, the central piece is the ovarian follicle, a tiny but critically important feature inthe female reproductive system. This special article is devoted to the role and work of the follicle in the ovary, wherethreats to fertility are present.",
  "image": "https://kangaroocarefertility.com/blogs/blog-49.jpg",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroocare Fertility",
    "url": "https://kangaroocarefertility.com/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Kangaroocare Fertility",
    "logo": {
      "@type": "ImageObject",
      "url": "https://kangaroocarefertility.com/static/media/kcf_logo.0b66caa0.png"
    }
  },
  "datePublished": "2024-03-18"
}
</script>
    
    `,
  },
  {
    id: 49,
    blogTitle:
      "Quality of Egg in IVF: Evaluating Methods for Improving Egg Quality",
    schema: `<script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocarefertility.com/media/blogs/quality-of-egg-in-ivf-evaluating-methods-for-improving-egg-quality/50"
  },
  "headline": "Quality of Egg in IVF: Evaluating Methods for Improving Egg Quality",
  "description": "In vitro fertilization egg quality is crucial to the clinic's performance, as it is one of the most important volumes ofthis process. For those who struggle with infertility, knowing or improving their egg quality can dramatically enhancetheir chances of pregnancy. This paper discusses the complex nature of AFQ and analyzes methods of assessing,understanding, and boosting it.",
  "image": "https://kangaroocarefertility.com/blogs/blog-50.jpg",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroocare Fertility",
    "url": "https://kangaroocarefertility.com/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Kangaroocare Fertility",
    "logo": {
      "@type": "ImageObject",
      "url": "https://kangaroocarefertility.com/static/media/kcf_logo.0b66caa0.png"
    }
  },
  "datePublished": "2024-03-18"
}
</script>
    
    `,
  },
  {
    id: 50,
    blogTitle: "Fibroids and Fertility: Treatment Options and Considerations",
    schema: `<script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocarefertility.com/media/blogs/fibroids-and-fertility-treatment-options-and-considerations/51"
  },
  "headline": "Fibroids and Fertility: Treatment Options and Considerations",
  "description": "In contrast, uterine fibroids, a disorder occurring in every fifth woman, are a few conditions that can complicate thefertility issue and cause problems, especially in reproductive health. The medical crew must be aware of the exactphysiological details of the biopsy.",
  "image": "https://kangaroocarefertility.com/blogs/blog-51.jpg",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroocare Fertility",
    "url": "https://kangaroocarefertility.com/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Kangaroocare Fertility",
    "logo": {
      "@type": "ImageObject",
      "url": "https://kangaroocarefertility.com/static/media/kcf_logo.0b66caa0.png"
    }
  },
  "datePublished": "2024-03-18"
}
</script>
    
    `,
  },
  {
    id: 51,
    blogTitle:
      "Bleeding During IVF Pregnancy: What Causes It and When to Worry",
    schema: `<script type="application/ld+json">
{
   "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocarefertility.com/media/blogs/bleeding-during-ivf-pregnancy-what-causes-it-and-when-to-worry/52"
  },
  "headline": "Bleeding During IVF Pregnancy: What Causes It and When to Worry",
  "description": "Pregnancy is filled with excitement, anticipation, and, sometimes, unexpected challenges. For couples undergoing invitro fertilization (IVF), the joy of conceiving can be accompanied by concerns about bleeding during pregnancy. In this comprehensive guide, we delve into the various aspects of bleeding during IVF pregnancy, including its causes, what is considered normal, and when to seek medical attention.",
  "image": "https://kangaroocarefertility.com/blogs/blog-52.jpg",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroo Care Fertility",
    "url": "https://kangaroocarefertility.com/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Kangaroo Care Fertility",
    "logo": {
      "@type": "ImageObject",
      "url": "https://kangaroocarefertility.com/static/media/kcf_logo.0b66caa0.png"
    }
  },
  "datePublished": "2024-04-04"

}
</script>
    
    `,
  },
  {
    id: 52,
    blogTitle:
      "Cervical Stenosis and Infertility: Causes, Risks, and Solutions",
    schema: `<script type="application/ld+json">
{
   "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocarefertility.com/media/blogs/cervical-stenosis-and-infertility-causes-risks-and-solutions/53"
  },
  "headline": "Cervical Stenosis and Infertility: Causes, Risks, and Solutions",
  "description": "Cervical stenosis, a condition characterized by the narrowing of the cervix, is often overlooked in discussions about fertility. However, its effects can be profound, affecting the ability of both men and women to conceive. Recognizing the factors contributing to cervical stenosis and exploring possible remedies is essential for those facing challenges in starting a family.",
  "image": "https://kangaroocarefertility.com/blogs/blog-53.jpg",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroo Care Fertility",
    "url": "https://kangaroocarefertility.com/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Kangaroo Care Fertility",
    "logo": {
      "@type": "ImageObject",
      "url": "https://kangaroocarefertility.com/static/media/kcf_logo.0b66caa0.png"
    }
  },
  "datePublished": "2024-04-08"

}
</script>
    
    `,
  },
  {
    id: 53,
    blogTitle: "Boosting Conception Naturally: Exploring the Fertility Diet",
    schema: `<script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocarefertility.com/media/blogs/boosting-conception-naturally-exploring-the-fertility-diet/54"
  },
  "headline": "Boosting Conception Naturally: Exploring the Fertility Diet",
  "description": "The journey to motherhood is often marked by various challenges, with fertility being a significant concern for many couples. While medical interventions offer solutions, there's growing interest in natural methods to enhance fertility, with one such approach being the fertility diet. In this comprehensive guide, we delve into the intricacies of boosting conception naturally through fertility dietary adjustments, exercise, and lifestyle changes.",
  "image": "https://kangaroocarefertility.com/blogs/blog-54.jpg",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroo Care Fertility",
    "url": "https://kangaroocarefertility.com/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Kangaroo Care Fertility",
    "logo": {
      "@type": "ImageObject",
      "url": "https://kangaroocarefertility.com/static/media/kcf_logo.0b66caa0.png"
    }
  },
  "datePublished": "2024-04-16"

}
</script>
    
    `,
  },
  {
    id: 54,
    blogTitle: "Foods to Avoid After Embryo Transfer: A Comprehensive Guide",
    schema: `<script type="application/ld+json">
{
   "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocarefertility.com/media/blogs/foods-to-avoid-after-embryo-transfer-a-comprehensive-guide/55"
  },
  "headline": "Foods to Avoid After Embryo Transfer: A Comprehensive Guide",
  "description": "Embryo transfer is a critical stage in the journey of assisted reproduction. During this delicate time, paying close attention to your diet is essential to optimize the chances of successful implantation and pregnancy. While there's no one-size-fits-all approach, confident dietary choices can support or hinder the process. In this comprehensive guide, we'll delve into the foods to avoid after embryo transfer, ensuring you're equipped with the knowledge to make the best nutritional decisions during this crucial period.",
  "image": "https://kangaroocarefertility.com/blogs/blog-55.jpg",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroo care Fertility",
    "url": "https://kangaroocarefertility.com/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Kangaroo care Fertility",
    "logo": {
      "@type": "ImageObject",
      "url": "https://kangaroocarefertility.com/static/media/kcf_logo.0b66caa0.png"
    }
  },
  "datePublished": "2024-04-20"

}
</script>
    
    `,
  },
  {
    id: 55,
    blogTitle: "Blocked Fallopian Tubes: Symptoms and Treatment Options",
    schema: `<script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocarefertility.com/media/blogs/blocked-fallopian-tubes-symptoms-and-treatment-options/56"
  },
  "headline": "Blocked Fallopian Tubes: Symptoms and Treatment Options",
  "description": "The obstruction of the fallopian tubes is a significant problem for the ones trying to get pregnant. They suffer hold-ups that are caused by so-called tubal occlusion. The fallopian tubes hold an important place in reproduction because they reach the egg from the ovaries to the uterus. In most cases, when these tubes are blocked, pregnancy cannot occur due to the disruption of fertilization and implantation processes, thus becoming the cause of infertility.",
  "image": "https://kangaroocarefertility.com/blogs/blog-56.jpg",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroo Care Fertility",
    "url": "https://kangaroocarefertility.com/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Kangaroo Care Fertility",
    "logo": {
      "@type": "ImageObject",
      "url": "https://kangaroocarefertility.com/static/media/kcf_logo.0b66caa0.png"
    }
  },
  "datePublished": "2024-04-25"

}
</script>
    
    `,
  },
  {
    id: 56,
    blogTitle: "Sperm Required for IUI: Factors Affecting Success",
    schema: `<script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocarefertility.com/media/blogs/sperm-required-for-iui-factors-affecting-success/57"
  },
  "headline": "Sperm Required for IUI: Factors Affecting Success",
  "description": "Embarking on the journey to parenthood is a momentous occasion for many couples, yet it can also be fraught with challenges, particularly for those facing fertility issues. In such instances, modern advancements in reproductive technology offer a ray of hope, providing innovative solutions like Intrauterine Insemination (IUI). However, the success of IUI hinges significantly on various factors, with the quality and quantity of sperm being paramount among them.",
  "image": "https://kangaroocarefertility.com/blogs/blog-57.jpg",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroo Care Fertility",
    "url": "https://kangaroocarefertility.com/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Kangaroo Care Fertility",
    "logo": {
      "@type": "ImageObject",
      "url": "https://kangaroocarefertility.com/static/media/kcf_logo.0b66caa0.png"
    }
  },
  "datePublished": "2024-05-06"

}
</script>
    
    `,
  },
  {
    id: 57,
    blogTitle: "Cornual Block in Fallopian Tube: Impact on Fertility",
    schema: `<script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocarefertility.com/media/blogs/cornual-block-in-fallopian-tube-impact-on-fertility/58"
  },
  "headline": "Cornual Block in Fallopian Tube: Impact on Fertility",
  "description": "Embarking on the pregnancy journey towards parenthood can be a significant yet often challenging path for many couples. When confronted with fertility issues, such as blocked fallopian tubes, the journey can become even more perplexing. Among the myriad of obstacles that couples may encounter, cornual block in the fallopian tubes emerges as a particularly noteworthy concern due to its distinct impact on fertility.",
  "image": "https://kangaroocarefertility.com/blogs/blog-58.jpg",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroo Care Fertility",
    "url": "https://kangaroocarefertility.com/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Kangaroo Care Fertility",
    "logo": {
      "@type": "ImageObject",
      "url": "https://kangaroocarefertility.com/static/media/kcf_logo.0b66caa0.png"
    }
  },
  "datePublished": "2024-05-08"

}
</script>
    
    `,
  },
  {
    id: 58,
    blogTitle: "Pregnancy After IUI: A Comprehensive Guide for Hopeful Couples",
    schema: `<script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocarefertility.com/media/blogs/pregnancy-after-iui-a-comprehensive-guide-for-hopeful-couples/59"
  },
  "headline": "Pregnancy After IUI: A Comprehensive Guide for Hopeful Couples",
  "description": "Enjoying the journey of parenthood can be exciting as well as nerve-wracking, especially for couples who have undergone Intrauterine Insemination (IUI) treatment. Understanding the signs & symptoms of pregnancy after IUI can bring reassurance and hope to those eagerly awaiting the news of conception. In this comprehensive guide, we'll delve into the various aspects of pregnancy after IUI, from positive signs after embryo transfer to recognizing success symptoms.",
  "image": "https://kangaroocarefertility.com/blogs/blog-59.jpg",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroo Care Fertility",
    "url": "https://kangaroocarefertility.com/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Kangaroo Care Fertility",
    "logo": {
      "@type": "ImageObject",
      "url": "https://kangaroocarefertility.com/static/media/kcf_logo.0b66caa0.png"
    }
  },
  "datePublished": "2024-05-14"

}
</script>
    
    `,
  },
  {
    id: 59,
    blogTitle: "AMH Test for Pregnancy: Your Key to Assessing Fertility Potential",
    schema: `<script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocarefertility.com/media/blogs/amh-test-for-pregnancy-your-key-to-assessing-fertility-potential/60"
  },
  "headline": "AMH Test for Pregnancy: Your Key to Assessing Fertility Potential",
  "description": "Understanding one's fertility potential is crucial in the journey towards conception. As modern medicine advances, various tools and tests have emerged to assess fertility in both men and women. One such tool gaining prominence is the anti-Mullerian hormone (AMH) test. This comprehensive blog aims to explore the significance of the AMH test for pregnancy in evaluating fertility potential, its intricacies, average values, implications, and strategies to optimize AMH levels.",
  "image": "https://kangaroocarefertility.com/blogs/blog-60.jpg",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroo Care Fertility",
    "url": "https://kangaroocarefertility.com/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Kangaroo Care Fertility",
    "logo": {
      "@type": "ImageObject",
      "url": "https://kangaroocarefertility.com/static/media/kcf_logo.0b66caa0.png"
    }
  },
  "datePublished": "2024-05-17"

}
</script>
    
    `,
  },
  {
    id: 60,
    blogTitle: "Deciphering the Complexities of IVF Failure: A Focus on Genetic Factors",
    schema: `<script type="application/ld+json">
{
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  "mainEntityOfPage": {
    "@type": "WebPage",
    "@id": "https://kangaroocarefertility.com/media/blogs/deciphering-the-complexities-of-ivf-failure-a-focus-on-genetic-factors/61"
  },
  "headline": "Deciphering the Complexities of IVF Failure: A Focus on Genetic Factors",
  "description": "In the kingdom of assisted reproductive technology, In Vitro Fertilization (IVF) offers hope for many couples struggling with infertility. However, despite its advancements, IVF success rates are not absolute, and failures can occur. One of the significant contributors to IVF failure lies in genetic factors, which warrant thorough exploration.",
  "image": "https://kangaroocarefertility.com/blogs/blog-61.jpg",  
  "author": {
    "@type": "Organization",
    "name": "Kangaroo Care Fertility",
    "url": "https://kangaroocarefertility.com/"
  },  
  "publisher": {
    "@type": "Organization",
    "name": "Kangaroo Care Fertility",
    "logo": {
      "@type": "ImageObject",
      "url": "https://kangaroocarefertility.com/static/media/kcf_logo.0b66caa0.png"
    }
  },
  "datePublished": "2024-05-20"

}
</script>
    
    `,
  },
];
