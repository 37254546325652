import React from "react";

export const Home = () => {
  const organizationSchema = `
        <script type="application/ld+json">
            {
            "@context": "https://schema.org",
            "@type": "MedicalOrganization",
            "name": "Kangaroo Care Fertility",
            "url": "https://kangaroocarefertility.com/",
            "logo": "https://kangaroocarefertility.com/static/media/kcf_logo.0b66caa0.png",
            "sameAs": [
                "https://www.facebook.com/kangaroocarefertility",
                "https://www.instagram.com/kangaroocare_fertility/",
                "https://www.youtube.com/channel/UCyqElguOP1XQNls1PqaGOyQ"
            ]
            }
        </script>
    `;

  const websiteSchema = `
        <script type="application/ld+json">
        {
        "@context": "https://schema.org/",
        "@type": "WebSite",
        "name": "Kangaroo Care Fertility",
        "url": "https://kangaroocarefertility.com/",
        "potentialAction": {
            "@type": "SearchAction",
            "target": "https://kangaroocarefertility.com/?s={search_term_string}",
            "query-input": "required name=search_term_string"
        }
        }
        </script>    
    `;

  const localBusinessSchema = `
        <script type="application/ld+json">
        {
        "@context": "https://schema.org",
        "@type": "LocalBusiness",
        "name": "Kangaroo Care Fertility",
        "image": "https://kangaroocarefertility.com/static/media/kcf_logo.0b66caa0.png",
        "@id": "https://kangaroocarefertility.com/#LocalBusiness",
        "url": "https://kangaroocarefertility.com/",
        "telephone": "1800-425-4500",
        "address": {
            "@type": "PostalAddress",
            "streetAddress": "#119, 8th Cross Rd, Govindaraja Nagar Ward, MC Layout, Vijayanagar,",
            "addressLocality": "Bengaluru",
            "postalCode": "560040",
            "addressCountry": "IN"
        },
        "geo": {
            "@type": "GeoCoordinates",
            "latitude": 12.9744706,
            "longitude": 77.5422191
        },
        "openingHoursSpecification": {
            "@type": "OpeningHoursSpecification",
            "dayOfWeek": [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Friday",
            "Thursday",
            "Saturday"
            ],
            "opens": "09:00",
            "closes": "20:00"
        },
        "sameAs": [
            "https://www.facebook.com/kangaroocarefertility",
            "https://www.instagram.com/kangaroocare_fertility/",
            "https://www.youtube.com/channel/UCyqElguOP1XQNls1PqaGOyQ"
        ] 
        }
        </script>
    
    `;

  return (
    <div>
      <iframe
        srcDoc={organizationSchema}
        title="Organization Schema"
        width="0"
        height="0"
      />
      <iframe
        srcDoc={websiteSchema}
        title="Website Schema"
        width="0"
        height="0"
      />
      <iframe
        srcDoc={localBusinessSchema}
        title="Local Business Schema"
        width="0"
        height="0"
      />
    </div>
  );
};
export default Home;
