import React from "react";
import "./operationalTeam.css";
import parthasarathy from "./assets/img/doctor/operational-team/parthasarathy.jpg";
import major from "./assets/img/doctor/major.jpg";
import shivu from "./assets/img/doctor/shivu.jpg";
import reshma from "./assets/img/doctor/reshma.jpg";
import ramya from "./assets/img/doctor/operational-team/vanaja-dr.png";
import usha from "./assets/img/doctor/usha.jpg";
import CustomTitle from "./CustomTitle";
import { Helmet } from "react-helmet";
export default function OperationalTeam() {
  return (
    <>
      <div data-aos="fade-up">
        <Helmet>
          <title>
            Meet Our Expert Operational Team | Kangaroo Care Fertility
          </title>
          <meta charSet="utf-8" />
          <meta
            name="description"
            content="An Kangaroo Care fertility operational team is a group of people working together towards a common goal."
          />
        </Helmet>
        <div className="container-fluid text-center py-4 bg-light">
          {/* <p style={{ color: "#771fbb", fontSize: "32px", fontWeight: "bold" }}>
            Operational Team
          </p> */}
          <h1 className="text-center text-blue">Operational Team</h1>

          <div className="container" id="owl-me-carousel-op">
            <div className="row justify-content-center">
              {/* <div className="col-lg-4 col-sm-6" id="cardOwl">
                <div className="card m-2">
                  <img
                    src={parthasarathy}
                    className="card-img-top admin-img"
                    alt="Shivu Senior Manager"
                  />
                  <div className="card-body">
                    <h3 className="card-title text-blue">PARTHASARATHY Y G </h3>
                    <p className="card-text">
                      Center Head
                      <br />
                      Bangalore
                    </p>
                  </div>
                </div>
              </div> */}
              <div className="col-lg-4 col-sm-6" id="cardOwl">
                <div className="card m-2">
                  <img
                    src={major}
                    className="card-img-top admin-img"
                    alt="Major Joyshree Head Nursing"
                  />
                  <div className="card-body">
                    <h3 className="card-title text-blue">MAJOR JOYSHREE</h3>
                    <p className="card-text">
                      Head - Nursing
                      <br />
                      Bangalore
                    </p>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-4 col-sm-6" id="cardOwl">
                <div className="card m-2">
                  <img
                    src={shivu}
                    className="card-img-top admin-img"
                    alt="Shivu Senior Manager"
                  />
                  <div className="card-body">
                    <h3 className="card-title text-blue">SHIVU</h3>
                    <p className="card-text">
                      Senior Manager - Project
                      <br />
                      Bangalore
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-sm-6" id="cardOwl">
                <div className="card m-2">
                  <img
                    src={reshma}
                    className="card-img-top admin-img"
                    alt="Reshma Senior Manager"
                  />
                  <div className="card-body">
                    <h3 className="card-title text-blue">RESHMA</h3>
                    <p className="card-text">
                      Senior Manager - Account <br />
                      Bangalore
                    </p>
                  </div>
                </div>
              </div> */}
              {/* <div className="col-lg-4 col-sm-6" id="cardOwl">
                <div className="card m-2">
                  <img src={vidhya} className="card-img-top admin-img" alt="Vidhya Shree R Senior Manager" />
                  <div className="card-body">
                    <h3 className="card-title text-blue">VIDHYA SHREE R</h3>
                    <p className="card-text">
                      Senior Manager - Human Resourse<br />Bangalore
                    </p>

                  </div>
                </div>
              </div> */}
              {/* <div className="col-lg-4 col-sm-6" id="cardOwl">
                <div className="card m-2">
                  <img
                    src={ramya}
                    className="card-img-top admin-img"
                    alt="Reshma Senior Manager"
                  />
                  <div className="card-body">
                    <h3 className="card-title text-blue">VANAJA RAMANNA</h3>
                    <p className="card-text">
                      Zonal Head - Human Resources <br />
                      Bangalore - Mysore
                    </p>
                  </div>
                </div>
              </div> */}
              <div className="col-lg-4 col-sm-6" id="cardOwl">
                <div className="card m-2">
                  <img
                    src={usha}
                    className="card-img-top admin-img"
                    alt="Usha Reddy Fertility Incharge"
                  />
                  <div className="card-body">
                    <h3 className="card-title text-blue">USHA REDDY</h3>
                    <p className="card-text">
                      Fertility Incharge
                      <br />
                      Bangalore
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
