import React from "react";
import hema from "../assets/img/doctor/dr-hema-h-r.png";
import { useLocation, Link } from "react-router-dom";
import CustomTitle from "../CustomTitle";

export default function Hema() {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const helmetDescriptions = {
    titleName: "Dr. Hema H R | Family Physician | Kangaroo Care Fertility",
    Description:
      "Experience personalized care with Dr. Hema H R, your family physician. Comprehensive healthcare solutions tailored to your needs. Visit now.",
  };

  return (
    <div id="each-doctor">
      <CustomTitle customValue={helmetDescriptions} />
      <nav aria-label="breadcrumb" id="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>

          <li className="breadcrumb-item active" aria-current="page">
            Doctor
          </li>
        </ol>
      </nav>
      {/* -------------------------doctor detail--------------- */}

      <div className="container mt-4">
        <div className="row">
          <div className="col-md-5">
            <div className="card" style={{ maxWidth: "30rem" }}>
              <img src={hema} className="card-img-top" alt="Dr. Hema H R" />
            </div>
            <br />
            <br />
            <br />
            <h3>More Info</h3>
            <br />
            <p>MBBS, M.MED,</p>
            <p>(Family Medicine), CCEBDM, CCGDM, CCMH</p>
            <p>
              Department : <span>Physicians</span>
            </p>
            <br />
            {/* <p>Consultations : &nbsp; 15 Minutes per Consultation</p>
            <br /> */}
            <p>Location : Bangalore</p>
            <br />

            <span className="badge bg-primary p-2">
              Available on Prior Appointment
            </span>
            <br />
            <p> - Toll-free number @ 1800-425-4500</p>
            <br />
          </div>

          <div className="col-md-7">
            <h1 className="text-blue">Dr. HEMA H R</h1>
            <p>
              <strong>FAMILY PHYSICIAN</strong>
            </p>
            <p>
              Dr. Hema H.R is a Consultant Family Physician currently associated
              with Kangaroo Care Women and Children Hospital, Bengaluru who
              provides medical care to women. She completed her MBBS from
              KASTURBA MEDICAL COLLEGE, MANGALORE and has done her Masters in
              Family Medicine from CMC VELLORE. She's certified in Diabetes, GDM
              and Hypertension management.
            </p>
            <p>
              She practices at Telecom Layout Vijaynagar. She's also a Medical
              Consultant to BHEL, Bengaluru. She is also a life member of IMA
              and AFPI.
            </p>
            {/* <p>
              Her expertise lies in treating patients with poor ovarian reserve,
              PCOD and endometriosis. Her keen interest and dedication to help
              infertile couple has resulted in countless of them to bear
              children.
            </p>
            <p>
              Multilinguistic doctor (fluent in English, Kannada, Telugu, Tamil
              and Malayalam) is very patient friendly and easily approachable.
              She tailor’s her treatment according to the patients need. Her
              management is very cost effective.
            </p>
            <h3>QUALIFICATION</h3>
            <p>MBBS . MS (OBG)</p>
            <p>Fellowship in reproductive medicine</p>
            <p>Diploma in Reproductive Medicine (Germany)</p>
            <h3>WORK EXPERIENCE</h3>
            <p>Reproductive medicine at craft hospital, Kerala</p>
            <p>
              Assistant professor in Department of OBG at Rajarajeshwari Medical
              College, Bangalore.
            </p>
            <h3>MEMBERSHIP:</h3>
            <p>Bangalore Society of Obstetrics and Gynecologist (BSOG)</p>
            <p>Indian Medical Council (IMA)</p> */}
          </div>
        </div>
      </div>
    </div>
  );
}
