import React from "react";
import { Link } from "react-router-dom";

export default function Centers() {
  return (
    <ul className="ms-5">
      <li className="border-bottom mb-3">
        <Link to="/centers/bengaluru">
          <span className="hand-link">&#9758;</span> &nbsp; Bengaluru
        </Link>
      </li>
      <li className="border-bottom mb-3">
        <Link to="/centers/mysore">
          <span className="hand-link">&#9758;</span> &nbsp; Mysore
        </Link>
      </li>
      <li className="border-bottom mb-3">
        <Link to="/centers/ramanagara">
          <span className="hand-link">&#9758;</span> &nbsp; Ramanagara
        </Link>
      </li>
    </ul>
  );
}
