import React from "react";
import CustomTitle from "../CustomTitle";
import "./IntrauterineInsemination.css";
import simg from "../assets/img/fertility/IUI1.jpg";
import { useLocation, Link } from "react-router-dom";
export default function Services() {
  const helmetDescriptions = {
    titleName: "Best IUI Treatment in Bangalore | Best IUI Center in Bangalore",
    Description:
      "Kangaroo Care Fertility is one of the best IUI fertility treatment center in Bangalore. We strive to provide the best infertility treatment to our patients.",
  };
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const IUISchema = `<script type="application/ld+json">
  {
    "@context": "http://schema.org/",
    "@type": "FAQPage",
    "mainEntity": [
      {
        "@type": "Question",
        "name": "Is IUI procedure painful ?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "IUI is not a painful procedure. You may sometimes feel a small cramp in your pelvic region, after the procedure."
        }
      },
      {
        "@type": "Question",
        "name": "What is double UI ?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "In certain conditions, such as low sperm counts or sperm abnormalities, double IUI that is insemination of the processed semen sample, twice, 24hrs apart has been tried with varied results."
        }
      },
      {
        "@type": "Question",
        "name": "Success rate with UI ?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Success with IUI is around 15-25% pregnancy per cycle. It is slightly higher when injectables are used compared to oral ovulogens. Success is much better with IVF/ICSI."
        }
      },
      {
        "@type": "Question",
        "name": "How many times can IUl be done?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "IUI is may be done upto 6 times in a couple. This is subject to vary based on the couple’s medical history."
        }
      },
      {
        "@type": "Question",
        "name": "When is donor semen used for IUI ?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "In men with Azoospermia, severe sperm abnormalities, high risk of genetic abnormalities, donor semen sample is used for IUI."
        }
      },
      {
        "@type": "Question",
        "name": "Who is IUI recommended for?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "IUI is recommended for couples who have difficulty conceiving naturally even after trying for a year."
        }
      },
      {
        "@type": "Question",
        "name": "What age is good for IUI?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "IUI is advised for women under the age of 35, a period where they are conventionally considered to be fertile, the chances of success will be around 20%. For women between the age of 35 to 40, the chance of getting pregnant decreases to 10%."
        }
      },
      {
        "@type": "Question",
        "name": "Are IUI babies healthy?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Yes, IUI babies are healthy. There is a chance of 2-4% of developing an infection in this procedure. However, the chance of any other medical complications or birth defects is low in this procedure."
        }
      },
      {
        "@type": "Question",
        "name": "Can IUI cause twins?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Although with IUI, the chance of pregnancy increases, this procedure alone does not increase the chance of having twins. IUI does not affect ovulation by itself, so a woman who undergoes IUI has the same chances of conceiving twins as other women who conceive naturally."
        }
      },
      {
        "@type": "Question",
        "name": "Which is better, IUI or IVF?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "IVF is better and has a higher success rate than IUI. IUI is considerably less effective than IVF, and couples may require multiple cycles before being able to conceive"
        }
      },
      {
        "@type": "Question",
        "name": "How do I make sure IUI is successful?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "To ensure a successful IUI, eat healthily, take fertility supplements and limit high-intensity exercise which can cause heat and inflammation and take away blood flow from your core."
        }
      },
      {
        "@type": "Question",
        "name": "What is the success rate of IUI?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "The success rate of IUI depends on the blockage's location on the blocked tube. When the blockage location is closest to the ovary, the success rate is around 11.7%. Whereas if the blockage location is closest to the uterus, the success rate is around 38.1%."
        }
      }
    ]
  }
  </script>
  
  
  `;

  const Accodian = [
    {
      id: 1,
      question: "Is IUI procedure painful ?",
      answer:
        "IUI is not a painful procedure. You may sometimes feel a small cramp in your pelvic region, after the procedure.",
    },
    {
      id: 2,
      question: "What is double IUI ?",
      answer:
        " In certain conditions, such as low sperm counts or sperm abnormalities, double IUI that is insemination of the processed semen sample, twice, 24hrs apart has been tried with varied results.",
    },
    {
      id: 3,
      question: " Success rate with IUI ?",
      answer:
        "Success with IUI is around 15-25% pregnancy per cycle. It is slightly higher when injectables are used compared to oral ovulogens. Success is much better with IVF/ICSI.",
    },
    {
      id: 4,
      question: "How many times can IUI be done?",
      answer:
        " IUI is may be done upto 6 times in a couple. This is subject to vary based on the couple’s medical history.",
    },
    {
      id: 5,
      question: "When is donor semen used for IUI ?",
      answer:
        "In men with Azoospermia, severe sperm abnormalities, high risk of genetic abnormalities, donor semen sample is used for IUI.",
    },
    {
      id: 6,
      question: "Who is IUI recommended for?",
      answer:
        "IUI is recommended for couples who have difficulty conceiving naturally even after trying for a year. ",
    },
    {
      id: 7,
      question: "What age is good for IUI?",
      answer:
        "IUI is advised for women under the age of 35, a period where they are conventionally considered to be fertile, the chances of success will be around 20%. For women between the age of 35 to 40, the chance of getting pregnant decreases to 10%.",
    },
    {
      id: 8,
      question: "Are IUI babies healthy?",
      answer:
        "Yes, IUI babies are healthy. There is a chance of 2-4% of developing an infection in this procedure. However, the chance of any other medical complications or birth defects is low in this procedure.  ",
    },
    {
      id: 9,
      question: "Can IUI cause twins?      ",
      answer:
        "Although with IUI, the chance of pregnancy increases, this procedure alone does not increase the chance of having twins. IUI does not affect ovulation by itself, so a woman who undergoes IUI has the same chances of conceiving twins as other women who conceive naturally.",
    },
    {
      id: 10,
      question: "Which is better, IUI or IVF?",
      answer:
        "IVF is better and has a higher success rate than IUI. IUI is considerably less effective than IVF, and couples may require multiple cycles before being able to conceive",
    },
    {
      id: 11,
      question: "How do I make sure IUI is successful?",
      answer:
        "To ensure a successful IUI, eat healthily, take fertility supplements and limit high-intensity exercise which can cause heat and inflammation and take away blood flow from your core.  ",
    },
    {
      id: 12,
      question: "What is the success rate of IUI?",
      answer:
        "The success rate of IUI depends on the blockage's location on the blocked tube. When the blockage location is closest to the ovary, the success rate is around 11.7%. Whereas if the blockage location is closest to the uterus, the success rate is around 38.1%.",
    },
  ];
  return (
    <div>
      <CustomTitle
        href="https://kangaroocarefertility.com/treatments/intrauterine"
        customValue={helmetDescriptions}
      />
      <iframe srcDoc={IUISchema} title="schema" width="0" height="0" />
      <div className="main-content container mt-2">
        <nav aria-label="breadcrumb" className="mb-5">
          <ol className="breadcrumb">
            <li className="breadcrumb-item text-specifc-color">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item text-specifc-color">
              <Link to="/">Specialities</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Intrauterine Insemination (IUI)
            </li>
          </ol>
        </nav>
        <div />

        {/* -------main content------------ */}

        {/* -----------------------------what is portion -------------------- */}
        <div className="container-fluid service my-5">
          <div className="row justify-content-center">
            <h1 className="heading1 text-center pb-5">
              Best IUI Fertility Hospital in Bangalore
            </h1>
            <div className="col-md-6 mx-2 d-none d-sm-block">
              <div className="mb-2" id="service-left-img-iui">
                <img src={simg} alt="IUI treatment and pregnant women" />
              </div>
            </div>
            <div className="col-md-5 mb-2 mx-2" id="service-content-card">
              <h2 className="my-4 text-center text-blue">
                Intrauterine Insemination (IUI)
              </h2>
              <hr />
              <p>
                <i className="fas fa-check-circle me-2"></i>
                <strong>What is Intrauterine Insemination (IUI)?</strong>
              </p>
              <p>
                IUI is a process which involves inserting the processed semen
                sample of the husband (or donor’s) into the uterus, around the
                time of ovulation. It is one of the simple, less invasive form
                of fertility treatment. At, Kangaroo Care Fertility our tailored
                management of IUI have obtained excellent results.
              </p>
            </div>
          </div>

          {/* -----------------------section2 content process--------------------- */}

          <div className="row justify-content-center my-5">
            <div className="col-md-7 mx-2" id="service-content2-card">
              <div className="mb-2 p-4">
                <h2 className="text-center text-blue">
                  Steps of IUI Treatment
                </h2>
                <p>
                  <ul>
                    <li>
                      <i className="fas fa-check-circle me-2"></i>Ovarian
                      stimulation with certain medications{" "}
                    </li>
                    <li>
                      <i className="fas fa-check-circle me-2"></i>Processing of
                      semen sample and the concentrated sperm sample is provided
                      for insertion{" "}
                    </li>
                    <li>
                      <i className="fas fa-check-circle me-2"></i>Carefully
                      timed insertion of the processed semen sample into the
                      uterus around the time of ovulation{" "}
                    </li>
                    <li>
                      <i className="fas fa-check-circle me-2"></i>Tracking of
                      ovulation by serial transvaginal scans to aid the process
                      of IUI{" "}
                    </li>
                    <li>
                      <i className="fas fa-check-circle me-2"></i>Confirm
                      pregnancy by a simple urine pregnancy test, 2 weeks after
                      IUI{" "}
                    </li>
                  </ul>
                </p>
                <p style={{ textAlign: "justify" }}>
                  We aim at personalized care and cater to result-oriented
                  services. Being one of the best IUI fertility treatment
                  centers in Vijayanagar, Bangalore is renowned for its
                  remarkable ability to combine clinical excellence with
                  compassionate care, enveloping every couple with medical,
                  emotional, and psychological support. We have state-of-art
                  fertility services providing the best quality and
                  comprehensive care to couples.{" "}
                </p>
              </div>
            </div>
            <div
              className="col-md-3 mb-2 mx-2 p-4 card  d-none d-md-block"
              id="service-link-card"
            >
              <h2 className="text-blue">Important Links</h2>
              <br />
              <ul className="ms-5">
                <li>
                  <Link to="/treatments/ovulation-induction">
                    <span className="hand-link">&#9758;</span> &nbsp; Ovulation
                    Induction
                  </Link>
                </li>
                <li>
                  <Link to="/treatments/intrauterine">
                    <span className="hand-link">&#9758;</span> &nbsp;
                    Intrauterine Insemination (IUI)
                  </Link>
                </li>
                <li>
                  <Link to="/treatments/invitro-fertilization">
                    <span className="hand-link">&#9758;</span> &nbsp; In Vitro
                    Fertilization
                  </Link>
                </li>
                <li>
                  <Link to="/treatments/intracytoplasmic">
                    <span className="hand-link">&#9758;</span> &nbsp;
                    Intracytoplasmic Sperm Injection{" "}
                  </Link>
                </li>
                <li>
                  <Link to="/treatments/genetic-counselling-and-testing">
                    <span className="hand-link">&#9758;</span> &nbsp; Genetic
                    Counselling and Testing
                  </Link>
                </li>
                <li>
                  <Link to="/treatments/varicocele">
                    <span className="hand-link">&#9758;</span> &nbsp; Varicocele
                  </Link>
                </li>
                <li>
                  <Link to="/treatments/tesa-pesa">
                    <span className="hand-link">&#9758;</span> &nbsp; TESA/ PESA
                  </Link>
                </li>
                <li>
                  <Link to="/treatments/fertility-preservation">
                    <span className="hand-link">&#9758;</span> &nbsp; Fertility
                    Preservation
                  </Link>
                </li>
                <li>
                  <Link to="/treatments/Donor">
                    <span className="hand-link">&#9758;</span> &nbsp; Donor
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-md-10 p-4 the-3-service-card ">
              <h2 className="text-center text-blue">
                For Whom IUI is Suitable{" "}
              </h2>
              <p>
                <i className="fas fa-check-circle me-2"></i>Couple with
                unexplained infertility. Women with PCOS, Mild-moderate
                Endometriosis, Anovulatory cycles, men with certain seminal
                abnormalities are benefited with IUI.
              </p>
              <h2 className="text-center text-blue">Insemination With Donor</h2>
              <p>
                <i className="fas fa-check-circle me-2"></i>Donor sperms can be
                used in men with poor sperm quality or quantity. Even in cases
                of certain genetic diseases, donor sperms can be used for IUI.
              </p>
              <h2 className="text-center text-blue">Cost of IUI</h2>
              <p>
                <i className="fas fa-check-circle me-2"></i>IUI is one of the
                simple forms of assisted reproduction. It costs very less when
                compared to IVF.
              </p>
              <p style={{ textAlign: "justify" }}>
                We provide the best IUI treatment in Vijayanagar, Bangalore. Our
                goal is to let couples achieve pregnancy responsibly and with
                the least possible risk for both mother and the baby.
              </p>
            </div>
          </div>
        </div>

        {/* -------------------------advantage cards---------------- */}

        <div className="container-fluid my-4">
          <div className="row justify-content-center" id="scards">
            <h2 className="text-center text-blue">
              Advantages of Ovulation Induction
            </h2>
            <br />
            <br />
            <div className="col-sm-3 m-2 p-3 the-3-service-card">
              <div className="text-center">
                {/* <img src={logo} alt="" /> */}
                <p>
                  <strong className="text-blue">Cost Effective</strong>
                </p>
              </div>
              <p>IUI costs very less compared to IVF</p>
            </div>
            <div className="col-sm-3 m-2 p-3 the-3-service-card">
              <div className="text-center">
                {/* <img src={logo} alt="" /> */}
                <p>
                  <strong className="text-blue">Male Infertility</strong>
                </p>
              </div>
              <p>
                In certain cases of male infertility, IUI offers a very good
                outcome
              </p>
            </div>
            <div className="col-sm-3 m-2 p-3 the-3-service-card">
              <div className="text-center">
                {/* <img src={logo} alt="" /> */}
                <p>
                  <strong className="text-blue">Female Infertility</strong>
                </p>
              </div>
              <p>
                It provides good pregnancy outcome in women with unexplained
                infertility, mild-moderate endometriosis.
              </p>
            </div>
          </div>
        </div>

        {/* ---------------------------faq------------------------ */}

        <div className="accordion container-fluid my-5 " id="accordionExample">
          <h2 className="text-center text-blue">FAQs</h2>
          <br />
          {Accodian.map((item) => {
            return (
              <>
                <div className="accordion-item">
                  <h2 className="accordion-header" id={`heading${item.id}`}>
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapse${item.id}`}
                      aria-expanded="false"
                      aria-controls={`collapse${item.id}`}
                    >
                      • {item.question}
                    </button>
                  </h2>
                  <div
                    id={`collapse${item.id}`}
                    className="accordion-collapse collapse"
                    aria-labelledby={`heading${item.id}`}
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">{item.answer}</div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
      <div className="container-fluid bg-light ">
        <div className="container">
          <div className="row">
            <div className="col text-center my-sm-5 my-3">
              <h2 className="heading2">
                Why choose Kangaroo Care Fertility for IUI Treatment in
                Bangalore?
              </h2>
              <p>
                Kangaroo Care Fertility is the best hospital for IUI fertility
                treatment in Bangalore. We have a team of certified fertility
                specialists who have expertise in infertility treatment to make
                your IUI journey successful. We make sure to provide the best
                IUI treatment in Bangalore.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* ----------------some heading and para----------- */}
      <div className="container-fluid bg-light ">
        <div className="container">
          <div className="row">
            <div className="col text-center my-sm-5 my-3">
              <h2 className="heading2">Best IUI Center in Bangalore</h2>
              <p>
                Kangaroo Care Fertility is one of the Best IUI Treatment Centers
                in Bangalore. Intrauterine Insemination is a less invasive
                infertility treatment. Our specialized fertility doctors monitor
                the female ovulation cycle, and track the timing of insemination
                of the sperms to maximise the chances of conception. Every
                couple will respond differently, and the success rate is not
                predictable. We at Kangaroo Care Fertility have obtained
                excellent results, which makes it the best hospital for IUI
                treatment in Bangalore.
              </p>
              <p>
                We aim to provide the best female & male fertility treatments
                like &nbsp;
                <a href="https://kangaroocarefertility.com/treatments/ovulation-induction">
                  Ovulation Induction,
                </a>
                <a href="https://kangaroocarefertility.com/treatments/invitro-fertilization">
                  IVF treatment,
                </a>
                <a href="https://kangaroocarefertility.com/treatments/intracytoplasmic">
                  ICSI,
                </a>
                <a href="https://kangaroocarefertility.com/treatments/genetic-counselling-and-testing">
                  genetic counseling & testing,
                </a>
                <a href="https://kangaroocarefertility.com/treatments/varicocele">
                  varicocele treatment,
                </a>
                <a href="https://kangaroocarefertility.com/treatments/tesa-pesa">
                  TESA/PESA
                </a>
                for both female infertility and male infertility problems.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
