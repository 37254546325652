import React from "react";
import CustomTitle from "../CustomTitle";
import "./faq.css";
import { useLocation, Link } from "react-router-dom";
export default function Faq() {
  const helmetDescriptions = {
    titleName:
      "FAQs on Fertility Treatment | Best Infertility Treatment Clinic Bangalore",
    Description:
      "Get answers to the frequently asked questions related to fertility treatment, advanced techniques and methods used to treat infertility in male and female.",
  };
  const faqSchema = `<script type="application/ld+json">
  {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "What does an Andrologist do?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "An Andrologist is a doctor specialized in the male evaluation and treatment of infertility. He finds the cause of infertility in the male and treats it. Varicocele, one of the common reasons attributed to infertility, he does Varicocelectomy. In males with Azoospermia, he does procedures like PESA/TESA. For further details, contact our Andrologist."
      }
    },{
      "@type": "Question",
      "name": "What is Azoospermia?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Absence of sperms in the semen is termed as azoospermia. It can be obstructive or nonobstructive. Treatment for both is available at Kangaroo Care Fertility."
      }
    },{
      "@type": "Question",
      "name": "Can smoking cause infertility in men?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Smoking can lead to DNA damage in sperm. These men with elevated sperm with DNA damage may have reduced fertility and higher miscarriage rates. Also, smoking is a risk factor for erectile dysfunction (ED), which can be a challenge."
      }
    },{
      "@type": "Question",
      "name": "What is sperm DFI?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "DFI stands for DNA Fragmentation Index. It is tested in men with infertility and repeated abortions. High DFI can be detrimental and may cause infertility. Ask your doctor as to how it can be treated."
      }
    },{
      "@type": "Question",
      "name": "What is the main cause of male infertility?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Semen disorders- abnormal sperm shape/size/number/motility or immature sperms, is the commonest cause of male infertility."
      }
    }]
  }
  </script>
  `;
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <div>
      <h1 className="h1-top-text">FAQ</h1>
      <CustomTitle href="https://kangaroocarefertility.com/media/Faq" customValue={helmetDescriptions} />
      <br />
      <iframe srcDoc={faqSchema} title="faq schema" width="0" height="0" />
      <nav aria-label="breadcrumb" id="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Media
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            FAQ
          </li>
        </ol>
      </nav>
      {/* ----------------------------FAQ-------------- */}
      <div className="container faq my-4" style={{ textAlign: "left" }}>
        <div className="accordion" id="accordionExample">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                <h6>What causes infertility?</h6>
              </button>
            </h2>
            <div
              id="collapseOne"
              className="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <p>
                  There are a number of causes of infertility. Approximately one
                  third of the cases are caused by conditions solely afflicting
                  the woman, one third caused by conditions solely afflicting
                  the man, and one third are problems involving both partners or
                  the causes are unknown. In women, the most common cause of
                  infertility is the irregular or defective release of eggs
                  (ovulation). The most common symptoms of ovulation disorders
                  are a lack of regular or any menstruation. Other more common
                  causes of infertility include blocked fallopian tubes or
                  abnormalities of the uterus.
                </p>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                <h6>At what point should one consider seeking help?</h6>
              </button>
            </h2>
            <div
              id="collapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="headingTwo"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <p>
                  Women younger than age 35 with a regular menstrual cycle are
                  advised to try getting pregnant for one year before seeking
                  help. That means regular, unprotected sexual intercourse
                  (without condoms or other contraceptives), preferably timed
                  around ovulation. Couples should have a good understanding of
                  when ovulation is expected to occur and time intercourse to
                  anticipate this event by a few days.
                </p>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="headingThree">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                <h6>Why is In Vitro Fertilization (IVF) used?</h6>
              </button>
            </h2>
            <div
              id="collapseThree"
              className="accordion-collapse collapse"
              aria-labelledby="headingThree"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <p>
                  In infertile couples where women have blocked or absent
                  fallopian tubes, or where men have low sperm counts, in vitro
                  fertilization (IVF) offers a chance at parenthood to couples
                  who until recently would have had no hope of having a
                  "biologically related" child.
                </p>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="headingFour">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseFour"
              >
                <h6>What is the process of In Vitro Fertilization (IVF)?</h6>
              </button>
            </h2>
            <div
              id="collapseFour"
              className="accordion-collapse collapse"
              aria-labelledby="headingFour"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <p>
                  In IVF, eggs are surgically removed from the ovary and mixed
                  with sperm outside the body in a Petri dish ("in vitro" is
                  Latin for "in glass"). After about 40 hours, the eggs are
                  examined to see if they have become fertilized by the sperm
                  and are dividing into cells. These fertilized eggs (embryos)
                  are then placed in the woman's uterus, thus bypassing the
                  fallopian tubes.
                </p>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="headingFive">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFive"
                aria-expanded="false"
                aria-controls="collapseFive"
              >
                <h6>What if my eggs don’t fertilize?</h6>
              </button>
            </h2>
            <div
              id="collapseFive"
              className="accordion-collapse collapse"
              aria-labelledby="headingFive"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <ul>
                  <p>
                    Most eggs will fertilize when they are placed in a culture
                    dish with several thousand normal sperm. This process is
                    called “in vitro fertilization” or “IVF.” When there are not
                    enough normal functioning sperm for IVF, fertilization will
                    usually occur after a single live sperm is injected into
                    each egg, termed “intracytoplasmic sperm injection” or
                    “ICSI.”
                  </p>
                </ul>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="headingSix">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSix"
                aria-expanded="false"
                aria-controls="collapseSix"
              >
                <h6>Why should we do Preimplantation Genetic Testing (PGT)?</h6>
              </button>
            </h2>
            <div
              id="collapseSix"
              className="accordion-collapse collapse"
              aria-labelledby="headingSix"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <p>
                  Patients with many inherited familial diseases can have their
                  embryos tested to determine its genetic make-up. Specifically,
                  this would include patients with a history of single-gene
                  disorders (such as cystic fibrosis or sickle cell anemia) and
                  patients with a history of sex-linked disorders (such as
                  Duchenne muscular dystrophy and Fragile X syndrome). In
                  addition, even families in search of a bone marrow donor may
                  be able to use PGT to bring a child into the world that can
                  provide matching stem cells for an affected sibling.
                </p>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="headingSeven">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSeven"
                aria-expanded="false"
                aria-controls="collapseSeven"
              >
                <h6>What is Magnetic-Activated Cell Sorting (MACS)?</h6>
              </button>
            </h2>
            <div
              id="collapseSeven"
              className="accordion-collapse collapse"
              aria-labelledby="headingSeven"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <p>
                  The MACS process involves the use of very small biodegradable
                  magnetic particles. These particles have an antibody known as
                  protein annexin. The antibody fastens itself to sperms with
                  high DNA fragmentation, that are more likely to undergo
                  apoptosis or programmed cell death. The semen sample is then
                  passed through a column with weak magnetic fields around it.
                  The apoptotic sperms get drawn towards the walls while the
                  healthy ones pass through the channel. Thus we obtain the
                  healthy sperms.
                </p>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="headingEight">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseEight"
                aria-expanded="false"
                aria-controls="collapseEight"
              >
                <h6>
                  Why am I not responding to the drugs for ovarian stimulation?
                </h6>
              </button>
            </h2>
            <div
              id="collapseEight"
              className="accordion-collapse collapse"
              aria-labelledby="headingEight"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body">
                <p>
                  A response to ovarian stimulation depends on a number of
                  different factors, the most important include available eggs,
                  appropriate hormone levels, proper administration of any
                  medications and lifestyle/environmental factors.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
