import React from "react";
import { Helmet } from "react-helmet";
const CustomTitle = (props) => {
  //   console.log("hello");
  //   console.log(props.customValue.titleName);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content={props.customValue.Description} />
        <title>{props.customValue.titleName}</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
    </>
  );
};
export default CustomTitle;
