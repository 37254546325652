import React from "react";
import doc2 from "../assets/img/doctor/dr-ramya-prakash.png";
import { useLocation, Link } from "react-router-dom";
import CustomTitle from "../CustomTitle";

export default function Kavya() {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const helmetDescriptions = {
    titleName:
      "Dr.Ramya Prakash | Fertility Specialist | Kangaroo Care Fertility",
    Description: "Dr.Ramya is a consultant obstetrician-gynecologist and reproductive medicine. She has completed her MBBS at Vydehi Institute of medical science and research center",
  };

  return (
    <div id="each-doctor">
      <CustomTitle customValue={helmetDescriptions} />
      <nav aria-label="breadcrumb" id="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>

          <li className="breadcrumb-item active" aria-current="page">
            Doctor
          </li>
        </ol>
      </nav>
      {/* -------------------------doctor detail--------------- */}

      <div className="container mt-4">
        <div className="row">
          <div className="col-md-5">
            <div className="card" style={{ maxWidth: "30rem" }}>
              <img
                src={doc2}
                className="card-img-top"
                alt="Dr. Ramya  "
              />
            </div>
            <br />
            <br />
            <br />
            <h3>More Info</h3>
            <br />
            <p>MBBS, DGO, FIRM(RGUHS) CIMPE</p>
            <p>Fellowship in Reproductive Medicine</p>
            <p>
              Department : <span>Infertility And Ivf</span>
            </p>
            <br />
            {/* <p>Consultations : 15 Minutes per Consultation</p>
            <br /> */}
            <p>Location : Bangalore</p>
            <br />

            <span className="badge bg-primary p-2">
              Available on Prior Appointment
            </span>
            <br />
            <p> - Toll-free number @ 1800-425-4500</p>
            <br />
          </div>

          <div className="col-md-7">
            <h1 className="text-blue">Dr. RAMYA PRAKASH</h1>
            <p>
              <strong>CONSULTANT OBSTETRICIAN GYNAECOLOGIST AND REPRODUCTIVE 
MEDICINE</strong>
            </p>
            <p>
            Dr.Ramya is a consultant obstetrician-gynecologist and reproductive medicine. She has completed her MBBS at Vydehi Institute of medical science and research center
            </p>
            <p>
            She has done Diploma in Obstetric and gynecology (DOG) at Kempegowda Institute Of Medical Sciences.
            </p>
            <p>
            She is a fellowship in Fetal medicine under Dr. Adinarayan at Sparsh Hospital, Bengaluru.
            </p>
            <p>
            She has done a fellowship in reproductive medicine at Manipal Ankur Rajajinagar, Bengaluru, RGUHS affiliated.
            </p>
            <p> She is intrested in Infertility, Fetal medicine, and High-Risk Pregnancy.</p>
            <h3>QUALIFICATION</h3>
            <p>MBBS, DGO, FIRM(RGUHS) CIMPE</p>
            <h3>WORK EXPERIENCE</h3>
            <p> MAY 2022 – till date working as a consultant in IVF ACCESS (Rajajinagar)</p>
            <p>
            Oct 2021- April 2022 – worked as a consultant in Asia-pacific Advanced Fertility 
Centre RR Nagar
            </p>
            <p>
            2021 – worked as a consultant in Reproductive medicine in Gunasheela surgical and 
Maternity Hospital, Basavanagudi Branch
            </p>
            <p>2019- 2021 - Fellowship in reproductive medicine at Manipal Ankur Rajajinagar, 
Bengaluru, RGUHS affiliated.</p>
            <p>2019- Fellowship in fetal medicine under Dr. Adinarayan at Sparsh Hospital, 
Bengaluru.</p>
            <p>2018-Three years (31/07/2015-05/06/2019) of a Senior Resident in the Department of 
obstetrics and gynecology at ESIC Hospital Peenya, Bengaluru. </p>
            <p></p>
            <p>
            2015-Seven months (01/01/2015-30/07/2015) of Senior Resident of OBG at Cloud 
Nine Hospital, Malleswaram, Bengaluru
            </p>
            <h3>EVENTS AND CERTIFICATIONS:</h3>
            <p>2022- Attended National urogynecology conference as chairperson </p>
            <p>2019 - Passed IMS menopause practitioner exam at IMSCON 2019</p>
            <p>2016 - Certificate of appreciation for the support of special service fortnight at 64th
ESIC Day, ESIC Hospital Peenya, Bangalore.</p>
<p>2014 – Certificate for completing hands-on training in Intrauterine 
Insemination at Nova IVI Fertility, Bangalore.</p>
<p> 2014 – Participated in Annual Conference, Gnanvarsha 2014 of the Bangalore 
Society of Obstetrics and Gynaecology at St. John’s Medical College Auditorium, 
Bangalore.</p>
<p>2013 – Participated in Annual Conference, Gnanvarsha 2013 of the Bangalore 
Society of Obstetrics and Gynaecology at Hotel Capitol, Raj Bhavan Road, 
Bangalore.</p>
          </div>
        </div>
      </div>
    </div>
  );
}
