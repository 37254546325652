import React from "react";
import doc3 from "../assets/img/doctor/shankar-singh.jpg";
import { useLocation, Link } from "react-router-dom";
import CustomTitle from "../CustomTitle";

export default function ShankarSingh() {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const helmetDescriptions = {
    titleName: "Dr. Shankar Singh |  Embryologist | Kangaroo Care Fertility",
    Description:
      "Meet Mr. Shankar Singh, your dedicated embryologist, guiding you through the journey of conception and beyond. Experience expert care today.",
  };

  return (
    <div id="each-doctor">
      <CustomTitle customValue={helmetDescriptions} />
      {/* <div
        className="py-md-5"
        style={{
          backgroundImage: "url(" + blogHeader + ")",
          backgroundPosition: "center",
          backgroundSize: "cover",
          height: "400px",
        }}
      >
        <div className="banner-header text-center py-5">
          <h2 style={{ position: "relative", top: "100px", color: "white" }}>
            Dr. CHANDAN
          </h2>
        </div>
      </div> */}
      <br />
      {/* <h6 className="offset-1 my-4 "  style={{ textAlign: "left", color: "black" }}>HOME &gt; MEDIA &gt; Blog</h6> */}
      <nav aria-label="breadcrumb" id="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>

          <li className="breadcrumb-item active" aria-current="page">
            Doctor
          </li>
        </ol>
      </nav>
      {/* -------------------------doctor detail--------------- */}

      <div className="container mt-4">
        <div className="row">
          <div className="col-md-5">
            <div className="card" style={{ maxWidth: "30rem" }}>
              <img src={doc3} className="card-img-top" alt="Dr. Chandan" />
            </div>
            <br />
            <br />
            <br />
            <h3>More Info</h3>
            <br />
            <p>MS.c Zoology, Diploma in Clinical Embryology</p>
            <br />
            <p>
              Department : <span>Infertility And Ivf</span>
            </p>
            <br />
            {/* <p>Consultations :  15 Minutes per Consultation</p>
            <br /> */}
            <p>Location : Bangalore</p>
            <br />

            <span className="badge bg-primary p-2">
              Available on Prior Appointment
            </span>
            <br />
            <p> - Toll-free number @ 1800-425-4500</p>
            <br />
          </div>

          <div className="col-md-7">
            <h1 className="text-blue">Mr. SHANKAR SINGH</h1>
            <p>
              <strong> EMBRYOLOGIST</strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
