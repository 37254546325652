import React from "react";
import doc6 from "../assets/img/doctor/arif1.jpg";
import { useLocation, Link } from "react-router-dom";
import CustomTitle from "../CustomTitle";

export default function Atif() {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const helmetDescriptions = {
    titleName:
      "Dr. Arif N K - Best IVF Specialist in Mysore - Fertility Expert",
    Description:
      "Achieve your parenthood dreams with Dr. Arif N K, Mysore's leading IVF specialist. Expert care and advanced treatments tailored for successful fertility outcomes.",
  };

  return (
    <div id="each-doctor">
      <CustomTitle customValue={helmetDescriptions} />
      {/* <div className="py-md-5" style={{ backgroundImage: "url(" + blogHeader + ")", backgroundPosition: 'center', backgroundSize: 'cover', height: '400px' }}>
        <div className="banner-header text-center py-5">
            <h2 style={{ color: '#fff', position: "relative", top: "100px" }}>Dr. DIVYASREE D</h2>
        </div>
    </div> */}
      <br />
      {/* <h6 className="offset-1 my-4 "  style={{ textAlign: "left", color: "black" }}>HOME &gt; MEDIA &gt; Blog</h6> */}
      <nav aria-label="breadcrumb" id="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>

          <li className="breadcrumb-item active" aria-current="page">
            Doctor
          </li>
        </ol>
      </nav>
      {/* -------------------------doctor detail--------------- */}

      <div className="container mt-4">
        <div className="row">
          <div className="col-md-5">
            <div className="card" style={{ maxWidth: "30rem" }}>
              <img src={doc6} className="card-img-top" alt="Dr. Divyashree D" />
            </div>
            <br />
            <br />
            <br />
            <h3>More Info</h3>
            <br />
            <p>MBBS DGO (AFMC) NB (OBG)</p>
            <br />
            <p>
              Department : <span>Infertility And Ivf</span>
            </p>
            <br />
            {/* <p>Consultations : 15 Minutes per Consultation</p>
            <br /> */}
            <p>Location : Mysore</p>
            <br />

            <span className="badge bg-primary p-2">
              Available on Prior Appointment
            </span>
            <br />
            <p> -Toll-free number @ 1800-425-4500</p>
            <br />
          </div>
          <div className="col-md-7">
            <h1 className="text-blue">Dr. Arif N K</h1>
            <p>
              <strong>IVF SPECIALIST</strong>
            </p>
            <h3>PROFILE:</h3>

            <span>
              Dr. Arif N K is a leading fertility specialist based in Mysore,
              known for his expertise in Assisted Reproductive Technology (ART)
              and fertility-enhancing surgeries. With years of experience in
              treating complex infertility cases, he is recognized for his
              compassionate approach and excellent communication skills. His
              primary focus is on managing difficult cases such as previous IVF
              failures, PCOS, and fertility preservation. Dr. Arif’s vision is
              to alleviate the emotional and physical struggles faced by
              infertile couples, making him one of the most trusted specialists
              in the region.
            </span>
            <br />

            <br />
            <h3>QUALIFICATION</h3>

            <span>MBBS DGO (AFMC) DNB (OBG)</span>
            <br />
            <br />
            <h3>WORK EXPERIENCE</h3>

            <span>
              <ol>
                <li>Obstetrician and Gynaecologist, Kuwait</li>
                <li>ART Specialist, Kuwait</li>
                <li>Manipal Hospital, Mysore</li>
                <li>Santaan Fertility Clinic</li>
                <li>Gunasheela Surgical and Maternity Hospital, Bangalore</li>
              </ol>
            </span>
            <br />
            <br />
            <h3> RESEARCH & PUBLICATIONS</h3>

            <span>
              Dr. Arif has attended multiple advanced courses on infertility
              from prestigious institutions, including the Cleveland Clinic,
              ASRM (American Society of Reproductive Medicine), CRAFT
              Kodungallur, and BJOG/RCOG. He actively participates in ongoing
              research and professional discussions related to reproductive
              medicine.
            </span>

            <br />
            <br />

            <h3>CERTIFICATIONS</h3>
            <span>
              <ol>
                <li>Fellowship in Infertility (IMA)</li>
                <li>
                  Advanced Diploma in ART and Reproductive Medicine,
                  Schleswig-Holstein, Kiel, Germany
                </li>
                <li>Fellowship in Sexual Medicine, JSS Mysore</li>
                <li>
                  Advanced Laparoscopy Training, CEMAST, Govindkripa Laparoscopy
                  Center
                </li>
                <li>Ultrasound Training, Kuwait</li>
                <li>Ian-Donald Inter University Medical Ultrasound</li>
                <li>
                  DFOGSI Advanced Ultrasound Course, Radhakrishna Hospital,
                  Bangalore
                </li>
              </ol>
            </span>

            <br />
            <br />

            <h3>SUB- SPECIALITY INTERESTS </h3>

            <span>
              <ol>
                <li>PCOS management</li>
                <li>Fertility preservation</li>
                <li>
                  Fertility-enhancing gynecological surgeries: hysteroscopy,
                  laparoscopy for endometriosis, fibroid, uterine polyps,
                  ovarian cyst, hydrosalpinx, and tubal factor infertility
                </li>
              </ol>
            </span>

            {/* <ol></ol> */}
            {/* <p>
              Dr. Divyasree is practicing obstetrician and gynecologist with
              experience of more than 10 yrs and exclusive experience in
              treating infertile couple for the past 3 years.
            </p>
            <p>
              She completed her MBBS from prestigious Gandhi medical college,
              Hyderabad and did her post graduation in Obstetrics and Gynecology
              from CAIMS Karimnagar, Andra Pradesh. She has done her fellowship
              in reproductive medicine from CRAFTS hospital and research
              institute, Kerala. She is trained in minimal invasive surgeries,
              at Bangalore.
            </p>
            <p>
              Dr. Divyasree is specialized in treating infertile couple with
              PCOS, poor ovarian reserve, recurrent IVF failures and recurrent
              pregnancy loss.
            </p>
            <p>
              She has given good pregnancy rates and take home baby rate in
              infertile couple
            </p>
            <h3>QUALIFICATION</h3>
            <p>MBBS, MS (OBG)</p>
            <p>Fellowship in reproductive medicine</p>
            <h3>WORK EXPERIENCE</h3>
            <p>
              Consultant at Garbhagudi IVF center, Bangalore from 2018 till
              2021.
            </p>
            <p>
              Reproductive medicine unit at CRAFTS hospital, Kerala 2017 t0
              2018.
            </p>
            <p>
              Assistant professor in dept of OBG at PESIMSR, Kuppam.2012 to
              2017.
            </p>
            <h3>RESEARCH AND PUBLICATIONS:</h3>
            <p>
              She has presented and published various clinical papers and case
              reports in national journals and conferences.
            </p>
            <p>
              Testicular sperms versus macs in patients with high dna
              fragmentation index and their ICSI outcome - presentation at
              national fertility conference 2018.
            </p>
            <p>
              Laparoscopic management of large ovarian cyst – published in
              international journal Reprod contracept Obstet Gynecol 2017.
            </p>
            <p>
              Disengagement of the deeply engaged fetal head during caesarean
              section in advanced labour : patwardhan versus push method
              published in international journal reprod contracept obstet
              gynecol 2015.
            </p>
            <h3>MEMBERSHIP:</h3>
            <p>Karnataka Medical Council</p>
            <p>She is a life time member in Indian fertility society.</p> */}
          </div>
        </div>
      </div>
    </div>
  );
}
