import React from 'react'
import doc from "../../assets/img/doctor/pallavi1.jpg";
import CustomTitle from '../../CustomTitle';
import {useLocation, Link} from "react-router-dom";

export default function Pallavi() {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const helmetDescriptions = {
    titleName: "Ms. Pavithra H - Best Embryologist in Ramanagara/Bangalore",
    Description:
      "Looking for the best embryologist in Ramanagara or Bangalore? Consult Ms. Pavithra H., a top specialist offering expert fertility care and personalized treatments.",
  };

  return (
    <div id="each-doctor">
      <CustomTitle customValue={helmetDescriptions} />
      {/* <div className="py-md-5" style={{ backgroundImage: "url(" + blogHeader + ")", backgroundPosition: 'center', backgroundSize: 'cover', height: '400px' }}>
        <div className="banner-header text-center py-5">
            <h2 style={{ color: '#fff', position: "relative", top: "100px" }}>Dr. DIVYASREE D</h2>
        </div>
    </div> */}
      <br />
      {/* <h6 className="offset-1 my-4 "  style={{ textAlign: "left", color: "black" }}>HOME &gt; MEDIA &gt; Blog</h6> */}
      <nav aria-label="breadcrumb" id="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>

          <li className="breadcrumb-item active" aria-current="page">
            Doctor
          </li>
        </ol>
      </nav>
      {/* -------------------------doctor detail--------------- */}

      <div className="container mt-4">
        <div className="row">
          <div className="col-md-5">
            <div className="card" style={{ maxWidth: "30rem" }}>
              <img src={doc} className="card-img-top" alt="Dr. Divyashree D" />
            </div>
            <br />
            <br />
            <br />
            <h3>More Info</h3>
            <br />
            <p>MBBS, MS (OBG),DNB, FRM</p>
            <br />
            <p>
              Department : <span>Infertility And Ivf</span>
            </p>
            <br />
            {/* <p>Consultations : 15 Minutes per Consultation</p>
            <br /> */}
            <p>Location : Ramanagara</p>
            <br />

            <span className="badge bg-primary p-2">
              Available on Prior Appointment
            </span>
            <br />
            <p> - Toll-free number @ 1800-425-4500</p>
            <br />
          </div>

          <div className="col-md-7">
            <h1 className="text-blue">Ms . PALLAVI R GANGATKAR</h1>
            <p>
              <strong>FERTILITY SPECIALIST</strong>
            </p>
            <br />

            <br />
            <p>
              Dr. Pallavi Gangatkar, Consultant Fertility specialist at Kangaroo
              Fertility, Ramanagaram. She completed her MBBS from Prestigious
              Kasturba Medical college – Manipal University and did her
              postgraduation from KVG medical college Sullia. She has done her
              Reproductive Medicine fellowship from Garbhagudi Institute of
              Reproductive health and Research, Bangalore. She’s Diplomate of
              National Board Examination, Delhi in Obstetrics and Gynaecology.
              She has also undergone training in Advanced Laparoscopy from
              Apollo Hospital, Bhuvaneshwar,
            </p>
            <p>
              She believes that with the right support, determination, and a
              sprinkle of scientific miracles, dreams of parenthood can come
              true.
            </p>

            <h3>
              <strong>Qualification:</strong>
            </h3>
            <p>
              <ul>
                <li>
                  MBBS from Kasturba Medical college, Mangalore affiliated to
                  Manipal University
                </li>
                <li>
                  MS (OBG) from KVG Medical college Sullia, affiliated to RGUHS
                </li>
                <li>DNB (OBG) from National Board of Examination, New Delhi</li>
                <li>
                  Fellowship in Reproductive Medicine from Garbhagudi Institute
                  of Reproductive Health and Research Bangalore{" "}
                </li>
              </ul>
            </p>
            <h3>
              <strong>Work Experience:</strong>
            </h3>
            <p>
              <ul>
                <li>
                  Senior Resident in Mysore medical college (Cheluvamba
                  hospital) and Bangalore Medical college (Vani Vilas hospital).
                </li>
                <li>
                  Clinical Research Fellow – Research project from Imperial
                  college London at Bangalore Medical College & Research
                  Institute and Karnataka Institute of Medical sciences, Hubli.
                </li>
                <li>
                  Consultant Obstetrics and Gynecology – Sri Ganga Hospital,
                  Karimnagar Telangana
                </li>
              </ul>
            </p>
            <h3>
              <strong>Specializes in</strong>
            </h3>
            <p>
              <ul>
                <li>Management of Repeated IVF failure</li>
                <li>PCOS management </li>
                <li>Recurrent pregnancy loss</li>
                <li>Fertility preservation</li>
              </ul>
            </p>
            <h3>
              <strong>Professional Membership</strong>
            </h3>
            <p>
              <ul>
                <li>
                  Federation of Obstetric and Gynaecological societies of India
                  (FOGSI)
                </li>
                <li>Indian Society for Assisted Reproduction (ISAR)</li>
                <li>Indian Medical Association (IMA)</li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
