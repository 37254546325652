import React from "react";
import { useLocation, Link } from "react-router-dom";
import errorimg1 from "./assets/img/error.jpg";
import "./error.css";
export default function Error() {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <div className="my-5 py-5 text-center">
      <div>
        <img src={errorimg1} />
      </div>
      <h1 className=" text-danger">Error 404</h1>
      <h3 className=" text-blue" id="error-message">
        Page Not Found
      </h3>
      <Link to="/" className="btn btn-outline-danger">
        Go&nbsp;To&nbsp;Home
      </Link>
    </div>
  );
}
