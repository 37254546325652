import React from "react";
import { Link } from "react-router-dom";
const Sitemap = () => {
  return (
    <>
      <div className="container" id="sitemap-buttons">
        <nav style={{ "--bs-breadcrumb-divider": ">" }} aria-label="breadcrumb">
          <ol class="breadcrumb mt-2">
            <li class="breadcrumb-item">
              <a href="#">Home</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Sitemap
            </li>
          </ol>
        </nav>
        <div className="row my-sm-5 my-3 ms-sm-5">
          <div className="col">
            <div className="col-button">
              <Link to="/" className="btn btn-danger d-inline-block my-2">
                Home
              </Link>
            </div>
            <div className="col-button">
              <Link
                to="/about-us"
                className="btn btn-danger d-inline-block my-2"
              >
                About us
              </Link>
            </div>
            <div className="col-button">
              <Link to="/" className="btn btn-danger d-inline-block my-2">
                Fertility
              </Link>
              <div className="col-button ms-sm-4 ms-2">
                <Link
                  to="/treatments/fertility-testing-for-men/"
                  className="btn btn-outline-danger d-inline-block my-2"
                >
                  Male Fertility
                </Link>
              </div>
              <div className="col-button ms-sm-4 ms-2">
                <Link
                  to="/treatments/fertility-testing-for-women"
                  className="btn btn-outline-danger d-inline-block my-2"
                >
                  Female Fertility
                </Link>
              </div>
            </div>
            <div className="col-button">
              <Link
                to="/treatments"
                className="btn btn-danger d-inline-block my-2"
              >
                Treatments
              </Link>
              <div className="col-button ms-sm-4 ms-2">
                <Link
                  to="/treatments/ovulation-induction"
                  className="btn btn-outline-danger d-inline-block my-2"
                >
                  Ovulation induction
                </Link>
              </div>
              <div className="col-button ms-sm-4 ms-2">
                <Link
                  to="/treatments/intrauterine"
                  className="btn btn-outline-danger d-inline-block my-2"
                >
                  Intrauterine Insemination (IUI)
                </Link>
              </div>
              <div className="col-button ms-sm-4 ms-2">
                <Link
                  to="/treatments/invitro-fertilization"
                  className="btn btn-outline-danger d-inline-block my-2"
                >
                  In Vitro Fertilisation (IVF)
                </Link>
              </div>
              <div className="col-button ms-sm-4 ms-2">
                <Link
                  to="/treatments/intracytoplasmic"
                  className="btn btn-outline-danger d-inline-block my-2"
                >
                  Intracytoplasmic Sperm Injection (ICSI)
                </Link>
              </div>
              <div className="col-button ms-sm-4 ms-2">
                <Link
                  to="/treatments/genetic-counselling-and-testing"
                  className="btn btn-outline-danger d-inline-block my-2"
                >
                  Genitic Counselling and Testing
                </Link>
              </div>
              <div className="col-button ms-sm-4 ms-2">
                <Link
                  to="/varicocele"
                  className="btn btn-outline-danger d-inline-block my-2"
                >
                  Andrology
                </Link>
                <div className="col-button ms-sm-4 ms-2">
                  <Link
                    to="/treatments/varicocele/"
                    className="btn btn-outline-danger d-inline-block my-2"
                  >
                    Varicocele
                  </Link>
                </div>
                <div className="col-button ms-sm-4 ms-2">
                  <Link
                    to="/treatments/tesa-pesa"
                    className="btn btn-outline-danger d-inline-block my-2"
                  >
                    TESA / PESA
                  </Link>
                </div>
              </div>
              <div className="col-button ms-sm-4 ms-2">
                <Link
                  to="/treatments/fertility-preservation"
                  className="btn btn-outline-danger d-inline-block my-2"
                >
                  Fertility Preservation
                </Link>
              </div>
              <div className="col-button ms-sm-4 ms-2">
                <Link
                  to="/treatments/donor"
                  className="btn btn-outline-danger d-inline-block my-2"
                >
                  Donar
                </Link>
              </div>
            </div>
            <div className="col-button">
              <Link
                to="/doctor/doctors"
                className="btn btn-danger d-inline-block my-2"
              >
                Our Doctors
              </Link>
            </div>
            <div className="col-button">
              <Link
                to="/facility"
                className="btn btn-danger d-inline-block my-2"
              >
                Facilities
              </Link>
            </div>
            <div className="col-button">
              <Link
                to="/media/blogs"
                className="btn btn-danger d-inline-block my-2"
              >
                Media
              </Link>
              <div className="col-button ms-sm-4 ms-2">
                <Link
                  to="/media/blogs/"
                  className="btn btn-outline-danger d-inline-block my-2"
                >
                  Our Blogs
                </Link>
              </div>
              <div className="col-button ms-sm-4 ms-2">
                <Link
                  to="/media/faq"
                  className="btn btn-outline-danger d-inline-block my-2"
                >
                  FAQ
                </Link>
              </div>
            </div>
            <div className="col-button">
              <Link
                to="/careers"
                className="btn btn-danger d-inline-block my-2"
              >
                Careers
              </Link>
            </div>
            <div className="col-button">
              <Link
                to="/contact"
                className="btn btn-danger d-inline-block my-2"
              >
                Contacts
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sitemap;
