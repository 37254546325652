import React from "react";
import styles from "./bottombar.module.css";

export default function BottomBar() {
  return (
    <section className={styles.bottomBarContainer}>
      <div className={styles.bottomBarWidth}>
        <article className={styles.cards}>
          <i className="fa fa-phone" aria-hidden="true"></i>
          <a href="tel:18004254500">1800-425-4500</a>
        </article>
        <article className={styles.cards}>
          <i className="fa fa-address-book" aria-hidden="true"></i>
          <a href="/appointments">Book An Appointment</a>
        </article>
        {/* <article className={styles.cards}>
          <i className="fa fa-user-md" aria-hidden="true"></i>
          <a href="/doctors">Find Doctor</a>
        </article> */}
      </div>
    </section>
  );
}
