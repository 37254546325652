import React from "react";
import kcf_white_logo from "./kcf_white_logo.webp";
import "./Footer.css";
import { useLocation, Link } from "react-router-dom";

// import AOS from "aos";
// import "aos/dist/aos.css";
function Footer() {
  // React.useEffect(() => {
  //   AOS.init();
  //   AOS.refresh();
  // }, []);
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <footer className="container-fluid bg-secondary">
      <div className="row text-white">
        <div
          className="col-md-4 col-sm-12 order-1 order-sm-0 p-4 "
          style={{ backgroundColor: "#7E52A0" }}
        >
          <div className="footer-logo footer_logo_container">
            <Link title="Kangaroo Care - Women & Child Hospital" to={`#}`}>
              <img
                className="footer-logo pb-4"
                src={kcf_white_logo}
                alt="Kangaroo Care Fertility Logo in White"
              />
            </Link>
            <p>Kangaroo Care Fertility: Nurturing Dreams into Reality</p>
            <p>
              At Kangaroo Care Fertility, we are dedicated to offering
              personalized, cost-effective fertility treatments that cater to
              your unique needs. Our dedicated team is committed to delivering
              the highest standard of care with integrity and transparency,
              ensuring that your journey is supportive and ethical. We
              understand the emotional and physical challenges of fertility, and
              we stand by you with compassion and expertise every step of the
              way. Trusted by countless families, we pride ourselves on our
              successful outcomes and heartfelt connections with our patients.
            </p>
            <p>"Where hope meets happiness."</p>

            <p>
              Trusted by <strong>many </strong> families
            </p>
          </div>
        </div>
        <div
          className="col-md-8 col-sm-12 order-0 order-sm-1 p-4"
          style={{ backgroundColor: "#9b5ba4" }}
        >
          <div className="row footer_address">
            <div className="col-md-6 mb-3">
              <div className="services-link">
                <h3 className="heading">Services</h3>
                <ul className="list-unstyled">
                  <li>
                    <Link to="/treatments/fertility-testing-for-men">
                      Male Fertility
                    </Link>
                  </li>
                  <li>
                    <Link to="/treatments/fertility-testing-for-women">
                      Female Fertility
                    </Link>
                  </li>
                  <li>
                    <Link to="/treatments/ovulation-induction">
                      Ovulation Induction
                    </Link>
                  </li>

                  <li>
                    <Link to="/treatments/intrauterine">
                      Intrauterine Insemination (IUI)
                    </Link>
                  </li>
                  <li>
                    <Link to="/treatments/invitro-fertilization">
                      In Vitro Fertilisation (IVF)
                    </Link>
                  </li>
                  <li>
                    <Link to="/treatments/intracytoplasmic">
                      Intracytoplasmic Sperm Injection (ICSI)
                    </Link>
                  </li>
                  <li>
                    <Link to="/treatments/genetic-counselling-and-testing">
                      Genetic Counselling and Testing
                    </Link>
                  </li>
                  <li>
                    <Link to="/treatments/varicocele">Andrology</Link>
                  </li>
                </ul>
              </div>

              <div className="col-md-6 mb-3">
                <div className="follow-link">
                  <h3 className="heading">Follow Us On</h3>
                  <ul className="list-icons">
                    <li>
                      <a href="https://www.linkedin.com/company/kangaroocare/ ">
                        <i class="fab fa-linkedin"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.facebook.com/kangaroocarehospital/">
                        <i class="fab fa-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/kangaroocareindia/">
                        <i class="fab fa-instagram"></i>
                      </a>
                    </li>

                    <li>
                      <a href="https://x.com/KangarooCare3">
                        <i class="fab fa-twitter"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-md-6 mb-3">
              <div className="row d-flex justify-content-between ">
                <div className="col-md-12 contact-info-link">
                  <h3 className="heading first_heading">
                    Bangalore Contact Info
                  </h3>
                  <address>
                    <p>
                      #119, 17th cross MC Layout, Vijaynagar, Bangalore - 560040
                    </p>
                  </address>{" "}
                  <br />
                  {/* <p>
                    <a href="mailto:fertilityblr@kangaroocareindia.com">
                      <i className="fas fa-envelope-open me-2"></i>
                      fertilityblr@kangaroocareindia.com
                    </a>
                  </p>
                  <div className="contact-number">
                    <i className="fas fa-tty me-2"></i>
                    <a href="tel:1800-425-4500"> Toll Free - 1800-425-4500</a>
                  </div> */}
                </div>
                <br />
                <div className="col-md-12 contact-info-link">
                  <h3 className="heading">Mysore Contact Info</h3>
                  <address>
                    <p>
                      #384, New Kalidasa Road, Opposite Kangaroo Care Hospitals,
                      Vijayanagar, 1st Stage, Mysore - 570017
                    </p>
                  </address>{" "}
                  <br />
                  {/* <p>
                    <a href="mailto:fertilityblr@kangaroocareindia.com">
                      <i className="fas fa-envelope-open me-2"></i>
                      fertilityblr@kangaroocareindia.com
                    </a>
                  </p>
                  <div className="contact-number">
                    <i className="fas fa-tty me-2"></i>
                    <a href="tel:1800-425-4500"> Toll Free - 1800-425-4500</a>
                  </div> */}
                </div>
                <br />
                <div className=" col-md-12 contact-info-link">
                  <h3 className="heading">Ramanagara Contact Info</h3>
                  <address>
                    <p>
                      No.32 of Vivekanandanagar, Block-3, North B.M. Road,
                      Ramanagara -562 159 Karnataka
                    </p>
                    <p></p>
                  </address>{" "}
                  <br />
                  {/* <p>
                    <a href="mailto:fertilityblr@kangaroocareindia.com">
                      <i className="fas fa-envelope-open me-2"></i>
                      fertilityblr@kangaroocareindia.com
                    </a>
                  </p>
                  <div className="contact-number">
                    <i className="fas fa-tty me-2"></i>
                    <a href="tel:1800-425-4500"> Toll Free - 1800-425-4500</a>
                  </div> */}
                  <p>
                    <a href="mailto:fertilitymarketing@kangaroocareindia.com">
                      <i className="fas fa-envelope-open me-2"></i>
                      fertilitymarketing@kangaroocareindia.com
                    </a>
                  </p>
                  <div className="contact-number">
                    <i className="fas fa-tty me-2"></i>
                    <a href="tel:1800-425-4500"> Toll Free - 1800-425-4500</a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12 mb-3">
              {/* <div className="row d-flex justify-content-between ">
                <div className="col-md-4 contact-info-link">
                  <h3 className="heading first_heading">
                    Bangalore Contact Info
                  </h3>
                  <address>
                    <p>
                      #119, 8th cross MC Layout, Vijaynagar, Bangalore - 560040
                    </p>
                  </address>{" "}
                  <p>
                    <a href="mailto:fertilityblr@kangaroocareindia.com">
                      <i className="fas fa-envelope-open me-2"></i>
                      fertilityblr@kangaroocareindia.com
                    </a>
                  </p>
                  <div className="contact-number">
                    <i className="fas fa-tty me-2"></i>
                    <a href="tel:1800-425-4500"> Toll Free - 1800-425-4500</a>
                  </div>
                </div>
                <br />
                <div className="col-md-4 contact-info-link">
                  <h3 className="heading">Mysore Contact Info</h3>
                  <address>
                    <p>
                      #505, Kalidasa road, Vijayanagar 1st stage, Opp. MUDA
                      Complex Mysore - 570017.
                    </p>
                  </address>{" "}
                  <p>
                    <a href="mailto:fertilityblr@kangaroocareindia.com">
                      <i className="fas fa-envelope-open me-2"></i>
                      fertilityblr@kangaroocareindia.com
                    </a>
                  </p>
                  <div className="contact-number">
                    <i className="fas fa-tty me-2"></i>
                    <a href="tel:1800-425-4500"> Toll Free - 1800-425-4500</a>
                  </div>
                </div>
                <br />
                <div className=" col-md-4 contact-info-link">
                  <h3 className="heading">Ramanagara Contact Info</h3>
                  <address>
                    <p>
                      No.32 of Vivekanandanagar, Block-3, North B.M. Road,
                      Ramanagara -562 159 Karnataka
                    </p>
                    <p></p>
                  </address>{" "}
                  <p>
                    <a href="mailto:fertilityblr@kangaroocareindia.com">
                      <i className="fas fa-envelope-open me-2"></i>
                      fertilityblr@kangaroocareindia.com
                    </a>
                  </p>
                  <div className="contact-number">
                    <i className="fas fa-tty me-2"></i>
                    <a href="tel:1800-425-4500"> Toll Free - 1800-425-4500</a>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="row py-2">
        <div className="col-12 text-center text-white">
          © Managed & Maintained by <a href="http://digikit.in"> DIGIKIT</a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
