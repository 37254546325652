import React from "react";
import styles from "./appointments.module.css";
import { Container } from "react-bootstrap";
import { Helmet } from "react-helmet";



export default function Appointments() {
  const helmetDescriptions = {
    titleName: "Book Your Appointment | Kangaroo Care Fertility Hospital",
    description:
      "Schedule your appointment at Kangaroo Care Fertility Hospital. Expert care in fertility, maternity, and neonatal services. Start your journey to parenthood today.",
  };
  return (
    <section className={styles.wrapper}>
      <Helmet>
        <title>{helmetDescriptions.titleName}</title>
        <meta name="description" content={helmetDescriptions.description} />
      </Helmet>
      <Container width>
        <div className={styles.container}>
          <h1>Book Appointment</h1>
        </div>
        <div className={styles.button_items}>
          <div className={styles.button_item}>
            <button>
              <a href="/book-appointment/fertility">
                Fertility Center Bengaluru
              </a>
            </button>
          </div>
          <div className={styles.button_item}>
            <button>
              <a href="/book-appointment/mysore-fertility">
                Fertility Center Mysore
              </a>
            </button>
          </div>
          <div className={styles.button_item}>
            <button>
              <a href="/book-appointment/ramanagara-fertility">
                Fertility Center Ramanagara
              </a>
            </button>
          </div>
        </div>
      </Container>
    </section>
  );
}
