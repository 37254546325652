const Bangalore = () => {
  const schema1 = `<script type='application/ld+json'>
  {
      "@context": "https://www.schema.org",
      "@type": "Physician",
      "name": "Dr. DIVYASREE D",
      "url": "https://kangaroocarefertility.com/doctor/divyasree",
      "logo": "https://kangaroocarefertility.com/static/media/kcf_logo.0b66caa0.png",
      "image": "https://kangaroocarefertility.com/static/media/dr-divyashree.d51f5a9b.png",
      "description": "Explore excellence in reproductive medicine with Dr. Divyasree's Fellowship program. Elevate your skills at Kangaroo Care Fertility. Enroll now!",
      "address":{
      "@type":"PostalAddress",
      "addressLocality":"Bangalore",
      "addressCountry":"IND"
      } ,   
      "telephone": "1800-425-4500",
      "medicalSpecialty":"FELLOWSHIP IN REPRODUCTIVE MEDICINE"
      }
  }
</script><`;
  const schema2 = `<script type='application/ld+json'>
  {
      "@context": "https://www.schema.org",
      "@type": "Physician",
      "name": "DR. RAMYA PRAKASH",
      "url": "https://kangaroocarefertility.com/doctor/ramya",
      "logo": "https://kangaroocarefertility.com/static/media/kcf_logo.0b66caa0.png",
      "image": "https://kangaroocarefertility.com/static/media/dr-ramya-prakash.ddef0438.png",
      "description": "Dr.Ramya is a consultant obstetrician-gynecologist and reproductive medicine. She has completed her MBBS at Vydehi Institute of medical science and research center (163 characters)",
      "address":{
      "@type":"PostalAddress",
      "addressLocality":"Bangalore",
      "addressCountry":"IND"
      } ,   
      "telephone": "1800-425-4500",
      "medicalSpecialty":"CONSULTANT OBSTETRICIAN GYNAECOLOGIST AND REPRODUCTIVE MEDICINE"
      }
  }
</script>`;
  const schema3 = `<script type='application/ld+json'>
  {
      "@context": "https://www.schema.org",
      "@type": "Physician",
      "name": "Dr. CHANDAN",
      "url": "https://kangaroocarefertility.com/doctor/chandan",
      "logo": "https://kangaroocarefertility.com/static/media/kcf_logo.0b66caa0.png",
      "image": "https://kangaroocarefertility.com/static/media/dr-chandan-new.1d2fc985.jpg",
      "description": "Discover excellence in fertility care with Dr. Chandan at Kangaroo Care Fertility. Empowering your path to parenthood with compassionate expertise.",
      "address":{
      "@type":"PostalAddress",
      "addressLocality":"Bangalore",
      "addressCountry":"IND"
      } ,   
      "telephone": "1800-425-4500",
      "medicalSpecialty":"SENIOR EMBRYOLOGIST"
      }
  }
  
</script>`;
  const schema4 = ` <script type='application/ld+json'>
  {
      "@context": "https://www.schema.org",
      "@type": "Physician",
      "name": "Mr. SHANKAR SINGH",
      "url": "https://kangaroocarefertility.com/doctor/shankar-singh",
      "logo": "https://kangaroocarefertility.com/static/media/kcf_logo.0b66caa0.png",
      "image": "https://kangaroocarefertility.com/static/media/shankar-singh.ec38b24b.jpg",
      "description": "Meet Dr. Shankar Singh, an expert embryologist at Kangaroo Care Fertility. Experience unparalleled fertility care with our dedicated specialist.",
      "address":{
      "@type":"PostalAddress",
      "addressLocality":"Bangalore",
      "addressCountry":"IND"
      } ,   
      "telephone": "1800-425-4500",
      "medicalSpecialty":"EMBRYOLOGIST"
      }
  }
 
</script>`;
  const schema5 = `<script type='application/ld+json'>
  {
      "@context": "https://www.schema.org",
      "@type": "Physician",
      "name": "Dr. SATISH BABU",
      "url": "https://kangaroocarefertility.com/doctor/satis",
      "logo": "https://kangaroocarefertility.com/static/media/kcf_logo.0b66caa0.png",
      "image": "https://kangaroocarefertility.com/static/media/satosh-babu-new.44c2aaed.png",
      "description": "Expert diabetes and endocrinology care at Kangaroo Care Fertility. Trust our skilled doctors for comprehensive treatment and personalized care.",
      "address":{
      "@type":"PostalAddress",
      "addressLocality":"Bangalore",
      "addressCountry":"IND"
      } ,   
      "telephone": "1800-425-4500",
      "medicalSpecialty":"DIABETES AND ENDOCRINOLOGY"
      }
  }
</script>`;
  const schema6 = `     <script type='application/ld+json'>
  {
      "@context": "https://www.schema.org",
      "@type": "Physician",
      "name": "Dr. HEMA H R",
      "url": "https://kangaroocarefertility.com/doctor/hema",
      "logo": "https://kangaroocarefertility.com/static/media/kcf_logo.0b66caa0.png",
      "image": "https://kangaroocarefertility.com/static/media/dr-hema-h-r.75a6c7ed.png",
      "description": "",
      "address":{
      "@type":"PostalAddress",
      "addressLocality":"Bangalore",
      "addressCountry":"IND"
      } ,   
      "telephone": "1800-425-4500",
      "medicalSpecialty":"FAMILY PHYSICIAN"
      }
  }
</script>`;

  return (
    <div>
      <iframe srcDoc={schema1} title="Blog Schema" width="0" height="0" />
      <iframe srcDoc={schema2} title="Blog Schema" width="0" height="0" />
      <iframe srcDoc={schema3} title="Blog Schema" width="0" height="0" />
      <iframe srcDoc={schema4} title="Blog Schema" width="0" height="0" />
      <iframe srcDoc={schema5} title="Blog Schema" width="0" height="0" />
      <iframe srcDoc={schema6} title="Blog Schema" width="0" height="0" />
    </div>
  );
};

export default Bangalore;
