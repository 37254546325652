import React from "react";
import doc3 from "../assets/img/doctor/swathi1.jpg";
import { useLocation, Link } from "react-router-dom";
import CustomTitle from "../CustomTitle";

export default function DrSwathi() {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const helmetDescriptions = {
    titleName:
      "Dr. SWATHI H K  OBG and Laparoscopic surgeon at Kangaroo Care Fertility",
    Description:
      "Dr. SWATHI H K  OBG and Laparoscopic surgeon at Kangaroo Care Fertility. She completed her MBBS from Hassan institute of medical sciences, Hassan.",
  };

  return (
    <div id="each-doctor">
      <CustomTitle customValue={helmetDescriptions} />
      {/* <div className="py-md-5" style={{ backgroundImage: "url(" + blogHeader + ")", backgroundPosition: 'center', backgroundSize: 'cover', height: '400px' }}>
        <div className="banner-header text-center py-5">
            <h2 style={{ color: '#fff', position: "relative", top: "100px" }}>Dr. DIVYASREE D</h2>
        </div>
    </div> */}
      <br />
      {/* <h6 className="offset-1 my-4 "  style={{ textAlign: "left", color: "black" }}>HOME &gt; MEDIA &gt; Blog</h6> */}
      <nav aria-label="breadcrumb" id="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>

          <li className="breadcrumb-item active" aria-current="page">
            Doctor
          </li>
        </ol>
      </nav>
      {/* -------------------------doctor detail--------------- */}

      <div className="container mt-4">
        <div className="row">
          <div className="col-md-5">
            <div className="card" style={{ maxWidth: "30rem" }}>
              <img src={doc3} className="card-img-top" alt="Dr. Divyashree D" />
            </div>
            <br />
            <br />
            <br />
            <h3>More Info</h3>
            <br />
            <p>MBBS</p>
            <br />
            <p>
              Department : <span>IVF and Fertility</span>
            </p>
            <br />
            {/* <p>Consultations : 15 Minutes per Consultation</p>
            <br /> */}
            <p>Location : Mysore</p>
            <br />

            <span className="badge bg-primary p-2">
              Available on Prior Appointment
            </span>
            <br />
            <p> - Toll-free number @ 1800-425-4500</p>
            <br />
          </div>
          <div className="col-md-7">
            <h1 className="text-blue">Dr SWATHI H K</h1>
            <p>
              <strong>IVF AND FERTILITY SPECIALIST</strong>
            </p>
            <h3>Profile</h3>
            <span>
              Dr. Swathi H K is a practicing Obstetrician, Gynecologist, and
              Fertility Specialist. Specialized in treating female factor
              infertility related to PCOS, recurrent pregnancy loss, poor
              ovarian reserve, recurrent implantation failures, and male factor
              infertility. Dr. Swathi is also skilled in fertility-enhancing
              surgeries and has a special interest in reproductive
              endocrinology, fertility preservation, and regenerative medicine.
            </span>
            <br />
            <br />
            <h3>QUALIFICATION</h3>
            <ul>
              <li>MBBS – Hassan Institute of Medical Sciences, Hassan</li>
              <li>
                MS (OBG) – JSS Academy of Higher Education and Research, Mysore
              </li>
              <li>DNB (OBG) – National Medical Council</li>
              <li>
                FRGUHS (Reproductive Medicine) – Rajiv Gandhi University of
                Health Sciences, Santasa IVF and Endo Surgery Institute
              </li>
              <li>FMAS – Basic and Advanced Laparoscopic Surgery</li>
            </ul>
            <br />
            <h3>WORK EXPERIENCE</h3>
            <ul>
              <li>
                Trained in basic and advanced laparoscopic surgery under Dr.
                Paul at Paul’s Hospital, Ernakulam, Kochi.
              </li>
              <li>
                Expertise in fertility-enhancing surgeries such as treatment for
                polyps, endometriosis, tubal factor infertility, fibroids, and
                adenomyosis.
              </li>
            </ul>
            <br />
            <h3>RESEARCH & PUBLICATION</h3>
            <ol>
              <li>
                Pattern of congenital anomalies and associated maternal risk
                factors: A study from Mysore, South India. Indian J Obstet
                Gynecol Res, 2019.
              </li>
              <li>
                Efficacy and Safety of Electrothermal Bipolar Vessel Sealer vs
                ENSEAL in Total Laparoscopic Hysterectomy for Large Uterus: A
                Comparative Study in Mysuru, South India. World J Lap Surg,
                2021.
              </li>
              <li>
                A novel therapy for spontaneous previable amniotic fluid leak by
                using intra‐amniotic platelet-rich fibrin and plasma: A case
                report.
              </li>
            </ol>
            <br />
            <h3>PROFESSIONAL MEMBERSHIP</h3>
            <ol>
              <li>
                Federation of Obstetric and Gynecological Societies of India
                (FOGSI)
              </li>
              <li>Indian Society for Assisted Reproduction (ISAR)</li>
              <li>Karnataka Chapter of ISAR (KISAR)</li>
              <li>Indian Association of Gynaecological Endoscopists (IAGE)</li>
              <li>Indian Medical Association (IMA) </li>
            </ol>
            <br />

            {/* <p>
              Dr. Divyasree is practicing obstetrician and gynecologist with
              experience of more than 10 yrs and exclusive experience in
              treating infertile couple for the past 3 years.
            </p>
            <p>
              She completed her MBBS from prestigious Gandhi medical college,
              Hyderabad and did her post graduation in Obstetrics and Gynecology
              from CAIMS Karimnagar, Andra Pradesh. She has done her fellowship
              in reproductive medicine from CRAFTS hospital and research
              institute, Kerala. She is trained in minimal invasive surgeries,
              at Bangalore.
            </p>
            <p>
              Dr. Divyasree is specialized in treating infertile couple with
              PCOS, poor ovarian reserve, recurrent IVF failures and recurrent
              pregnancy loss.
            </p>
            <p>
              She has given good pregnancy rates and take home baby rate in
              infertile couple
            </p>
            <h3>QUALIFICATION</h3>
            <p>MBBS, MS (OBG)</p>
            <p>Fellowship in reproductive medicine</p>
            <h3>WORK EXPERIENCE</h3>
            <p>
              Consultant at Garbhagudi IVF center, Bangalore from 2018 till
              2021.
            </p>
            <p>
              Reproductive medicine unit at CRAFTS hospital, Kerala 2017 t0
              2018.
            </p>
            <p>
              Assistant professor in dept of OBG at PESIMSR, Kuppam.2012 to
              2017.
            </p>
            <h3>RESEARCH AND PUBLICATIONS:</h3>
            <p>
              She has presented and published various clinical papers and case
              reports in national journals and conferences.
            </p>
            <p>
              Testicular sperms versus macs in patients with high dna
              fragmentation index and their ICSI outcome - presentation at
              national fertility conference 2018.
            </p>
            <p>
              Laparoscopic management of large ovarian cyst – published in
              international journal Reprod contracept Obstet Gynecol 2017.
            </p>
            <p>
              Disengagement of the deeply engaged fetal head during caesarean
              section in advanced labour : patwardhan versus push method
              published in international journal reprod contracept obstet
              gynecol 2015.
            </p>
            <h3>MEMBERSHIP:</h3>
            <p>Karnataka Medical Council</p>
            <p>She is a life time member in Indian fertility society.</p> */}
          </div>
        </div>
      </div>
    </div>
  );
}
