export const ServicesSchema = [
  {
    id: 1,
    link: "fertility-testing-for-men",
    schema: `   "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "What does an Andrologist do?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "An Andrologist is a doctor specialized in the male evaluation and treatment of infertility. He finds the cause of infertility in the male and treats it. Varicocele, one of the common reasons attributed to infertility, he does Varicocelectomy. In males with Azoospermia, he does procedures like PESA/TESA. For further details, contact our Andrologist."
      }
    },{
      "@type": "Question",
      "name": "What is Azoospermia?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Absence of sperms in the semen is termed as azoospermia. It can be obstructive or non-obstructive. Treatment for both is available at Kangaroo Care Fertility."
      }
    },{
      "@type": "Question",
      "name": "Can smoking cause infertility in men?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Smoking can lead to DNA damage in sperm. These men with elevated sperm with DNA damage may have reduced fertility and higher miscarriage rates. Also, smoking is a risk factor for erectile dysfunction (ED), which can be a challenge."
      }
    },{
      "@type": "Question",
      "name": "What is sperm DFI?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "DFI stands for DNA Fragmentation Index. It is tested in men with infertility and repeated abortions. High DFI can be detrimental and may cause infertility. Ask your doctor as to how it can be treated."
      }
    },{
      "@type": "Question",
      "name": "What is the main cause of male infertility?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Semen disorders- abnormal sperm shape/size/number/motility or immature sperms, is the commonest cause of male infertility."
      }
    }]
   `,
  },
];

export default function ServicesSchemaContent({ link }) {
  const schemaFilter = ServicesSchema.filter((item) => {
    return item.link === link;
  });
  let articleStructuredData2;

  if (schemaFilter.length >= 1) {
    articleStructuredData2 = schemaFilter[0].schema.trim();
  }

  return (
    <>
      <script type="application/ld+json">{`{${articleStructuredData2}}`}</script>
    </>
  );
}
