import React from "react";
import imgmin from "./assets/img/home/service-images/arrow.png";
import "./Treatments.css";

import icon1 from "./assets/img/icons/treatment-page/ovulationinduction.png";
import icon2 from "./assets/img/icons/treatment-page/iui.png";
import icon3 from "./assets/img/icons/treatment-page/ivf.png";
import icon4 from "./assets/img/icons/treatment-page/icsi.png";
import icon5 from "./assets/img/icons/treatment-page/geneticcounselingandtesting.png";
import icon6 from "./assets/img/icons/treatment-page/andrology.png";
import icon7 from "./assets/img/icons/treatment-page/fertilitypreservation.png";
import icon8 from "./assets/img/icons/treatment-page/donorsurrogacy.png";
import { useLocation } from "react-router-dom";
import { NavLink as Link } from "react-router-dom";
import CustomTitle from "../components/CustomTitle";

export default function Treatments() {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const helmetDescriptions = {
    titleName: "Best Infertility Treatment in Bangalore",
    Description:
      "Experience parenthood with the best fertility treatment in Bangalore.  We have a team of experienced fertility specialists to offer you the best solutions  like ivf, iui, icsi. Book Appointment",
  };

  return (
    <div>
      <CustomTitle href="https://kangaroocarefertility.com/fertility-treatments" customValue={helmetDescriptions} />
      <h1 className="h1-top-text mt-4">Top Fertility Treatment in Bangalore</h1>
      <div className="container-fluid" id="treatment-page">
        <div className="container">
          <div className="row mt-5" id="homeHavingCardTreat">
            <div className="col-lg-3 col-md-6 mb-5 pb-5">
              <div className="card hoverMe-card-to-grow">
                <h5 className="card-title text-center mb-3">
                  Ovulation Induction
                </h5>
                <img
                  style={{ width: "80px", height: "80px" }}
                  src={icon1}
                  className="align-self-center card-img-top"
                  alt="..."
                />
                <div className="card-body mt-2">
                  <p className="card-text" style={{ textAlign: "justify" }}>
                    Medications are given to induce ovulation, followed by timed
                    intercourse or artificial insemination for better chances of
                    conception.
                  </p>
                </div>
                <div className="bottom-icon align-self-center">
                  <Link
                    className="treatments-link-all"
                    to="/treatments/ovulation-induction"
                  >
                    <img
                      style={{ width: "40px", height: "40px" }}
                      src={imgmin}
                      className="card-img-bottom"
                      alt="..."
                    />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-5 pb-5">
              <div className="card hoverMe-card-to-grow">
                <h5 className="card-title text-center mb-3">IUI</h5>
                <img
                  style={{ width: "80px", height: "80px" }}
                  src={icon2}
                  className="align-self-center card-img-top"
                  alt="..."
                />
                <div className="card-body mt-2">
                  <p className="card-text" style={{ textAlign: "justify" }}>
                    IUI facilitates fertilization as the sperm is directly
                    inserted into the uterus whereby it surpasses the hostile
                    cervical factors and its journey towards the egg is
                    shortened.
                  </p>
                </div>
                <div className="bottom-icon align-self-center">
                  <Link
                    className="treatments-link-all"
                    to="/treatments/intrauterine"
                  >
                    <img
                      style={{ width: "40px", height: "40px" }}
                      src={imgmin}
                      className="card-img-bottom"
                      alt="..."
                    />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-5 pb-5">
              <div className="card hoverMe-card-to-grow">
                <h5 className="card-title text-center mb-3">IVF</h5>
                <img
                  style={{ width: "80px", height: "80px" }}
                  src={icon3}
                  className="align-self-center card-img-top"
                  alt="..."
                />
                <div className="card-body mt-2">
                  <p className="card-text" style={{ textAlign: "justify" }}>
                    A type of assisted reproduction where the egg and the sperms
                    are allowed to fertilize naturally by placing them in a
                    petri dish in the lab.
                  </p>
                </div>

                <div className="bottom-icon align-self-center">
                  <Link
                    className="treatments-link-all"
                    to="/treatments/invitro-fertilization"
                  >
                    <img
                      style={{ width: "40px", height: "40px" }}
                      src={imgmin}
                      className="card-img-bottom"
                      alt="..."
                    />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-5 pb-5">
              <div className="card hoverMe-card-to-grow">
                <h5 className="card-title text-center mb-3">ICSI</h5>
                <img
                  style={{ width: "80px", height: "80px" }}
                  src={icon4}
                  className="align-self-center card-img-top"
                  alt="..."
                />
                <div className="card-body mt-2">
                  <p className="card-text" style={{ textAlign: "justify" }}>
                    ICSI, another type of assisted reproduction, improves the
                    chances of fertilization as the sperm is directly injected
                    into the egg.
                  </p>
                </div>

                <div className="bottom-icon align-self-center">
                  <Link
                    className="treatments-link-all"
                    to="/treatments/intracytoplasmic"
                  >
                    <img
                      style={{ width: "40px", height: "40px" }}
                      src={imgmin}
                      className="card-img-bottom"
                      alt="..."
                    />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-5 pb-5">
              <div className="card hoverMe-card-to-grow">
                <h5 className="card-title text-center mb-3">
                  Genetic counseling and testing
                </h5>
                <img
                  style={{ width: "80px", height: "80px" }}
                  src={icon5}
                  className="align-self-center card-img-top"
                  alt="..."
                />
                <div className="card-body mt-3">
                  <p className="card-text" style={{ textAlign: "justify" }}>
                    Testing for various chromosomal abnormalities helps the
                    couple to have a healthy baby.
                  </p>
                </div>
                <div className="bottom-icon align-self-center">
                  <Link
                    className="treatments-link-all"
                    to="/treatments/genetic-counselling-and-testing"
                  >
                    <img
                      style={{ width: "40px", height: "40px" }}
                      src={imgmin}
                      className="card-img-bottom"
                      alt="..."
                    />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-5 pb-5">
              <div className="card hoverMe-card-to-grow">
                <h5 className="card-title text-center mb-3">Andrology</h5>
                <img
                  style={{ width: "80px", height: "80px" }}
                  src={icon6}
                  className="align-self-center card-img-top"
                  alt="..."
                />
                <div className="card-body mt-3">
                  <p className="card-text" style={{ textAlign: "justify" }}>
                    Andrology involves treatment of various conditions, causing
                    male infertility and male sexual dysfunction.
                  </p>
                </div>
                <div className="bottom-icon align-self-center">
                  <Link
                    className="treatments-link-all"
                    to="/treatments/varicocele"
                  >
                    <img
                      style={{ width: "40px", height: "40px" }}
                      src={imgmin}
                      className="card-img-bottom"
                      alt="..."
                    />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-5 pb-5">
              <div className="card hoverMe-card-to-grow">
                <h5 className="card-title text-center mb-3">
                  Fertility Preservation
                </h5>
                <img
                  style={{ width: "80px", height: "80px" }}
                  src={icon7}
                  className="align-self-center card-img-top"
                  alt="..."
                />
                <div className="card-body mt-3">
                  <p className="card-text" style={{ textAlign: "justify" }}>
                    Fertility can be preserved by either freezing the eggs,
                    sperm or even the embryo to be used at a later date.
                  </p>
                </div>
                <div className="bottom-icon align-self-center">
                  <Link
                    className="treatments-link-all"
                    to="/treatments/fertility-preservation"
                  >
                    <img
                      style={{ width: "40px", height: "40px" }}
                      src={imgmin}
                      className="card-img-bottom"
                      alt="..."
                    />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-5 pb-5">
              <div className="card hoverMe-card-to-grow">
                <h5 className="card-title text-center mb-3">Donor</h5>
                <img
                  style={{ width: "80px", height: "80px" }}
                  src={icon8}
                  className="align-self-center card-img-top"
                  alt="..."
                />
                <div className="card-body mt-3">
                  <p className="card-text" style={{ textAlign: "justify" }}>
                    These are various ways in which an infertile couple could
                    have their own baby through methods like Ovum donation,
                    donor sperm, surrogacy, under legal terms. These methods aid
                    the couple in the treatment of infertility.
                  </p>
                </div>
                <div className="bottom-icon align-self-center">
                  <Link className="treatments-link-all" to="/treatments/donor">
                    <img
                      style={{ width: "40px", height: "40px" }}
                      src={imgmin}
                      className="card-img-bottom"
                      alt="..."
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
