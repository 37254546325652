import React from "react";
import "./FertilityWomen.css";
import CustomTitle from "../CustomTitle";
import simg from "../assets/img/fertility/female_fertility.jpg";
import { useLocation, Link } from "react-router-dom";
export default function Services() {
  const helmetDescriptions = {
    titleName:
      "Female Infertility Treatment in Bangalore | Fertility Clinic Bangalore",
    Description:
      "Get the best infertility treatments for women at Kangaroo Care Fertility Bangalore. We aim to provide best assessment for women to overcome infertility problems",
  };
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const femaleFertilitySchema = `<script type="application/ld+json">
  {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "How is woman’s fertility tested ?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "We take history, examine and do a couple of tests (discussed above) and an ultrasound. After all these, we counsel the woman regarding her chances for conception and the time it would take for her to achieve the same at our center."
      }
    },{
      "@type": "Question",
      "name": "Can infertility be cured ?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "At Kangaroo Care Fertility, we help the infertile couple to become fertile and have a baby of their own. We find the cause for infertility and treat it."
      }
    },{
      "@type": "Question",
      "name": "Success of fertility treatment ?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "The success of fertility treatment depends on the cause for infertility. We have the best success rate for all our Ovulation induction, IUI, IVF/ICSI procedures."
      }
    },{
      "@type": "Question",
      "name": "Cost of Infertility Treatment ?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Kangaroo Care Hospitals are known for their transparent, ethical and affordable prices. We offer fertility treatment at affordable prices, when compared to various other centers in and around Bangalore."
      }
    },{
      "@type": "Question",
      "name": "Can cell phones cause female infertility?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Continuous exposure to cell phone radiation is considered to be very harmful for women trying to conceive. The long- term exposure and proximity to cellular radiation leads to infertility in women as it affects the normal activity of ovaries."
      }
    }]
  }
  </script>
  `;
  return (
    <div>
      <CustomTitle
        href="https://kangaroocarefertility.com/treatments/fertility-testing-for-women"
        customValue={helmetDescriptions}
      />
      <iframe
        srcDoc={femaleFertilitySchema}
        title="Female Fertility schema"
        width="0"
        height="0"
      />
      <div className="main-content container mt-2">
        <nav aria-label="breadcrumb" className="mb-5">
          <ol className="breadcrumb">
            <li className="breadcrumb-item text-specifc-color">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item text-specifc-color">
              <Link to="/treatments/fertility-testing-for-women">
                Specialities
              </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Fertility Testing In Women
            </li>
          </ol>
        </nav>
      </div>

      {/* -------main content------------ */}

      {/* -----------------------------what is portion -------------------- */}
      <div className="container-fluid service my-5">
        <div className="row justify-content-center">
          <h1 className="heading1 text-center pb-5">
            Best Female fertility Treatment in Bangalore
          </h1>
          <div className="col-md-6 mx-2 d-none d-sm-block">
            <div className="mb-2" id="service-left-img-women">
              <img src={simg} alt="Happy woman with pregnancy test" />
            </div>
          </div>
          <div className="col-md-5 mb-2 mx-2" id="service-content-card">
            <h2 className="my-4 text-center text-blue">Female Fertility</h2>
            <hr />
            <p>
              <i className="fas fa-check-circle me-2"></i>
              <strong>
                Female fertility is a woman's ability to conceive a biological
                child.
              </strong>
            </p>
            <p>
              Infertility means not being able to get pregnant after at least
              one year of trying (or 6 months if the woman is over age 35). If a
              woman keeps having miscarriages, it is also called infertility.
              Female infertility can result from age, physical problems, hormone
              problems, and lifestyle or environmental factors.
            </p>
            <p>
              As age progresses, fertility decreases. Especially in women,
              fertility decreases at an alarming rate after 35yrs of age.
            </p>
          </div>
        </div>

        {/* -----------------------section2 content process--------------------- */}

        <div className="row justify-content-center my-5">
          <div className="col-md-8 mx-2" id="service-content2-card">
            <div className="mb-2 p-4">
              <h2 className="text-center text-blue">
                Female Fertility Testing
              </h2>
              <br />
              <p>
                <i className="fas fa-check-circle me-2"></i>{" "}
                <strong>Hormonal Blood Tests-</strong> They are done at varying
                times of the menstrual cycle. They are- Serum FSH, LH, E2, AMH,
                TSH, Prolactin.
              </p>
              <p>
                <i className="fas fa-check-circle me-2"></i>{" "}
                <strong>Ovarian Reserve Tests-</strong> Apart from AMH and FSH,
                Antral Follicular count is done on ultrasound.
              </p>
              <p>
                <i className="fas fa-check-circle me-2"></i>
                <strong>Hysterosalpingography (HSG)-</strong> A procedure done
                to know the patency of the tubes. It can be done with or without
                anaesthesia.
              </p>
              <p>
                <i className="fas fa-check-circle me-2"></i>
                <strong>Ultrasound (2D/3D)-</strong> done to evaluate the
                uterus, tubes and ovaries. Usually a transvaginal USG is done as
                it has a better resolution.
              </p>
              <p>
                <i className="fas fa-check-circle me-2"></i>
                <strong>Hysteroscopy/Laparoscopy-</strong> These are the
                minimally invasive procedures, done under anaesthesia, to
                evaluate the pelvic organs. We can obtain a live image of the
                organs and make a definite diagnosis.
              </p>
            </div>
          </div>
          <div
            className="col-md-3 mb-2 mx-2 p-4 card  d-none d-md-block"
            id="service-link-card"
          >
            <h2 className="text-blue">Important Links</h2>
            <br />
            <ul className="ms-5">
              <li>
                <Link to="/treatments/ovulation-induction">
                  <span className="hand-link">&#9758;</span> &nbsp; Ovulation
                  Induction
                </Link>
              </li>
              <li>
                <Link to="/treatments/intrauterine">
                  <span className="hand-link">&#9758;</span> &nbsp; Intrauterine
                  Insemination (IUI)
                </Link>
              </li>
              <li>
                <Link to="/treatments/invitro-fertilization">
                  <span className="hand-link">&#9758;</span> &nbsp; In Vitro
                  Fertilization
                </Link>
              </li>
              <li>
                <Link to="/treatments/intracytoplasmic">
                  <span className="hand-link">&#9758;</span> &nbsp;
                  Intracytoplasmic Sperm Injection{" "}
                </Link>
              </li>
              <li>
                <Link to="/treatments/genetic-counselling-and-testing">
                  <span className="hand-link">&#9758;</span> &nbsp; Genetic
                  Counselling and Testing
                </Link>
              </li>
              <li>
                <Link to="/treatments/varicocele">
                  <span className="hand-link">&#9758;</span> &nbsp; Varicocele
                </Link>
              </li>
              <li>
                <Link to="/treatments/tesa-pesa">
                  <span className="hand-link">&#9758;</span> &nbsp; TESA/ PESA
                </Link>
              </li>
              <li>
                <Link to="/treatments/fertility-preservation">
                  <span className="hand-link">&#9758;</span> &nbsp; Fertility
                  Preservation
                </Link>
              </li>
              <li>
                <Link to="/treatments/Donor">
                  <span className="hand-link">&#9758;</span> &nbsp; Donor
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="container" id="women">
        <div className="row d-flex justify-content-center">
          <div className="col-md-10 p-3 my-3 the-3-service-card">
            <h2 className="text-center mt-3 text-blue">
              Female Fertility Treatment
            </h2>
            <br />
            <p>
              <i className="fas fa-check-circle me-2"></i>
              Fertility treatment aims to help the women to bear child naturally
              or through assisted reproduction. The various options available
              for the woman at Kangaroo Care Fertility are-
            </p>
            <p>
              <ul>
                <li>● Ovulation Induction</li>
                <li>● Intrauterine Insemination (IUI)</li>
                <li>● In-vitro Fertilization (IVF)</li>
                <li>● Intracytoplasmic Sperm Injection (ICSI)</li>
                <li>● Laparoscopy & Hysteroscopy</li>
              </ul>
            </p>
          </div>
        </div>
      </div>

      {/* -------------------------advantage cards---------------- */}

      {/* <div className="container-fluid my-4">
        <div className="row justify-content-center" id="scards">
          <h2 className="text-center">Advantages of Ovulation Induction</h2>
          <br />
          <br />
          <div className="col-md-3 m-2 p-3 the-3-service-card">
            <div className="text-center">
              <img src={logo} alt="" />
              <p>
                <strong>Affordable</strong>
              </p>
            </div>
            <p>
              Ovulation Induction involves oral medications and sometimes
              injections and thus is not expensive.
            </p>
          </div>
          <div className="col-md-3 m-2 p-3 the-3-service-card">
            <div className="text-center">
              <img src={logo} alt="" />
              <p>
                <strong>Non-invasive</strong>
              </p>
            </div>
            <p>It does not involve any invasive procedures.</p>
          </div>
          <div className="col-md-3 m-2 p-3 the-3-service-card">
            <div className="text-center">
              <img src={logo} alt="" />
              <p>
                <strong>Suitability</strong>
              </p>
            </div>
            <p>
              It is most suitable in women with PCOS, anovulation, mild-moderate
              endometriosis, unexplained infertility.
            </p>
          </div>
        </div>
      </div> */}

      {/* ---------------------------faq------------------------ */}

      <div className="accordion container-fluid my-5 " id="accordionExample">
        <h2 className="text-center text-blue">FAQs</h2>
        <br />
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              ● How is woman’s fertility tested ?
            </button>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              We take history, examine and do a couple of tests (discussed
              above) and an ultrasound. After all these, we counsel the woman
              regarding her chances for conception and the time it would take
              for her to achieve the same at our center.
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingTwo">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              ● Can infertility be cured ?
            </button>
          </h2>
          <div
            id="collapseTwo"
            className="accordion-collapse collapse"
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              At Kangaroo Care Fertility, we help the infertile couple to become
              fertile and have a baby of their own. We find the cause for
              infertility and treat it.
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingThree">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              ● Success of fertility treatment ?
            </button>
          </h2>
          <div
            id="collapseThree"
            className="accordion-collapse collapse"
            aria-labelledby="headingThree"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              The success of fertility treatment depends on the cause for
              infertility. We have the best success rate for all our Ovulation
              induction, IUI, IVF/ICSI procedures.
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingFour">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
              ● Cost of Infertility Treatment ?
            </button>
          </h2>
          <div
            id="collapseFour"
            className="accordion-collapse collapse"
            aria-labelledby="headingFour"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              Kangaroo Care Hospitals are known for their transparent, ethical
              and affordable prices. We offer fertility treatment at affordable
              prices, when compared to various other centers in and around
              Bangalore.
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingFive">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFive"
              aria-expanded="false"
              aria-controls="collapseFive"
            >
              ● Can cell phones cause female infertility?
            </button>
          </h2>
          <div
            id="collapseFive"
            className="accordion-collapse collapse"
            aria-labelledby="headingFive"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              Continuous exposure to cell phone radiation is considered to be
              very harmful for women trying to conceive. The long- term exposure
              and proximity to cellular radiation leads to infertility in women
              as it affects the normal activity of ovaries.
            </div>
          </div>
        </div>
      </div>
      {/* ----------------some heading and para----------- */}
      <div className="container-fluid bg-light ">
        <div className="container">
          <div className="row">
            <div className="col text-center my-sm-5 my-3">
              <h2 className="heading2">
                Female Infertility Treatment Hospital in Bangalore
              </h2>
              <p>
                At Kangaroo Care Fertility, we have the best female fertility
                specialists to carry out female fertility testings such as
                Hormonal Blood tests, HSG, Ultrasound and so on. We aim to
                provide the best female infertility treatments like Ovulation
                Induction, &nbsp;
                <a href="https://kangaroocarefertility.com/treatments/invitro-fertilization">
                  IVF treatment,
                </a>
                <a href="https://kangaroocarefertility.com/treatments/intrauterine">
                  IUI,
                </a>
                <a href="https://kangaroocarefertility.com/treatments/intracytoplasmic">
                  ICSI &nbsp;
                </a>
                & Hysteroscopy to help women conceive
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
