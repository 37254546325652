import React from "react";
import "./FertilityTestingForMen.css";
import simg from "../assets/img/fertility/hb_male_fertility.jpg";
import CustomTitle from "../CustomTitle";
import { useLocation, Link } from "react-router-dom";
import ServicesSchemaContent, { ServicesSchema } from "./ServicesSchema";
export default function Services() {
  const helmetDescriptions = {
    titleName:
      "Male Infertility Treatment Hospital Bangalore | Male Fertility Testing",
    Description:
      "One of the best clinic for male infertility treatment in Bangalore. Get personalized assessment from experienced doctors at Kangaroo Care Fertility Bangalore. ",
  };
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  // const schema = ServicesSchemaContent("fertility-testing-for-men");
  // console.log(schema);
  return (
    <div>
      <CustomTitle
        href="https://kangaroocarefertility.com/treatments/fertility-testing-for-men"
        customValue={helmetDescriptions}
      />
      <div className="main-content container mt-2">
        <nav aria-label="breadcrumb" className="mb-5">
          <ol className="breadcrumb">
            <li className="breadcrumb-item text-specifc-color">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item text-specifc-color">
              <Link to="/treatments/fertility-testing-for-men">
                Specialities
              </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Fertility Testing In Men
            </li>
          </ol>
        </nav>
      </div>

      {/* -------main content------------ */}

      {/* -----------------------------what is portion -------------------- */}
      <div className="container-fluid service my-5">
        <div className="row justify-content-center">
          <h1 className="heading1 text-center pb-5">
            Best Male Fertility Treatment in Bangalore
          </h1>
          <div className="col-md-6 mx-2 d-none d-sm-block">
            <div className="mb-2" id="service-left-img-man">
              <img src={simg} alt="Father hugs baby" />
            </div>
          </div>
          <div className="col-md-5 mb-2 mx-2" id="service-content-card">
            <h2 className="my-4 text-center text-blue">Fertility In Men</h2>
            <hr />
            <p>
              <i className="fas fa-check-circle me-2"></i>
              <strong></strong>
              Fertility is defined as the natural capacity to conceive a child.
              It decreases after the age of 40 in men.
            </p>
            <p>
              Male infertility means a man is not able to start a pregnancy with
              his female partner. Nearly 1 in 7 couples is infertile, which
              means they haven't been able to conceive a child even though
              they've had frequent, unprotected sexual intercourse for a year or
              longer. In up to half of these couples, male infertility plays at
              least a partial role.
            </p>
          </div>
        </div>

        {/* -----------------------section2 content process--------------------- */}

        <div className="row justify-content-center my-5">
          <div className="col-md-8 mx-2" id="service-content2-card">
            <div className="mb-2 p-4">
              <h2 className="text-center text-blue">
                {" "}
                Causes of Male Infertility
              </h2>
              <br />
              <p>
                <i className="fas fa-check-circle me-2"></i>
                Conceiving a healthy baby depends on a number of factors,
                including healthy sperm. In fact, male infertility is the second
                biggest issue after a woman’s age so it’s important to
                understand how the male reproductive system works. The most
                common causes of male infertility are called:
              </p>
              <p>
                <ul>
                  <li>● Azoospermia, no sperm cells are produced.</li>
                  <li>● Oligospermia, where few sperm cells are produced.</li>
                  <li>
                    ● Teratospermia, where a high proportion of sperm is
                    abnormally shaped.
                  </li>
                  <li>● Blocked or absent vas deferens:</li>

                  <li>
                    ● Vas deferens is the tube that transports the sperm from
                    the testes
                  </li>

                  <li>
                    ● Genetic condition such as cystic fibrosis or chromosomal
                    abnormality.
                  </li>
                  <li>
                    ● High sperm DNA fragmentation that can affect a sperm's
                    ability to fertilise an egg.
                  </li>
                  <li>
                    ● Sperm antibodies that can interfere with sperm motility
                    and fertilisation.
                  </li>
                </ul>
              </p>
            </div>
          </div>
          <div
            className="col-md-3 mb-2 mx-2 p-4 card  d-none d-md-block"
            id="service-link-card"
          >
            <h2 className="text-blue">Important Links</h2>
            <br />
            <ul className="ms-5">
              <li>
                <Link to="/treatments/ovulation-induction">
                  <span className="hand-link">&#9758;</span> &nbsp; Ovulation
                  Induction
                </Link>
              </li>
              <li>
                <Link to="/treatments/intrauterine">
                  <span className="hand-link">&#9758;</span> &nbsp; Intrauterine
                  Insemination (IUI)
                </Link>
              </li>
              <li>
                <Link to="/treatments/invitro-fertilization">
                  <span className="hand-link">&#9758;</span> &nbsp; In Vitro
                  Fertilization
                </Link>
              </li>
              <li>
                <Link to="/treatments/intracytoplasmic">
                  <span className="hand-link">&#9758;</span> &nbsp;
                  Intracytoplasmic Sperm Injection{" "}
                </Link>
              </li>
              <li>
                <Link to="/treatments/genetic-counselling-and-testing">
                  <span className="hand-link">&#9758;</span> &nbsp; Genetic
                  Counselling and Testing
                </Link>
              </li>
              <li>
                <Link to="/treatments/varicocele">
                  <span className="hand-link">&#9758;</span> &nbsp; Varicocele
                </Link>
              </li>
              <li>
                <Link to="/treatments/tesa-pesa">
                  <span className="hand-link">&#9758;</span> &nbsp; TESA/ PESA
                </Link>
              </li>
              <li>
                <Link to="/treatments/fertility-preservation">
                  <span className="hand-link">&#9758;</span> &nbsp; Fertility
                  Preservation
                </Link>
              </li>
              <li>
                <Link to="/treatments/Donor">
                  <span className="hand-link">&#9758;</span> &nbsp; Donor
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="container" id="men">
        <div className="row d-flex justify-content-center">
          <div className="col-md-10 p-3 my-3 the-3-service-card">
            <h2 className="text-center mt-3 text-blue">
              Male Fertility Testing
            </h2>
            <br />
            <p>
              <i className="fas fa-check-circle me-2"></i>
              Fertility testing in men is equally important. Infertility can be
              attributed to males in 30 - 40% of the cases.
            </p>
            <p>
              <i className="fas fa-check-circle me-2"></i>
              <strong>Timed intercourse or artificial insemination </strong>
              Around the time of ovulation we shall advise the most appropriate
              day to have sexual intercourse to maximise the chances of
              pregnancy or to schedule an intrauterine insemination.
              <ul>
                <li>
                  ● Semen Analysis- One of the most common test done. Reported
                  by our embryologist. The sample should be given after sexual
                  abstinence of 2-5 days.
                </li>
                <li>
                  ● Hormonal Blood Test- Serum FSH, LH, E2, TSH, done under
                  special circumstances.{" "}
                </li>
                <li>
                  ● Scrotal ultrasound- Our Andrologist does it to confirm the
                  diagnoses of Varicocele and to rule out other testicular
                  pathologies.
                </li>
                <li>
                  ● Post ejaculatory urine analysis- It is done when the male
                  has retrograde ejaculation.
                </li>
                <li>
                  ● Genetic testing- When the male has Azoospermia or a severe
                  oligospermia, this test is done.
                </li>
              </ul>
            </p>
            <h2 className="text-center text-blue">
              Male Fertility Treatment Options
            </h2>
            <p>
              <i className="fas fa-check-circle me-2"></i>
              The inability to conceive a child can be stressful and
              frustrating, but a number of treatments are available for male
              infertility.
              <ul>
                <li>● Intrauterine Insemination (IUI)</li>
                <li>● IVF</li>
                <li>● ICSI</li>
                <li>● Varicocelectomy</li>
                <li>● TESA/PESA/TESE</li>
                <li>● Pre-Implantation Genetic Testing (PGT)</li>
              </ul>
            </p>
          </div>
        </div>
      </div>
      <ServicesSchemaContent link="fertility-testing-for-men" />
      {/* -------------------------advantage cards---------------- */}

      {/* <div className="container-fluid my-4">
        <div className="row justify-content-center" id="scards">
          <h2 className="text-center">Advantages of Ovulation Induction</h2>
          <br />
          <br />
          <div className="col-md-3 m-2 p-3 the-3-service-card">
            <div className="text-center">
              <img src={logo} alt="" />
              <p>
                <strong>Affordable</strong>
              </p>
            </div>
            <p>
              Ovulation Induction involves oral medications and sometimes
              injections and thus is not expensive.
            </p>
          </div>
          <div className="col-md-3 m-2 p-3 the-3-service-card">
            <div className="text-center">
              <img src={logo} alt="" />
              <p>
                <strong>Non-invasive</strong>
              </p>
            </div>
            <p>It does not involve any invasive procedures.</p>
          </div>
          <div className="col-md-3 m-2 p-3 the-3-service-card">
            <div className="text-center">
              <img src={logo} alt="" />
              <p>
                <strong>Suitability</strong>
              </p>
            </div>
            <p>
              It is most suitable in women with PCOS, anovulation, mild-moderate
              endometriosis, unexplained infertility.
            </p>
          </div>
        </div>
      </div> */}

      {/* ---------------------------faq------------------------ */}

      <div className="accordion container-fluid my-5 " id="accordionExample">
        <h2 className="text-center text-blue">FAQs</h2>
        <br />
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              ● What does an Andrologist do?
            </button>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              An Andrologist is a doctor specialized in the male evaluation and
              treatment of infertility. He finds the cause if infertility in the
              male and treats it. Varicocele, one of the common reasons
              attributed to infertility, he does Varicocelectomy. In males with
              Azoospermia, he does procedures like PESA/TESA. For further
              details, contact our Andrologist.
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingTwo">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              ● What is Azoospermia?
            </button>
          </h2>
          <div
            id="collapseTwo"
            className="accordion-collapse collapse"
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              Absence of sperms in the semen is termed as azoospermia. It can be
              obstructive or non-obstructive. Treatment for both is available at
              Kangaroo Care Fertility.
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingThree">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              ● Can smoking cause infertility in men?
            </button>
          </h2>
          <div
            id="collapseThree"
            className="accordion-collapse collapse"
            aria-labelledby="headingThree"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              Smoking can lead to DNA damage in sperm. These men with elevated
              sperm with DNA damage may have reduced fertility and higher
              miscarriage rates. Also, smoking is a risk factor for erectile
              dysfunction (ED), which can be a challenge.
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingFour">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
              ● What is sperm DFI?
            </button>
          </h2>
          <div
            id="collapseFour"
            className="accordion-collapse collapse"
            aria-labelledby="headingFour"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              DFI stands for DNA Fragmentation Index. It is tested in men with
              infertility and repeated abortions. High DFI can be detrimental
              and may cause infertility. Ask your doctor as to how it can be
              treated.
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingFive">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFive"
              aria-expanded="false"
              aria-controls="collapseFive"
            >
              ● What is the main cause of male infertility?
            </button>
          </h2>
          <div
            id="collapseFive"
            className="accordion-collapse collapse"
            aria-labelledby="headingFive"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              Semen disorders- abnormal sperm shape/size/number/motility or
              immature sperms, is the commonest cause of male infertility.
            </div>
          </div>
        </div>
      </div>
      {/* ----------------some heading and para----------- */}
      <div className="container-fluid bg-light ">
        <div className="container">
          <div className="row">
            <div className="col text-center my-sm-5 my-3">
              <h2 className="heading2">
                Male Infertility Treatment Hospital in Bangalore
              </h2>
              <p>
                Male infertility in most of the cases occurs if there are
                problems with sperm production or with sperm delivery. At
                Kangaroo Care Fertility, we have the best male infertility
                doctors who carry out male infertility testing to diagnose the
                problem and provide suitable infertility treatments like{" "}
                <a href="https://kangaroocarefertility.com/treatments/genetic-counselling-and-testing">
                  genetic counseling & testing,
                </a>
                <a href="https://kangaroocarefertility.com/treatments/tesa-pesa">
                  TESA/PESA &nbsp;
                </a>
                to overcome the fertility related problems.{" "}
              </p>

              <p>
                We also specialize in treating female infertility problems with
                the best suitable treatments like{" "}
                <a href="https://kangaroocarefertility.com/treatments/ovulation-induction">
                  Ovulation Induction,
                </a>
                <a href="https://kangaroocarefertility.com/treatments/invitro-fertilization">
                  IVF treatment,
                </a>
                <a href="https://kangaroocarefertility.com/treatments/intrauterine">
                  IUI,
                </a>
                <a href="https://kangaroocarefertility.com/treatments/intracytoplasmic">
                  ICSI.
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
