import React from "react";
import CustomTitle from "../CustomTitle";
import "./varicocele.css";
import simg from "../assets/img/fertility/Varicocele.jpg";
import { useLocation, Link } from "react-router-dom";
export default function Services() {
  const helmetDescriptions = {
    titleName:
      "Leading Bangalore Varicocele Treatment Center: Proven Solutions",
    Description:
      "Transform your life with the finest varicocele treatment in Bangalore. Optimal care, exceptional results – your journey to wellness starts here!",
  };
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const varicoleSchema = `<script type="application/ld+json">
  {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "How is Varicocele diagnosed?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Varicocele is usually diagnosed clinically. Ultrasound of the scrotum is done to detect subclinical Varicocele and to rule out other abnormalities in the testes."
      }
    },{
      "@type": "Question",
      "name": "How do men with varicocele present?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Many a times varicocele may be asymptomatic, or they can have pain, swelling and male infertility."
      }
    },{
      "@type": "Question",
      "name": "Does Varicocele cause infertility?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Varicocele has been found in upto 40 % of infertile men. After varicocelectomy lot of infertile men have conceived. So yes varicocele can cause infertility in men."
      }
    },{
      "@type": "Question",
      "name": "How soon can I join work after varicocelectomy?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "After about 2-3 weeks, one can resume work after varicocelectomy."
      }
    },{
      "@type": "Question",
      "name": "What is the main cause of Varicocele?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "Varicoceles are believed to be caused by defective valves in the veins within the scrotum, just above the testicles. Normally, these valves regulate the flow of blood to and from the testicles. When normal flow doesn't occur, the blood backs up, causing the veins to dilate."
      }
    }]
  }
  </script>


  `;
  return (
    <>
      <CustomTitle
        href="https://kangaroocarefertility.com/treatments/varicocele"
        customValue={helmetDescriptions}
      />
      <iframe srcDoc={varicoleSchema} title="schema" width="0" height="0" />
      <div className="main-content container mt-2">
        <nav aria-label="breadcrumb" className="mb-5">
          <ol className="breadcrumb">
            <li className="breadcrumb-item text-specifc-color">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Varicocele
            </li>
          </ol>
        </nav>
      </div>

      {/* -------main content------------ */}

      {/* -----------------------------what is portion -------------------- */}
      <div className="container-fluid service my-5">
        <div className="row justify-content-center">
          <h1 className="heading1 text-center pb-5">
            Varicocele Male Infertility Treatment
          </h1>
          <div className="col-md-6 mx-2 d-none d-sm-block">
            <div className="mb-2" id="service-left-img-vericocele">
              <img src={simg} alt="Doctor briefing the report to a patient" />
            </div>
          </div>
          <div className="col-md-5 mb-2 mx-2" id="service-content-card">
            <h2 className="my-4 text-center text-blue">Varicocele</h2>
            <hr />
            <p>
              <i className="fas fa-check-circle me-2"></i>
              <strong>What is Varicocele?</strong>
            </p>
            <p>
              Varicocele is a common condition in men. It is seen in about 15%
              of men in the general population and almost upto 40% in men with
              infertility. Though not all men with varicocele are infertile,
              many infertile men with varicocele become fertile after
              varicocelectomy.
            </p>
          </div>
        </div>

        {/* -----------------------section2 content process--------------------- */}

        <div className="row justify-content-center my-5">
          <div className="col-md-8 mx-2" id="service-content2-card">
            <div className="mb-2 p-4">
              <h2 className="text-center text-blue">
                {" "}
                How to diagnose Varicocele ?
              </h2>
              <br />
              <p>
                <i className="fas fa-check-circle me-2"></i>
                Varicocele is usually asymptomatic. It may present as a dull
                aching pain, swelling in the scrotum. It is known to be
                associated with male infertility. We diagnose based on clinical
                presentation and Doppler scan of the scrotum.
              </p>
              {/* <p>
                <i className="fas fa-check-circle me-2"></i>
                <strong>Stimulation </strong>Ovaries are stimulated with
                carefully selected medication/s to promote the growth of
                follicles containing eggs.
              </p> */}
              <h2 className="text-center text-blue">
                {" "}
                How is Varicocelectomy done ?
              </h2>
              <br />
              <p>
                <i className="fas fa-check-circle me-2"></i>
                We evaluate the male. If a diagnosis of Varicocele is made,
                after explaining the pros, cons and the need of varicocelectomy
                are explained in detail. Varicocelectomy surgery is done in our
                specialized OT with the high-tech equipment. Varicocele repair
                can improve sperm production. Post operative period is made
                comfortable with utmost care with a short hospital stay.
              </p>
            </div>
          </div>
          <div
            className="col-md-3 mb-2 mx-2 p-4 card  d-none d-md-block"
            id="service-link-card"
          >
            <h2 className="text-blue">Important Links</h2>
            <br />
            <ul className="ms-5">
              <li>
                <Link to="/treatments/ovulation-induction">
                  <span className="hand-link">&#9758;</span> &nbsp; Ovulation
                  Induction
                </Link>
              </li>
              <li>
                <Link to="/treatments/intrauterine">
                  <span className="hand-link">&#9758;</span> &nbsp; Intrauterine
                  Insemination (IUI)
                </Link>
              </li>
              <li>
                <Link to="/treatments/invitro-fertilization">
                  <span className="hand-link">&#9758;</span> &nbsp; In Vitro
                  Fertilization
                </Link>
              </li>
              <li>
                <Link to="/treatments/intracytoplasmic">
                  <span className="hand-link">&#9758;</span> &nbsp;
                  Intracytoplasmic Sperm Injection{" "}
                </Link>
              </li>
              <li>
                <Link to="/treatments/genetic-counselling-and-testing">
                  <span className="hand-link">&#9758;</span> &nbsp; Genetic
                  Counselling and Testing
                </Link>
              </li>
              <li>
                <Link to="/treatments/varicocele">
                  <span className="hand-link">&#9758;</span> &nbsp; Varicocele
                </Link>
              </li>
              <li>
                <Link to="/treatments/tesa-pesa">
                  <span className="hand-link">&#9758;</span> &nbsp; TESA/ PESA
                </Link>
              </li>
              <li>
                <Link to="/treatments/fertility-preservation">
                  <span className="hand-link">&#9758;</span> &nbsp; Fertility
                  Preservation
                </Link>
              </li>
              <li>
                <Link to="/treatments/Donor">
                  <span className="hand-link">&#9758;</span> &nbsp; Donor
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* -------------------------advantage cards---------------- */}

      <div className="container-fluid my-4">
        <div className="row justify-content-center" id="scards">
          <h2 className="text-center text-blue">
            Advantages of Varicocelectomy
          </h2>
          <br />
          <br />
          <div className="col-sm-3 m-2 p-3 the-3-service-card">
            <div className="text-center">
              {/* <img src={logo} alt="" /> */}
              <p>
                <strong className="text-blue">Safe</strong>
              </p>
            </div>
            <p>
              Varicocelectomy prevents testicular atrophy in the long run, and
              thus maintains the fertility in men.
            </p>
          </div>
          <div className="col-sm-3 m-2 p-3 the-3-service-card">
            <div className="text-center">
              {/* <img src={logo} alt="" /> */}
              <p>
                <strong className="text-blue">Good Outcome</strong>
              </p>
            </div>
            <p>
              Post varicocelectomy, the pregnancy results are better. Couple can
              even try for natural pregnancy after varicocelectomy.
            </p>
          </div>
          <div className="col-sm-3 m-2 p-3 the-3-service-card">
            <div className="text-center">
              {/* <img src={logo} alt="" /> */}
              <p>
                <strong className="text-blue">Cost effective</strong>
              </p>
            </div>
            <p>
              IVF without varicocelectomy may lead to failure or abortion in the
              couple. Post varicocelectomy, it gives good pregnancy results and
              thus cost effective.
            </p>
          </div>
        </div>
      </div>

      {/* ---------------------------faq------------------------ */}

      <div className="accordion container-fluid my-5 " id="accordionExample">
        <h2 className="text-center text-blue">FAQs</h2>
        <br />
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              • How is Varicocele diagnosed?
            </button>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              Varicocele is usually diagnosed clinically. Ultrasound of the
              scrotum is done to detect subclinical Varicocele and to rule out
              other abnormalities in the testes.
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingTwo">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              • How do men with varicocele present?
            </button>
          </h2>
          <div
            id="collapseTwo"
            className="accordion-collapse collapse"
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              Many a times varicocele may be asymptomatic, or they can have
              pain, swelling and male infertility.
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingThree">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              • Does Varicocele cause infertility?
            </button>
          </h2>
          <div
            id="collapseThree"
            className="accordion-collapse collapse"
            aria-labelledby="headingThree"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              Varicocele has been found in upto 40 % of infertile men. After
              varicocelectomy lot of infertile men have conceived. So yes
              varicocele can cause infertility in men.
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingFour">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
              • How soon can I join work after varicocelectomy?
            </button>
          </h2>
          <div
            id="collapseFour"
            className="accordion-collapse collapse"
            aria-labelledby="headingFour"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              After about 2-3 weeks, one can resume work after varicocelectomy.
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingFive">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFive"
              aria-expanded="false"
              aria-controls="collapseFive"
            >
              • What is the main cause of Varicocele?
            </button>
          </h2>
          <div
            id="collapseFive"
            className="accordion-collapse collapse"
            aria-labelledby="headingFive"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              Varicoceles are believed to be caused by defective valves in the
              veins within the scrotum, just above the testicles. Normally,
              these valves regulate the flow of blood to and from the testicles.
              When normal flow doesn't occur, the blood backs up, causing the
              veins to dilate.
            </div>
          </div>
        </div>
      </div>
      {/* ----------------some heading and para----------- */}
      <div className="container-fluid bg-light ">
        <div className="container">
          <div className="row">
            <div className="col text-center my-sm-5 my-3">
              <h2 className="heading2">
                Why choose Kangaroo Care Fertility for Varicocele treatment in
                Bangalore?
              </h2>
              <p>
                Varicocele is one of the common causes of male infertility. We
                are one of the best varicocele treatment centres in Bangalore
                providing comprehensive care and quality services. We have the
                best varicocele surgeons in Bangalore.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid bg-light ">
        <div className="container">
          <div className="row">
            <div className="col text-center my-sm-5 my-3">
              <h2 className="heading2">
                Best Hospital for Varicocele Treatment in Bangalore
              </h2>
              <p>
                Varicoceles is one of the factors causing infertility in men. At
                Kangaroo Care Fertility, we have high-tech equipment providing
                the varicocele fertility treatment. Our fertility specialists
                first diagnose and make sure to deliver the process of advanced
                varicocele treatment. which makes us stand out as the Best
                hospital for Varicocele fertility Treatment in Bangalore. &nbsp;
              </p>
              <p>
                We also provide treatments like &nbsp;
                <a href="https://kangaroocarefertility.com/treatments/ovulation-induction">
                  Ovulation Induction,
                </a>
                <a href="https://kangaroocarefertility.com/treatments/invitro-fertilization">
                  IVF treatment,
                </a>
                <a href="https://kangaroocarefertility.com/treatments/intrauterine">
                  IUI,
                </a>
                <a href="https://kangaroocarefertility.com/treatments/intracytoplasmic">
                  ICSI,
                </a>
                <a href="https://kangaroocarefertility.com/treatments/genetic-counselling-and-testing">
                  genetic counseling & testing,
                </a>
                <a href="https://kangaroocarefertility.com/treatments/tesa-pesa">
                  TESA/PESA &nbsp;
                </a>
                for female infertility issues.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
