import React from "react";
import doc3 from "../../assets/img/doctor/dr-kusuma.jpg";
import { useLocation, Link } from "react-router-dom";
import CustomTitle from "../../CustomTitle";

export default function Kusuma() {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const helmetDescriptions = {
    titleName: " Dr. Kusuma | Fertility Specialist | Kangaroo Care Fertility ",
    Description:
      "Dr. Kusuma: Pioneering DIABETOLOGIST Transforming Reproductive Medicine. Expertise in Assisted Reproductive Technologies | kangaroocare Fertility",
  };

  return (
    <div id="each-doctor">
      <CustomTitle customValue={helmetDescriptions} />
      {/* <div className="py-md-5" style={{ backgroundImage: "url(" + blogHeader + ")", backgroundPosition: 'center', backgroundSize: 'cover', height: '400px' }}>
        <div className="banner-header text-center py-5">
            <h2 style={{ color: '#fff', position: "relative", top: "100px" }}>Dr. DIVYASREE D</h2>
        </div>
    </div> */}
      <br />
      {/* <h6 className="offset-1 my-4 "  style={{ textAlign: "left", color: "black" }}>HOME &gt; MEDIA &gt; Blog</h6> */}
      <nav aria-label="breadcrumb" id="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>

          <li className="breadcrumb-item active" aria-current="page">
            Doctor
          </li>
        </ol>
      </nav>
      {/* -------------------------doctor detail--------------- */}

      <div className="container mt-4">
        <div className="row">
          <div className="col-md-5">
            <div className="card" style={{ maxWidth: "30rem" }}>
              <img src={doc3} className="card-img-top" alt="Dr. Divyashree D" />
            </div>
            <br />
            <br />
            <br />
            <h3>More Info</h3>
            <br />
            <p>MBBS & FELLOWSHIP IN DIABETOLOGY (FID)</p>
            <br />
            <p>
              Department : <span>Diabetology </span>
            </p>
            <br />
            {/* <p>Consultations : 15 Minutes per Consultation</p>
            <br /> */}
            <p>Location : Ramanagara</p>
            <br />

            <span className="badge bg-primary p-2">
              Available on Prior Appointment
            </span>
            <br />
            <p> - Toll-free number @ 1800-425-4500</p>
            <br />
          </div>

          <div className="col-md-7">
            <h1 className="text-blue">Dr. Kusuma</h1>
            <p>
              <strong>DIABETOLOGIST </strong>
            </p>

            <p>
              Dr. Kusuma V, a proficient medical professional, brings a wealth
              of experience and academic excellence to healthcare. She aspires
              to contribute her knowledge and skills to a progressive
              organization for mutual growth and advancement.
            </p>
            <h3>Academic Achievements</h3>
            <ul>
              <li>MBBS from M S Ramaiah Medical College</li>
              <li>P.U.C. from V.V.S. Women's College</li>
              <li>S.S.C. from St. Michael English School</li>
            </ul>
            <h3>Professional Courses</h3>
            <ul>
              <li>Fellowship in Diabetology (FID)</li>
              <li>Certificate Course in Pediatric Diabetes</li>
            </ul>
            <h3>Experience</h3>
            <ul>
              <li>
                Resident doctor in the Department of Cardiology at B.G.S.
                Gleneagles Hospital
              </li>
              <li>Visiting Doctor at Namdhari Seeds Private Limited</li>
              <li>Medical officer at Jala Mangala Primary Health Centre</li>
              <li>
                Chief Medical Officer at B.G.S. Global Hospital, Ramanagara,
              </li>
            </ul>
            <h3>Conference/Seminars/Health Camps</h3>
            <p>
              Active participation in various conferences, seminars, and health
              camps, contributing to professional development and community
              health.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
