import React from "react";
import "./press.css";
// import img12 from "google-01.png";
import { useLocation, Link } from "react-router-dom";
import CustomTitle from "../CustomTitle";
import ImageShow from "../../UI/ImageShow/ImageShow";

export default function Image() {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const helmetDescriptions = {
    titleName: "Kangaroo Care Fertility: Latest Press Releases and Features",
    Description:
      "Get the updates of latest press releases by Kangaroo Care Fertility Hospital. Details about achievements, workshops and much more. Read Now!!",
  };

  const [show, setShow] = React.useState(null);

  function hanldleImgClick(img) {
    // let imgPath = `${path}${img}`;
    setShow(img);
  }

  return (
    <>
      <CustomTitle
        href="https://kangaroocarefertility.com/media/press"
        customValue={helmetDescriptions}
      />
      {/* <div id="banner-logo">
        <p className="text-center" id="banner-logo-text">
          | PRESS |
        </p>
      </div>

      <h6
        className="offset-md-2 my-4"
        style={{ textAlign: "left", color: "black" }}
      >
        HOME &gt; MEDIA &gt; NEWS{" "}
      </h6>
      <br /> */}
      <br />
      <nav aria-label="breadcrumb" id="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Media
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Press
          </li>
        </ol>
      </nav>
      <h1 className="h1-top-text">Press Release</h1>
      <div className="container" id="press-release">
        <div className="row justify-content-around">
          <div className="col-md-5">
            <div className="card mb-4" id="news-card-kangaroo">
              <div className="row g-0">
                <div className="col-md-4" id="news-card-kangaroo-img">
                  <img
                    src="/media/press/press1.jpeg"
                    alt="..."
                    onClick={() => hanldleImgClick("press1.jpeg")}
                  />
                </div>
                <div className="col-md-8">
                  <div className="card-body">
                    <p className="card-text">
                      Best Fertility Hospital in Mysore
                    </p>
                    <p className="card-text">
                      <small className="text-muted">09 Oct 2021</small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <div className="card mb-4" id="news-card-kangaroo">
              <div className="row g-0">
                <div className="col-md-4" id="news-card-kangaroo-img">
                  <img
                    src="/media/press/press_1.jpg"
                    alt="..."
                    onClick={() => hanldleImgClick("press_1.jpg")}
                  />
                </div>
                <div className="col-md-8">
                  <div className="card-body">
                    <p className="card-text">
                      Kangaroo Care Fertility Mysore - Inauguration
                    </p>
                    <p className="card-text">
                      <small className="text-muted"></small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-5">
            <div className="card mb-4" id="news-card-kangaroo">
              <div className="row g-0">
                <div className="col-md-4" id="news-card-kangaroo-img">
                  <img
                    src="/media/press/press_2.jpg"
                    alt="..."
                    onClick={() => hanldleImgClick("press_2.jpg")}
                  />
                </div>
                <div className="col-md-8">
                  <div className="card-body">
                    <p className="card-text">
                      Kangaroo Care Fertility Mysore - Inauguration
                    </p>
                    <p className="card-text">
                      <small className="text-muted"></small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-5">
            <div className="card mb-4" id="news-card-kangaroo">
              <div className="row g-0">
                <div className="col-md-4" id="news-card-kangaroo-img">
                  <img
                    src="/media/press/press_3.jpg"
                    alt="..."
                    onClick={() => hanldleImgClick("press_3.jpg")}
                  />
                </div>
                <div className="col-md-8">
                  <div className="card-body">
                    <p className="card-text">
                      Kangaroo Care Fertility Mysore - Inauguration
                    </p>
                    <p className="card-text">
                      <small className="text-muted"></small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-5">
            <div className="card mb-4" id="news-card-kangaroo">
              <div className="row g-0">
                <div className="col-md-4" id="news-card-kangaroo-img">
                  <img
                    src="/media/press/press_4.jpg"
                    alt="..."
                    onClick={() => hanldleImgClick("press_4.jpg")}
                  />
                </div>
                <div className="col-md-8">
                  <div className="card-body">
                    <p className="card-text">
                      Kangaroo Care Fertility Mysore - Inauguration
                    </p>
                    <p className="card-text">
                      <small className="text-muted"></small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-md-5">
            <div className="card mb-4" id="news-card-kangaroo">
              <div className="row g-0">
                <div className="col-md-4" id="news-card-kangaroo-img">
                  <img src={img12} alt="..." />
                </div>
                <div className="col-md-8">
                  <div className="card-body">
                    <p className="card-text">Wellness Through Natural Bonding</p>
                    <p className="card-text">
                      <small className="text-muted">01 Oct 2020</small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <div className="card mb-4" id="news-card-kangaroo">
              <div className="row g-0">
                <div className="col-md-4" id="news-card-kangaroo-img">
                  <img src={img12} alt="..." />
                </div>
                <div className="col-md-8">
                  <div className="card-body">
                    <p className="card-text">Golden Hours of Zippy Babies</p>
                    <p className="card-text">
                      <small className="text-muted">01 Oct 2020</small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <div className="card mb-4" id="news-card-kangaroo">
              <div className="row g-0">
                <div className="col-md-4" id="news-card-kangaroo-img">
                  <img src={img12} alt="..." />
                </div>
                <div className="col-md-8">
                  <div className="card-body">
                    <p className="card-text">Awareness - No Medical Errors</p>
                    <p className="card-text">
                      <small className="text-muted">01 Oct 2020</small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <div className="card mb-4" id="news-card-kangaroo">
              <div className="row g-0">
                <div className="col-md-4" id="news-card-kangaroo-img">
                  <img src={img12} alt="..." />
                </div>
                <div className="col-md-8">
                  <div className="card-body">
                    <p className="card-text">The First Trimester of Pregnancy</p>
                    <p className="card-text">
                      <small className="text-muted">01 Oct 2020</small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <div className="card mb-4" id="news-card-kangaroo">
              <div className="row g-0">
                <div className="col-md-4" id="news-card-kangaroo-img">
                  <img src={img12} alt="..." />
                </div>
                <div className="col-md-8">
                  <div className="card-body">
                    <p className="card-text">
                      Golden Hour for insuring long life of a new born child
                    </p>
                    <p className="card-text">
                      <small className="text-muted">01 Oct 2020</small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <div className="card mb-4" id="news-card-kangaroo">
              <div className="row g-0">
                <div className="col-md-4" id="news-card-kangaroo-img">
                  <img src={img12} alt="..." />
                </div>
                <div className="col-md-8">
                  <div className="card-body">
                    <p className="card-text">
                      Now in Mysore! Kangaroo Care Women & Children Hospital
                    </p>
                    <p className="card-text">
                      <small className="text-muted">01 Oct 2020</small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <div className="card mb-4" id="news-card-kangaroo">
              <div className="row g-0">
                <div className="col-md-4" id="news-card-kangaroo-img">
                  <img src={img12} alt="..." />
                </div>
                <div className="col-md-8">
                  <div className="card-body">
                    <p className="card-text">
                      Now in Mysore! Kangaroo Care Women & Children Hospital
                    </p>
                    <p className="card-text">
                      <small className="text-muted">01 Oct 2020</small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <div className="card mb-4" id="news-card-kangaroo">
              <div className="row g-0">
                <div className="col-md-4" id="news-card-kangaroo-img">
                  <img src={img12} alt="..." />
                </div>
                <div className="col-md-8">
                  <div className="card-body">
                    <p className="card-text">
                      Now in Mysore! Kangaroo Care Women & Children Hospital
                    </p>
                    <p className="card-text">
                      <small className="text-muted">01 Oct 2020</small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <div className="card mb-4" id="news-card-kangaroo">
              <div className="row g-0">
                <div className="col-md-4" id="news-card-kangaroo-img">
                  <img src={img12} alt="..." />
                </div>
                <div className="col-md-8">
                  <div className="card-body">
                    <p className="card-text">
                      Now in Mysore! Kangaroo Care Women & Children Hospital
                    </p>
                    <p className="card-text">
                      <small className="text-muted">01 Oct 2020</small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <div className="card mb-4" id="news-card-kangaroo">
              <div className="row g-0">
                <div className="col-md-4" id="news-card-kangaroo-img">
                  <img src={img12} alt="..." />
                </div>
                <div className="col-md-8">
                  <div className="card-body">
                    <p className="card-text">
                      Now in Mysore! Kangaroo Care Women & Children Hospital
                    </p>
                    <p className="card-text">
                      <small className="text-muted">01 Oct 2020</small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>

      {show && (
        <ImageShow show={show} setShow={setShow} customPath="/media/press/" />
      )}
    </>
  );
}
