import React from "react";
import doc04 from "../assets/img/doctor/doc4-1.jpg";
import { useLocation, Link } from "react-router-dom";
import CustomTitle from "../CustomTitle";

export default function Pramodh() {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const helmetDescriptions = {
    titleName:
      "Dr. Pramodh | Lifestyle & Addiction Medicine | Kangaroo Care Fertility",
    Description: "",
  };

  return (
    <div id="each-doctor">
      <CustomTitle customValue={helmetDescriptions} />
      <nav aria-label="breadcrumb" id="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>

          <li className="breadcrumb-item active" aria-current="page">
            Doctor
          </li>
        </ol>
      </nav>
      {/* -------------------------doctor detail--------------- */}

      <div className="container mt-4">
        <div className="row">
          <div className="col-md-5">
            <div className="card" style={{ maxWidth: "30rem" }}>
              <img src={doc04} className="card-img-top" alt="Dr. Pramodh" />
            </div>
            <br />
            <br />
            <br />
            <h3>More Info</h3>
            <br />
            <p>MBBS, DFM, DHA, CCEBDM, MLSM</p>
            <p>Masters Degree in Lifestyle Medicine</p>
            <p>
              Department : <span>Physicians</span>
            </p>
            <br />
            {/* <p>Consultations : 60 Minutes per Consultation</p>
            <br /> */}
            <p>Location : Bangalore</p>
            <br />

            <span className="badge bg-primary p-2">
              Available on Prior Appointment
            </span>
            <br />
            <p> - Contact Front Desk @ 2301 5678</p>
            <br />
          </div>

          <div className="col-md-7">
            <h1 className="text-blue">Dr. PRAMODH</h1>
            <p>
              <strong>LIFESTYLE & ADDICTION MEDICINE</strong>
            </p>
            <p>
              Dr.Pramodh completed his MBBS from Adichunchanagiri Institute of
              Medical Sciences, Having over all experience of 20 yrs in the
              field of medicine, he pursued a course in Diabetology under public
              health foundation of India, New Delhi, later on attained masters
              degree in Life style through Apollo Medvarsity and also completed
              foundation course in Life style medicine from American college of
              Life style medicine . He is also a certified physician for
              substance abuse disorder from National Drug Dependence treatment
              centre in collaboration with AIIMS Delhi.
            </p>
            <p>
              His main focus is in counselling and educating people about Life
              style modifications and prevent them from getting diseased or
              reversal of disease especially Diabetes, Hypertension etc.
            </p>
            <p>
              His area of interest is for obesity, Sleep Medicine, Physical
              Fitness, Alcohol and substance abuse.
            </p>

            <h3>Consultant - Lifestyle & Addiction Medicine</h3>
            <p>MBBS</p>
            <p>Kangaroo care mother & child Hospital</p>
            <p>Bangalore Super Speciality Centre</p>
            <p>Sai Baba Centre for Alcohol & Substance abuse</p>
            <p>Vmedo Health Care</p>
            <h3>Professional Membership</h3>
            <p>Karnataka Medical Council</p>
            <p>Ex- Treasurer – Indian Society of Life Style Medicine</p>
          </div>
        </div>
      </div>
    </div>
  );
}
