import React, { useEffect, useState, useRef } from "react";

import "./bengalur.css";

import OwlCarousel from "react-owl-carousel";

import ramanagaraCenterImg from "../../assets/img/centers/ramanagara.jpg";

import { useLocation, Link } from "react-router-dom";
import CustomTitle from "../../CustomTitle";
import Centers from "../Centers";
export default function Ramanagara() {
  const [width, setWidth] = useState(window.innerWidth);
  const carouselRef = useRef(null);
  const testimonialRef = useRef(null);

  const helmetDescriptions = {
    titleName:
      "Top Center in Ramanagara | Expert Care at Kangaroo Care Fertility",
    Description:
      "Find top fertility specialists in Ramanagara at Kangaroo Care Fertility. Expert care for your journey to parenthood. Book your consultation today!",
  };
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const nextTestimonial = () => {
    if (testimonialRef.current) {
      testimonialRef.current.next();
    }
  };
  const prevTestimonial = () => {
    if (testimonialRef.current) {
      testimonialRef.current.prev();
    }
  };
  const testimonailOptions = {
    responsive: {
      0: {
        items: 1,
      },
      // 600: {
      //   items: 2,
      // },
      // 1000: {
      //   items: 3,
      // },
    },
  };

  return (
    <div>
      <CustomTitle
        href="https://kangaroocarefertility.com/treatments/ovulation-induction"
        customValue={helmetDescriptions}
      />

      {/* <div className="main-content container mt-2">
        <nav aria-label="breadcrumb" className="mb-5">
          <ol className="breadcrumb">
            <li className="breadcrumb-item text-specifc-color">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Ovulation Induction
            </li>
          </ol>
        </nav>
      </div> */}
      {/* -------main content------------ */}

      {/* -----------------------------what is portion -------------------- */}
      <div className="container-fluid service my-5">
        {/* -----------------------section2 content process--------------------- */}

        <div className="row justify-content-center my-5">
          <div className="col-md-7 mx-2" id="service-content2-card">
            <div className="mb-2 p-4">
              <img
                src={ramanagaraCenterImg}
                className="card-img-top"
                alt="mysore"
              />
              <h1 className=" text-blue pt-4">Fertility Center Ramanagara</h1>

              <p>
                Kangaroo Care Fertility Center, where we prioritize providing
                top-notch fertility care to couples seeking assistance in their
                journey towards parenthood. Situated in Ramanagara, our center
                is equipped with state-of-the-art infrastructure and staffed by
                a highly skilled team of compassionate doctors who guide couples
                through the complexities of fertility treatment.
              </p>
              <p>
                At Kangaroo Care, we understand the emotional and physical
                challenges of infertility, so we strive to create a nurturing
                and supportive environment for our patients. Our ultimate goal
                is to help you realize your dream of starting or expanding your
                family.
              </p>
              <p>
                Our fertility specialists have extensive experience in the
                evaluation and treatment of infertility, offering a range of
                advanced procedures, including IVF and IUI, as well as
                hysteroscopic and laparoscopic surgical interventions. With
                their expertise and dedication, we aim to maximize the chances
                of successful conception for every couple who walks through our
                doors.
              </p>
              <p>
                In addition to our medical team, Kangaroo Care Fertility Center
                is proud to offer comprehensive support services, including
                physiotherapy, counseling, and nutritional guidance. We
                understand that fertility treatment can be physically and
                emotionally demanding, and our holistic approach ensures that
                you receive the care and support you need at every step of your
                journey.
              </p>
              <p>
                Our commitment to evidence-based medicine ensures you receive
                the most effective and scientifically proven treatments tailored
                to your needs. By combining cutting-edge technology with
                personalized care, we strive to achieve the best possible
                outcomes for our patients.
              </p>
              <p>
                At Kangaroo Care Fertility Center, we are honored to be part of
                your fertility journey and look forward to helping you achieve
                your goal of becoming proud parents. Contact us today to take
                the first step toward realizing your dream of parenthood.
              </p>
              <p>
                {" "}
                <i className="fas fa-check-circle me-2"></i>Pre-Conceptional
                Counseling{" "}
              </p>
              <p>
                {" "}
                <i className="fas fa-check-circle me-2"></i>Ovulation Induction{" "}
              </p>
              <p>
                {" "}
                <i className="fas fa-check-circle me-2"></i>Intrauterine
                Insemination (IUI){" "}
              </p>
              <p>
                {" "}
                <i className="fas fa-check-circle me-2"></i>In-Vitro
                Fertilisation (IVF){" "}
              </p>
              <p>
                {" "}
                <i className="fas fa-check-circle me-2"></i>Egg Donor IVF{" "}
              </p>
              <p>
                {" "}
                <i className="fas fa-check-circle me-2"></i>Egg Cryopreservation{" "}
              </p>
              <p>
                {" "}
                <i className="fas fa-check-circle me-2"></i>Egg Freezing{" "}
              </p>
              <p>
                {" "}
                <i className="fas fa-check-circle me-2"></i>Intracytoplasmic
                Sperm Injection (ICSI){" "}
              </p>
              <p>
                {" "}
                <i className="fas fa-check-circle me-2"></i>Intracytoplasmic
                Morphologically Selected Sperm Injection (IMSI){" "}
              </p>
              <p>
                {" "}
                <i className="fas fa-check-circle me-2"></i>Sperm Donation{" "}
              </p>
              <p>
                {" "}
                <i className="fas fa-check-circle me-2"></i>Sperm Banking{" "}
              </p>
              <p>
                {" "}
                <i className="fas fa-check-circle me-2"></i>PICSI{" "}
              </p>
              <p>
                {" "}
                <i className="fas fa-check-circle me-2"></i>Platelet Rich Plasma
                (PRP) for Endometrium Thickness{" "}
              </p>
              <p>
                {" "}
                <i className="fas fa-check-circle me-2"></i>Blastocyst Culture &
                Transfer{" "}
              </p>
              <p>
                {" "}
                <i className="fas fa-check-circle me-2"></i>Magnetic Activated
                Cell Sorting (MACS){" "}
              </p>
            </div>
          </div>
          <div
            className="col-md-3 mb-2 mx-2 p-4 card  d-none d-md-block center_branches_items"
            id="service-link-card"
          >
            <div>
              <h2 className="text-blue ">Our Centers</h2>
              <br />
              <Centers />
            </div>
          </div>
          <div className="testimonials-section">
            <div className="testimonials-section-top-text">
              <h2>Testimonials</h2>
            </div>

            <div className="testimonial_items_">
              <div
                className="container testimonial_items_wrapper"
                id="testimonial_branch"
              >
                <OwlCarousel
                  ref={testimonialRef}
                  items={1}
                  className="owl-carousel-blog testimonials-slider"
                  data-aos="fade-up"
                  data-aos-delay="100"
                  loop
                  autoplay={true}
                  autoplayTimeout={4000}
                  margin={8}
                  dots={true}
                  nav={false}
                  navText={[
                    '<i className="icofont-simple-up newStyle"></i>',
                    '<i className="icofont-simple-up"></i>',
                  ]}
                  {...testimonailOptions}
                >
                  <div className="testimonials-item">
                    <div className="image_container">
                      <div className="testimonial_google">
                        <img src="/google-01.png" />
                      </div>
                      <div className="testimonial_star">
                        <img src="/5-star.png" />
                      </div>
                    </div>
                    <p id="kindlyLeft">
                      We consider overselves most fortunate to have consulted in
                      kangaroo care fertility center Bangalore. The facility of
                      Kangaroo Care Fertility is wonderful, the medical staff is
                      well experienced and caring, and the adminstrative staff
                      is extreamly pleasant and efficient. We were beyond
                      impressed with the kind of care we got care and
                      experience. Thanks to all doctors and staff Kangaroo Care
                      Fertility.
                    </p>
                    <p className="testimonials-item-name"> Monica & Vinay</p>
                  </div>
                  <div className="testimonials-item">
                    <div className="image_container">
                      <div className="testimonial_google">
                        <img src="/google-01.png" />
                      </div>
                      <div className="testimonial_star">
                        <img src="/5-star.png" />
                      </div>
                    </div>
                    <p>
                      We are thankful to Kangaroo care, fertility center for the
                      best services. The doctors are very good and the team is
                      very honored and polite enough to keep us informed about
                      all The processes and procedures. All the facilities
                      available under one root and ambience maintained, neat and
                      clean.
                    </p>
                    <p className="testimonials-item-name"> Shnuddha Reddy </p>
                  </div>
                  <div className="testimonials-item">
                    <div className="image_container">
                      <div className="testimonial_google">
                        <img src="/google-01.png" />
                      </div>
                      <div className="testimonial_star">
                        <img src="/5-star.png" />
                      </div>
                    </div>
                    <p>
                      The overall experience from Kangaroocare fertility was
                      really good. Every doctors and staff are very caring and
                      supportive. They will guide you like mothers and care for
                      every women. Thanks again and recommended others also to
                      this hospital
                    </p>
                    <p className="testimonials-item-name"> Jyothi, Hari </p>
                  </div>
                </OwlCarousel>
                <div className="testimonial_custom_nav">
                  <button onClick={prevTestimonial}>
                    <i class="fas fa-chevron-left"></i>
                  </button>
                  <button onClick={nextTestimonial}>
                    <i class="fas fa-chevron-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* -------------------------advantage cards---------------- */}
    </div>
  );
}
