import React from "react";
import BlogContent from "./blog.json";
import Blogs from "./Blogs";
import { useParams } from "react-router-dom";
import BlogJson from "./blog.json";

export default function MainBlog() {
  const { id } = useParams();
  const filteredBlog = BlogJson.filter((item) => {
    return item.id == id;
  });
  return (
    <div>
      {filteredBlog.map((value) => {
        return (
          <>
            {/* <Blogs
              id={value.id}
              title={value.title}
              image={value.image}
              detailed_text={value.detailed_text}
            /> */}
            <Blogs {...filteredBlog} />
          </>
        );
      })}
    </div>
  );
}
