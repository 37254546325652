import styles from "./imageShow.module.css";
export default function ImageShow({ show, setShow, customPath }) {
  return (
    <section className={styles.wrapper}>
      <div className={styles.wrapper_icon}>
        {/* <IoCloseCircleOutline /> */}
        <i class="fas fa-times" onClick={() => setShow(null)}></i>
      </div>
      <section className={styles.bg}></section>

      <section className={styles.img} onClick={() => setShow(null)}>
        <img src={`${customPath}${show}`} />
      </section>
    </section>
  );
}
