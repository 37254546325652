import React from "react";
import doc3 from "../assets/img/doctor/tarun-javeli.jpeg";
import { useLocation, Link } from "react-router-dom";
import CustomTitle from "../CustomTitle";

export default function Tarun() {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const helmetDescriptions = {
    titleName:
      "Dr. Tarun Javali | Leading Fertility Specialist at Kangaroo Care",
    Description:
      "Meet Dr Tarun Javali, a renowned fertility specialist at Kangaroo Care. Discover his expertise in advanced fertility treatments and personalized patient care for better outcomes.",
  };

  return (
    <div id="each-doctor">
      <CustomTitle customValue={helmetDescriptions} />
      {/* <div className="py-md-5" style={{ backgroundImage: "url(" + blogHeader + ")", backgroundPosition: 'center', backgroundSize: 'cover', height: '400px' }}>
        <div className="banner-header text-center py-5">
            <h2 style={{ color: '#fff', position: "relative", top: "100px" }}>Dr. DIVYASREE D</h2>
        </div>
    </div> */}
      <br />
      {/* <h6 className="offset-1 my-4 "  style={{ textAlign: "left", color: "black" }}>HOME &gt; MEDIA &gt; Blog</h6> */}
      <nav aria-label="breadcrumb" id="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>

          <li className="breadcrumb-item active" aria-current="page">
            Doctor
          </li>
        </ol>
      </nav>
      {/* -------------------------doctor detail--------------- */}

      <div className="container mt-4">
        <div className="row">
          <div className="col-md-5">
            <div className="card" style={{ maxWidth: "30rem" }}>
              <img src={doc3} className="card-img-top" alt="Dr. Divyashree D" />
            </div>
            <br />
            <br />
            <br />
            <h3>More Info</h3>
            <br />
            <p>MBBS, MS-General Surgery, MCh - Urology</p>
            <br />
            <p>
              Department : <span>Andrologist & Urologist </span>
            </p>
            <br />
            {/* <p>Consultations : 15 Minutes per Consultation</p>
            <br /> */}
            <p>Location : Bangalore</p>
            <br />

            <span className="badge bg-primary p-2">
              Available on Prior Appointment
            </span>
            <br />
            <p> - Toll-free number @ 1800-425-4500</p>
            <br />
          </div>

          <div className="col-md-7">
            <h1 className="text-blue">Dr. Tarun Javali</h1>
            <p>
              <strong>Profile</strong>
            </p>
            <p>
              Dr. Tarun Javali is a Urologist, Andrologist, and Laparoscopic
              Surgeon based in Malleswaram, Bangalore. He has 13 years of
              experience overall, with 11 years as a specialist.
            </p>

            <h3>QUALIFICATIONS</h3>
            <p>
              MBBS from Jawaharlal Institute of Postgraduate Medical Education
              and Research (JIPMER), Puducherry (2005)
            </p>
            <p>
              MS-General Surgery from Jawaharlal Institute of Postgraduate
              Medical Education and Research (JIPMER), Puducherry (2008)
            </p>
            <p>
              MCh - Urology from All India Institute of Medical Sciences, New
              Delhi (2011)
            </p>

            <h3>WORK EXPERIENCE</h3>
            <p>13 years of experience overall</p>
            <p>11 years as a specialist</p>

            <h3>PROFESSIONAL MEMBERSHIPS</h3>
            <p>Indian Medical Association (IMA)</p>
            <p>Urological Society of India (USI)</p>
            <p>American Urological Association (AUA)</p>
            <p>European Association of Urology</p>
            <p>Indian Society of Organ Transplantation (ISOT)</p>
            <p>Karnataka Urology Association</p>
            <p>Association of Southern Urologists</p>
            <p>Indian American Urological Association</p>
          </div>
        </div>
      </div>
    </div>
  );
}
