import React from "react";
import logo from "../kcf_logo.png";
import "./KcFertilityServices.css";
export default function ThankYou() {
  return (
    <>
      <div className="thank-you thank-you-wrapper">
        <p style={{ marginTop: "10px" }}>Thank You! </p>
        <p> Our representative will contact you soon.</p>
        <a href="https://kangaroocarefertility.com/">Home Page</a>
      </div>
    </>
  );
}
