import React from "react";
import CustomTitle from "../CustomTitle";
import "./intracytoplasmicsperminjection.css";
import simg from "../assets/img/Treatments/treatments-banner/intrauterineinsemination.jpg";
import chart from "../assets/img/home/service-images/chart.JPG";
import { useLocation, Link } from "react-router-dom";
export default function Services() {
  const helmetDescriptions = {
    titleName: "Best ICSI Treatment in Bangalore | ICSI Infertility Treatment",
    Description:
      "Kangaroo Care Fertility is one of the top centers for ICSI infertility treatment in Bangalore. Our specialists use advanced procedures to deliver the best to our patients.",
  };
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const ICSISchema = `<script type="application/ld+json">
  {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [{
      "@type": "Question",
      "name": "Difference between IVF and ICSI ?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "In IVF, we leave the egg and the sperms in a petri dish for the fertilization to occur naturally. In ICSI, carefully selected single sperm is injected directly into the egg under a microscope for fertilization to occur."
      }
    },{
      "@type": "Question",
      "name": "Is ICSI expensive ?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "ICSI is done using a micromanipulator and thus it is slightly expensive when compared to IVF."
      }
    },{
      "@type": "Question",
      "name": "Are babies born after ICSI normal ?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "After several studies, it was concluded that congenital malformations were actually slightly lesser in babies born through ICSI when compared to those born naturally."
      }
    },{
      "@type": "Question",
      "name": "How many sperms are needed for ICSI ?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "All we need is 1 good sperm for ICSI. “ One Sperm, One egg, One EMBRYO”"
      }
    },{
      "@type": "Question",
      "name": "Are there any adverse effects after ICSI?",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "There is no convincing evidence that the rate of birth defects in children born from the ICSI process is any different to the general population."
      }
    }]
  }
  </script>


  `;
  return (
    <>
      <CustomTitle
        href="https://kangaroocarefertility.com/treatments/intracytoplasmic"
        customValue={helmetDescriptions}
      />
      <iframe srcDoc={ICSISchema} title="schema" width="0" height="0" />
      <div className="main-content container mt-2">
        <nav aria-label="breadcrumb" className="mb-5">
          <ol className="breadcrumb">
            <li className="breadcrumb-item text-specifc-color">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item text-specifc-color">
              <Link to="/">Specialities</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Intracytoplasmic Sperm Injection (ICSI)
            </li>
          </ol>
        </nav>
      </div>

      {/* -------main content------------ */}

      {/* -----------------------------what is portion -------------------- */}
      <div className="container-fluid service my-5">
        <div className="row justify-content-center">
          <h1 className="heading1 text-center pb-5">
            ICSI Treatment in Bangalore
          </h1>
          <div className="col-md-6 mx-2 d-none d-sm-block">
            <div className="mb-2" id="service-left-img-intracy">
              <img src={simg} alt="in vitro fertilization" />
            </div>
          </div>
          <div className="col-md-5 mb-2 mx-2" id="service-content-card">
            <h2 className="my-4 text-center text-blue">
              Intracytoplasmic Sperm Injection (ICSI)
            </h2>
            <hr />
            <p>
              <i className="fas fa-check-circle me-2"></i>
              <strong>What is Intracytoplasmic Sperm Injection (ICSI)?</strong>
            </p>
            <p>
              ICSI is a procedure where a single sperm is injected into each egg
              to assist fertilisation using very fine micro-manipulation
              equipment. At, Kangaroo Care Fertility we use high end equipment
              and provide ultimate results.
            </p>
          </div>
        </div>

        {/* -----------------------section2 content process--------------------- */}

        <div className="row justify-content-center my-5">
          <p style={{ width: "90%", textAlign: "center" }}>
            We are one of the Best ICSI treatment clinic in Vijayanagar
            Bangalore providing comprehensive care and quality services. Our
            experienced team of ICSI specialists work in partnership with you to
            provide the best ICSI treatment that can increase the chances of
            conceiving.
          </p>
          <div className="col-md-8 mx-2" id="service-content2-card">
            <div className="mb-2 p-4">
              <h2 className="text-center text-blue">Procedure of ICSI</h2>
              <br />
              <p>
                <i className="fas fa-check-circle me-2"></i>
                The procedure of ICSI is similar to IVF
                <Link to="/treatments/invitro-fertilization">(go to IVF)</Link>.
                The difference is in fertilization of the eggs. Here in ICSI, we
                select the sperm after processing the semen sample in the lab.
                This is directly inserted into the egg for fertilization to The
                procedure of ICSI is similar to
                <Link to="/treatments/invitro-fertilization">
                  {" "}
                  IVF treatment
                </Link>
                . The difference is in fertilization of the eggs. Here in ICSI,
                we select the sperm after processing the semen sample in the
                lab. This is directly inserted into the egg for fertilization to
                occur. The fertilization failure is less with ICSI compared to
                IVF.
              </p>
              <br />
              <div className="text-center img-chart mb-3">
                <img
                  src={chart}
                  alt="IVF Treatment process steps"
                  style={{ maxWidth: "400px" }}
                />
              </div>
              <h5>
                <i className="fas fa-check-circle me-2"></i>
                Whom to advise ICSI
              </h5>
              <p>
                <ul>
                  <li>Men with low sperm count.</li>
                  <li>When there is abnormal sperm morphology</li>
                  <li>Unexplained infertility</li>
                  <li>High DFI</li>
                  <li>Previous failed IVF</li>
                  <li>
                    Presence of anti-sperm antibodies, like in post vasectomy
                    reversal patients.
                  </li>
                </ul>
              </p>
            </div>
          </div>
          <div
            className="col-md-3 mb-2 mx-2 p-4 card  d-none d-md-block"
            id="service-link-card"
          >
            <h2 className="text-blue">Important Links</h2>
            <br />
            <ul className="ms-5">
              <li>
                <Link to="/treatments/ovulation-induction">
                  <span className="hand-link">&#9758;</span> &nbsp; Ovulation
                  Induction
                </Link>
              </li>
              <li>
                <Link to="/treatments/intrauterine">
                  <span className="hand-link">&#9758;</span> &nbsp; Intrauterine
                  Insemination (IUI)
                </Link>
              </li>
              <li>
                <Link to="/treatments/invitro-fertilization">
                  <span className="hand-link">&#9758;</span> &nbsp; In Vitro
                  Fertilization
                </Link>
              </li>
              <li>
                <Link to="/treatments/intracytoplasmic">
                  <span className="hand-link">&#9758;</span> &nbsp;
                  Intracytoplasmic Sperm Injection{" "}
                </Link>
              </li>
              <li>
                <Link to="/treatments/genetic-counselling-and-testing">
                  <span className="hand-link">&#9758;</span> &nbsp; Genetic
                  Counselling and Testing
                </Link>
              </li>
              <li>
                <Link to="/treatments/varicocele">
                  <span className="hand-link">&#9758;</span> &nbsp; Varicocele
                </Link>
              </li>
              <li>
                <Link to="/treatments/tesa-pesa">
                  <span className="hand-link">&#9758;</span> &nbsp; TESA/ PESA
                </Link>
              </li>
              <li>
                <Link to="/treatments/fertility-preservation">
                  <span className="hand-link">&#9758;</span> &nbsp; Fertility
                  Preservation
                </Link>
              </li>
              <li>
                <Link to="/treatments/Donor">
                  <span className="hand-link">&#9758;</span> &nbsp; Donor
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* -------------------------advantage cards---------------- */}

      <div className="container-fluid my-4">
        <div className="row justify-content-center" id="scards">
          <h2 className="text-center text-blue">Advantages of ICSI</h2>
          <br />
          <br />
          <div className="col-sm-3 m-2 p-3 the-3-service-card">
            <div className="text-center">
              {/* <img src={logo} alt="" /> */}
              <p>
                <strong className="text-blue">Better Outcome</strong>
              </p>
            </div>
            <p>
              The fertilization rate and pregnancy rates are good with ICSI.
            </p>
          </div>
          <div className="col-sm-3 m-2 p-3 the-3-service-card">
            <div className="text-center">
              {/* <img src={logo} alt="" /> */}
              <p>
                <strong className="text-blue">Safety and Affordability</strong>
              </p>
            </div>
            <p>
              ICSI done at the state-of-the-art embryology lab with high end
              equipment at Kangaroo Care Fertility is safe and cost effective,
              especially in male infertility.
            </p>
          </div>
          <div className="col-sm-3 m-2 p-3 the-3-service-card">
            <div className="text-center">
              {/* <img src={logo} alt="" /> */}
              <p>
                <strong className="text-blue">Male Infertility</strong>
              </p>
            </div>
            <p>
              Even in men with very low sperm counts, we can achieve a
              pregnancy. Likewise, ICSI gives better results in male
              infertility.
            </p>
          </div>
        </div>
      </div>

      {/* ---------------------------faq------------------------ */}

      <div className="accordion container-fluid my-5 " id="accordionExample">
        <h2 className="text-center text-blue">FAQs</h2>
        <br />
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              • Difference between IVF and ICSI ?
            </button>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              In IVF, we leave the egg and the sperms in a petri dish for the
              fertilization to occur naturally. In ICSI, carefully selected
              single sperm is injected directly into the egg under microscope
              for fertilization to occur.
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingTwo">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              • Is ICSI expensive ?
            </button>
          </h2>
          <div
            id="collapseTwo"
            className="accordion-collapse collapse"
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              ICSI is done using a micromanipulator and thus it is slightly
              expensive when compared to IVF.
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingThree">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              • Are babies born after ICSI normal ?
            </button>
          </h2>
          <div
            id="collapseThree"
            className="accordion-collapse collapse"
            aria-labelledby="headingThree"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              After several studies, it was concluded that congenital
              malformations were actually slightly lesser in babies born through
              ICSI when compared to those born naturally.
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingFour">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
            >
              • How many sperms are needed for ICSI ?
            </button>
          </h2>
          <div
            id="collapseFour"
            className="accordion-collapse collapse"
            aria-labelledby="headingFour"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              All we need 1 good sperm for ICSI. “ One Sperm, One egg, One
              EMBRYO”
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingFive">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFive"
              aria-expanded="false"
              aria-controls="collapseFive"
            >
              • Are there any adverse effects after ICSI?
            </button>
          </h2>
          <div
            id="collapseFive"
            className="accordion-collapse collapse"
            aria-labelledby="headingFive"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              There is no convincing evidence that the rate of birth defects in
              children born from the ICSI process is any different to the
              general population.
            </div>
          </div>
        </div>
      </div>
      {/* ----------------some heading and para----------- */}
      <div className="container-fluid bg-light ">
        <div className="container">
          <div className="row">
            <div className="col text-center my-sm-5 my-3">
              <h2 className="heading2">
                Why choose Kangaroo Care Fertility for ICSI Treatment in
                Bangalore?
              </h2>
              <p>
                Kangaroo Care Fertility is one of the best ICSI clinics in
                Bangalore. Our Specialised fertility doctors provide exceptional
                care and treatment for male & female infertility problems. We
                make sure to provide the best ICSI treatment in Vijayanagar
                Bangalore.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid bg-light ">
        <div className="container">
          <div className="row">
            <div className="col text-center my-sm-5 my-3">
              <h2 className="heading2">
                Best ICSI Infertility Treatment in Bangalore
              </h2>
              <p>
                Experience parenthood with the best ICSI treatment in Bangalore.
                We use high end advanced equipment to provide the best results.
                Our department of ICSI specialists help you to take your first
                steps towards happiness. Kangaroo care is the best ICSI
                infertility treatment center to offer specialized attention and
                care for male infertility treatment in Bangalore. We also
                specialize in providing other treatments like &nbsp;s
                <a href="https://kangaroocarefertility.com/treatments/ovulation-induction">
                  Ovulation Induction,
                </a>
                <a href="https://kangaroocarefertility.com/treatments/invitro-fertilization">
                  IVF treatment,
                </a>
                <a href="https://kangaroocarefertility.com/treatments/intrauterine">
                  IUI,
                </a>
                <a href="https://kangaroocarefertility.com/treatments/intracytoplasmic">
                  ICSI,
                </a>
                <a href="https://kangaroocarefertility.com/treatments/genetic-counselling-and-testing">
                  genetic counseling & testing,
                </a>
                <a href="https://kangaroocarefertility.com/treatments/varicocele">
                  varicocele treatment,
                </a>
                <a href="https://kangaroocarefertility.com/treatments/tesa-pesa">
                  TESA/PESA &nbsp;
                </a>
                for both female infertility and male infertility problems.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
