import React from "react";
import doc3 from "../../assets/img/doctor/hemalatha-ramanagar.jpg";
import { useLocation, Link } from "react-router-dom";
import CustomTitle from "../../CustomTitle";

export default function Hemalatha() {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const helmetDescriptions = {
    titleName:
      "Dr. Hemalatha D | Fertility Specialist | Kangaroo Care Fertility ",
    Description:
      "Hemalatha N: Pioneering Embryologist Transforming Reproductive Medicine. Expertise in Assisted Reproductive Technologies | kangaroocare Fertility",
  };

  return (
    <div id="each-doctor">
      <CustomTitle customValue={helmetDescriptions} />
      {/* <div className="py-md-5" style={{ backgroundImage: "url(" + blogHeader + ")", backgroundPosition: 'center', backgroundSize: 'cover', height: '400px' }}>
        <div className="banner-header text-center py-5">
            <h2 style={{ color: '#fff', position: "relative", top: "100px" }}>Dr. DIVYASREE D</h2>
        </div>
    </div> */}
      <br />
      {/* <h6 className="offset-1 my-4 "  style={{ textAlign: "left", color: "black" }}>HOME &gt; MEDIA &gt; Blog</h6> */}
      <nav aria-label="breadcrumb" id="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>

          <li className="breadcrumb-item active" aria-current="page">
            Doctor
          </li>
        </ol>
      </nav>
      {/* -------------------------doctor detail--------------- */}

      <div className="container mt-4">
        <div className="row">
          <div className="col-md-5">
            <div className="card" style={{ maxWidth: "30rem" }}>
              <img src={doc3} className="card-img-top" alt="Dr. Divyashree D" />
            </div>
            <br />
            <br />
            <br />
            <h3>More Info</h3>
            <br />
            <p>MSC BIOLOGICAL SCIENCE & DIPLOMA IN CEART</p>
            <br />
            <p>
              Department : <span>Infertility And Ivf</span>
            </p>
            <br />
            {/* <p>Consultations : 15 Minutes per Consultation</p>
            <br /> */}
            <p>Location : Ramanagara</p>
            <br />

            <span className="badge bg-primary p-2">
              Available on Prior Appointment
            </span>
            <br />
            <p> - Toll-free number @ 1800-425-4500</p>
            <br />
          </div>

          <div className="col-md-7">
            <h1 className="text-blue">HEMALATHA N</h1>
            <p>
              <strong>EMBRYOLOGIST</strong>
            </p>

            <h3>EDUCATION</h3>
            <p>2015: Secondary Education - Kengeri Junior College</p>
            <p>2017: Higher Secondary Education - Kengeri PU College</p>
            <p>
              2022: Integrated BSc-MSc in Biological Science - Bangalore
              University
            </p>
            <p>2023: PG Diploma in CEART - Bangalore University</p>

            <h3>EXPERIENCE</h3>
            <p>Laboratory management</p>
            <p>Record maintenance</p>
            <p>Good communication</p>
            <p>Semen analysis</p>
            <p>Sperm preparation for IUI, IVF, and ICSI</p>
            <p>Cryopreservation of semen samples</p>
            <p>Vitrification and thawing of oocytes and embryos</p>
            <p>Denudation</p>
            <p>Embryo transfer</p>
            <p>Conventional IVF techniques</p>
            <h3>PROJECTS</h3>
            <p>
              Influence of sericin on haematology and defence mechanism against
              NPV infection
            </p>
            <h3>INTERNSHIP</h3>
            <p>
              Three months trained in Andrology and Embryology at Huballi
              Assisted Conception Centre (Hubli)
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
