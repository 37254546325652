import React from "react";

export default function Mysore() {
  const schema1 = `<script type='application/ld+json'>
  {
      "@context": "https://www.schema.org",
      "@type": "Physician",
      "name": "Dr SWATHI H K",
      "url": "https://kangaroocarefertility.com/doctor/drswathi",
      "logo": "https://kangaroocarefertility.com/static/media/kcf_logo.0b66caa0.png",
      "image": "https://kangaroocarefertility.com/static/media/swatihk.361a2b49.png",
      "description": "Dr. SWATHI H K OBG and Laparoscopic surgeon at Kangaroo Care Fertility. She completed her MBBS from Hassan institute of medical sciences, Hassan. (146 characters)",
      "address":{
      "@type":"PostalAddress",
      "addressLocality":"Mysore",
      "addressCountry":"IND"
      } ,   
      "telephone": "1800-425-4500",
      "medicalSpecialty":"MBBS,MS, DNB OBG, Laparoscopic surgeon"
      }
  }
</script>`;
  const schema2 = `
  <script type='application/ld+json'>
  {
      "@context": "https://www.schema.org",
      "@type": "Physician",
      "name": "Dr Rashmi T S",
      "url": "https://kangaroocarefertility.com/doctor/rashmi",
      "logo": "https://kangaroocarefertility.com/static/media/kcf_logo.0b66caa0.png",
      "image": "https://kangaroocarefertility.com/static/media/dr-rashmi-new.749eb895.jpg",
      "description": "Meet Rashmi, a skilled embryologist at Kangaroo Care Fertility. Experience expert fertility care with personalized solutions for your journey.",
      "address":{
      "@type":"PostalAddress",
      "addressLocality":"Mysore",
      "addressCountry":"IND"
      } ,   
      "telephone": "1800-425-4500",
      "medicalSpecialty":"embryologist"
      }
}   
</script>`;
  return (
    <div>
      <iframe srcDoc={schema1} title="Blog Schema" width="0" height="0" />
      <iframe srcDoc={schema2} title="Blog Schema" width="0" height="0" />
    </div>
  );
}
