import React from "react";
import "./camp.css";
import img12 from "../../assets/img/media/blogs/press1.jpeg";
import { useLocation, Link } from "react-router-dom";
import CustomTitle from "../../CustomTitle";

import camp1 from "../../assets/img/camps/camp-1.png";
import camp2 from "../../assets/img/camps/camp-2.jpg";
export default function Image() {
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const helmetDescriptions = {
    titleName: "KangarooCare Fertility Camp | Expert Guidance and Support",
    Description:
      "Kangaroo Care Fertility offers a unique approach to fertility through media, camps, and tailored solutions for hopeful parents.",
  };
  return (
    <>
      <CustomTitle
        href="https://kangaroocarefertility.com/media/press"
        customValue={helmetDescriptions}
      />
      {/* <div id="banner-logo">
        <p className="text-center" id="banner-logo-text">
          | PRESS |
        </p>
      </div>

      <h6
        className="offset-md-2 my-4"
        style={{ textAlign: "left", color: "black" }}
      >
        HOME &gt; MEDIA &gt; NEWS{" "}
      </h6>
      <br /> */}
      <br />
      <nav aria-label="breadcrumb" id="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Media
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Camp
          </li>
        </ol>
      </nav>
      <h1 className="h1-top-text">Camp</h1>
      <div className="container" id="press-release">
        <div className="row justify-content-around">
          <div className="col-md-4">
            <div className="card mb-4" id="news-card-kangaroo">
              <div className="camp-card-container">
                <img src={camp1} alt="Fertility camp at Divyam hospital" />
                <div className="camp-card-content">
                  <div className="camp-card-bg"></div>
                  <section>
                    <div className="camp-card-title">
                      Fertility Camp At Divyam Hospital Magai
                    </div>
                    <div className="camp-card-date">25 June 2023</div>
                  </section>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="card mb-4" id="news-card-kangaroo">
              <div className="camp-card-container">
                <img
                  src={camp2}
                  alt="Fertility camp at Adithyaa Nursing home"
                />
                <div className="camp-card-content">
                  <div className="camp-card-bg"></div>
                  <section>
                    <div className="camp-card-title">
                      FERTILITY CAMP AT ADITHYAA NURSING HOME - RAMANAGARA
                    </div>
                    <div className="camp-card-date">28 April 2023</div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
{
  /* <div className="col-md-5">
<div className="card mb-4" id="news-card-kangaroo">
  <div className="row g-0">
    <div className="col-md-4" id="news-card-kangaroo-img">
      <img src={img12} alt="..." />
    </div>
    <div className="col-md-8">
      <div className="card-body">
        <p className="card-text">
          Best Fertility Hospital in Mysore
        </p>
        <p className="card-text">
          <small className="text-muted">09 Oct 2021</small>
        </p>
      </div>
    </div>
  </div>
</div>
</div> */
}
