import React from "react";
import logo from "../kcf_logo.png";
import "./KcFertilityServices.css";
export default function KcFertilityServicesThankYou() {
  return (
    <>
      <div className="thank-you">
        <img src={logo} />
        <p style={{ marginTop: "10px" }}>Thank You! </p>
        <p> Our representative will contact you soon.</p>
      </div>
    </>
  );
}
