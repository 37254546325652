import React from "react";
import CustomTitle from "../CustomTitle";
import "./InVitroFertilization.css";
import simg from "../assets/img/fertility/Intrauterine-Insemination-(IUI)-Banner.jpg";
import chart from "../assets/img/home/service-images/chart.JPG";
import { useLocation, Link } from "react-router-dom";

export default function Services() {
  const helmetDescriptions = {
    titleName:
      "Best IVF Fertility Center in Bangalore | IVF Hospital in Bangalore",
    Description:
      "Kangaroo care Fertility is the leading IVF center in Bangalore providing high-quality services with personalized care and counseling. Consult experts today.!",
  };
  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const inVitroSchema = `<script type="application/ld+json">
  {
    "@context": "http://schema.org/",
    "@type": "FAQPage",
    "mainEntity": [
      {
        "@type": "Question",
        "name": " Are IVF babies normal?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Babies born through IVF are on par with natural pregnancy. They may be delivered prematurely, large for gestation but congenital anomalies are not more as compared to natural pregnancy."
        }
      },
      {
        "@type": "Question",
        "name": "Is IVF painful ?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "The IVF injections are not very painful. The needles are too thin. There may be a slight stinging sensation after the hormonal injections."
        }
      },
      {
        "@type": "Question",
        "name": "How long does it take to get pregnant with IVF ?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "One IVF cycle takes about 2 months. Women younger than 35yrs, usually get pregnant in their first IVF cycle."
        }
      },
      {
        "@type": "Question",
        "name": "  When should a couple opt for IVF?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "IVF is advised in couples with advanced age, damaged or blocked tubes, poor ovarian reserve, repeated abortions, failure of multiple IUI attempts,etc."
        }
      },
      {
        "@type": "Question",
        "name": "What is the best age for IVF?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "IVF does not really fixate on the age. The outcome is better in younger age groups and decreases rapidly beyond the mid 30’s."
        }
      },
      {
        "@type": "Question",
        "name": "What is IVF and how is it done?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "IVF means in-vitro fertilization, it’s one of the best-known types of assisted reproductive technology. It works by using a combination of drugs and surgical techniques to help the sperm fertilize the egg and embed the fertilized egg in the uterus."
        }
      },
      {
        "@type": "Question",
        "name": " How is IVF done step by step?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Step 1: Ovarian stimulation
  Step 2: Trigger injection
  Step 3: Egg retrieval and semen collection
  Step 4: Fertilization (insemination) and embryo development
  Step 5: Embryo transfer
  Step 6: Pregnancy test"
        }
      },
      {
        "@type": "Question",
        "name": "  Do you get pregnant with IVF?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Yes, women can get pregnant with IVF. On average, out of every 5 women, at least one woman has a chance of getting pregnant and having a baby with IVF treatment."
        }
      },
      {
        "@type": "Question",
        "name": "Why is IVF so expensive?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "IVF treatment cost depends on the number of IVF cycles, i.e the greater the number of IVF cycles, the higher the cost of treatment. And other factors which make IVF expensive are the cost of ultrasound scans and tests, donated eggs, freezing embryos, medical and fertility status, and ICSI treatment."
        }
      },
      {
        "@type": "Question",
        "name": "Does IVF hurt?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "IVF injections don't involve much pain. At the same time, the pain can vary from person to person."
        }
      },
      {
        "@type": "Question",
        "name": "Who is not suitable for IVF?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Women with health problems such as tumors, fibroids, ovarian dysfunction, abnormal hormone levels, and uterine abnormalities can experience lower pregnancy rates with IVF."
        }
      },
      {
        "@type": "Question",
        "name": "Is IVF 100% successful?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Not 100%, but IVF is known to be only 60-70% successful (subject to vary depending upon the couple's presentation)."
        }
      }
    ]
  }
  </script>
  
  `;
  const Accodian = [
    {
      id: 1,
      question: "Are IVF babies normal?",
      answer:
        "Babies born through IVF are on par with natural pregnancy. They may be delivered prematurely, large for gestation but congenital anomalies are not more as compared to natural pregnancy.",
    },
    {
      id: 2,
      question: " Is IVF painful ?",
      answer:
        "The IVF injections are not much painful. The needles are too thin. There may be a slight stinging sensation after the hormonal injections.",
    },
    {
      id: 3,
      question: "How long does it take to get pregnant with IVF ?",
      answer:
        "One IVF cycle takes about 2 months. Women younger than 35yrs, usually get pregnant in their first IVF cycle.",
    },
    {
      id: 4,
      question: "When should a couple opt for IVF?",
      answer:
        "IVF is advised in couple with advanced age, damaged or blocked tubes, poor ovarian reserve, repeated abortions, failure of multiple IUI attempts,etc.",
    },
    {
      id: 5,
      question: "What is the best age for IVF?",
      answer:
        " IVF does not really fixate on the age. The outcome is better in younger age groups and decreases rapidly beyond the mid 30’s.",
    },

    {
      id: 6,
      question: "What is IVF and how is it done?",
      answer:
        "IVF means in-vitro fertilization, it’s one of the best-known types of assisted reproductive technology. It works by using a combination of drugs and surgical techniques to help the sperm fertilize the egg and embed the fertilized egg in the uterus.",
    },
    {
      id: 7,
      question: "How is IVF done step by step?",
      answer:
        "<div style='margin-left:3%'><p> Step 1: Ovarian stimulation </p><p> Step 2: Trigger injection </p><p> Step 3: Egg retrieval and semen collection </p><p> Step 4: Fertilization (insemination) and embryo development </p><p> Step 5: Embryo transfer </p><p> Step 6: Pregnancy test </p> </div>",
    },
    {
      id: 8,
      question: "Do you get pregnant with IVF?",
      answer:
        "Yes, women can get pregnant with IVF. On average, out of every 5 women, at least one woman has a chance of getting pregnant and having a baby with IVF treatment.",
    },
    {
      id: 9,
      question: "Why is IVF so expensive?",
      answer:
        "IVF treatment cost depends on the number of IVF cycles, i.e the greater the number of IVF cycles, the higher the cost of treatment. And other factors which make IVF expensive are the cost of ultrasound scans and tests, donated eggs, freezing embryos, medical and fertility status, and  ICSI treatment.",
    },
    {
      id: 10,
      question: "Does IVF hurt?",
      answer:
        "IVF injections don't involve much pain. At the same time, the pain can vary from person to person.",
    },
    {
      id: 11,
      question: "Who is not suitable for IVF?",
      answer:
        "Women with health problems such as tumors, fibroids, ovarian dysfunction, abnormal hormone levels, and uterine abnormalities can experience lower pregnancy rates with IVF.",
    },
    {
      id: 12,
      question: "Is IVF 100% successful?",
      answer:
        "Not 100%, but IVF is known to be only 60-70% successful (subject to vary depending upon the couple's presentation). ",
    },
  ];
  return (
    <div>
      <CustomTitle
        href="https://kangaroocarefertility.com/treatments/invitro-fertilization"
        customValue={helmetDescriptions}
      />
      <iframe srcDoc={inVitroSchema} title="schema" width="0" height="0" />
      <div className="main-content container mt-2">
        <nav aria-label="breadcrumb" className="mb-5">
          <ol className="breadcrumb">
            <li className="breadcrumb-item text-specifc-color">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item text-specifc-color">
              <Link to="/">Specialities</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              In Vitro Fertilization
            </li>
          </ol>
        </nav>
      </div>

      {/* -------main content------------ */}

      {/* -----------------------------what is portion -------------------- */}
      <div className="container-fluid service my-5">
        <div className="row justify-content-center">
          <h1 className="heading1 text-center pb-5">
            Best IVF Hospital in Bangalore
          </h1>
          <div className="col-md-6 mx-2 d-none d-sm-block">
            <div className="mb-2" id="service-left-im-g">
              <img src={simg} alt="sperms moving towards egg icon" />
            </div>
          </div>
          <div className="col-md-5 mb-2 mx-2" id="service-content-card">
            <h2 className="my-4 text-center text-blue">
              In Vitro Fertilisation (IVF)
            </h2>
            <hr />
            <p>
              <i className="fas fa-check-circle me-2"></i>
              <strong>What is IVF ?</strong>
            </p>
            <p>
              IVF is a procedure used to overcome a range of fertility issues,
              by which an egg (which was aspirated from the follicle) and sperm
              (from husband / donor) are joined together outside the body, in a
              specialised lab.
            </p>
            <p>
              The fertilised egg known as embryo is allowed to grow in a
              protected environment for some days before being transferred into
              the woman's uterus increasing the chance that a pregnancy will
              occur. Kangaroo Care Fertility with its state of the art andrology
              and embryology lab provide good results.
            </p>
          </div>
        </div>

        {/* -----------------------section2 content process--------------------- */}

        <div className="row justify-content-center my-5">
          <div className="col-md-8 mx-2" id="service-content2-card">
            <div className="mb-2 p-4">
              <h2 className="text-center text-blue">IVF Treatment Process</h2>
              <br />
              <div className="text-center">
                <img src={chart} alt="IVF Treatment process steps" />
              </div>
              <p style={{ textAlign: "center" }}>
                At Kangaroo Care Fertility, we offer a wide range of fertility
                treatments. We are one of the best IVF centers in Vijayanagar
                Bangalore providing comprehensive care and quality services. We
                are equipped with advanced laboratories to carry out the
                procedures. Our experienced and qualified team of fertility
                specialists work in partnership with you to provide the best IVF
                treatment that can increase the chances of conceiving.
              </p>
            </div>
          </div>
          <div
            className="col-md-3 mb-2 mx-2 p-4 card  d-none d-md-block"
            id="service-link-card"
          >
            <h2 className="text-blue">Important Links</h2>
            <br />
            <ul className="ms-5">
              <li>
                <Link to="/treatments/ovulation-induction">
                  <span className="hand-link">&#9758;</span> &nbsp; Ovulation
                  Induction
                </Link>
              </li>
              <li>
                <Link to="/treatments/intrauterine">
                  <span className="hand-link">&#9758;</span> &nbsp; Intrauterine
                  Insemination (IUI)
                </Link>
              </li>
              <li>
                <Link to="/treatments/invitro-fertilization">
                  <span className="hand-link">&#9758;</span> &nbsp; In Vitro
                  Fertilization
                </Link>
              </li>
              <li>
                <Link to="/treatments/intracytoplasmic">
                  <span className="hand-link">&#9758;</span> &nbsp;
                  Intracytoplasmic Sperm Injection{" "}
                </Link>
              </li>
              <li>
                <Link to="/treatments/genetic-counselling-and-testing">
                  <span className="hand-link">&#9758;</span> &nbsp; Genetic
                  Counselling and Testing
                </Link>
              </li>
              <li>
                <Link to="/treatments/varicocele">
                  <span className="hand-link">&#9758;</span> &nbsp; Varicocele
                </Link>
              </li>
              <li>
                <Link to="/treatments/tesa-pesa">
                  <span className="hand-link">&#9758;</span> &nbsp; TESA/ PESA
                </Link>
              </li>
              <li>
                <Link to="/treatments/fertility-preservation">
                  <span className="hand-link">&#9758;</span> &nbsp; Fertility
                  Preservation
                </Link>
              </li>
              <li>
                <Link to="/treatments/Donor">
                  <span className="hand-link">&#9758;</span> &nbsp; Donor
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="container my-3 ">
        <div className="row d-flex justify-content-center align-item-center ">
          <div className="col-md-10 p-4 my-4 the-3-service-card">
            <h2 className="text-center text-blue">
              Steps of In-Vitro Fertilization Treatment :
            </h2>
            <p>
              <i className="fas fa-check-circle me-2"></i>
              <strong>Step 1: Appointment</strong>
            </p>
            <p>
              We review your medical history and all previous investigations and
              treatments. Various options of treatment the success rates are
              discussed.
            </p>
            <p>
              <i className="fas fa-check-circle me-2"></i>
              <strong>Step 2: Treatment </strong>
            </p>
            <p>
              After thorough discussion with the couple, consent is taken for
              IVF. The couple are asked to come on Day2/3 of the cycle to begin
              the treatment in the form of hormone injections.
            </p>
            <p>
              <i className="fas fa-check-circle me-2"></i>
              <strong>Step 3: Treatment monitoring </strong>
            </p>
            <p>
              Throughout your cycle, regular blood tests measure your hormone
              levels and ultrasounds measure the size and number of your ovarian
              follicles. This also helps us determine the appropriate time for
              egg collection.
            </p>
            <p>
              <i className="fas fa-check-circle me-2"></i>
              <strong>Step 4: Trigger injection </strong>
            </p>
            <p>
              Once you have the optimum number and size of follicles, we plan
              your egg collection. You’ll have a trigger injection, and the
              operation for egg pick up will occur 36 to 38 hours later.
            </p>
            <p>
              <i className="fas fa-check-circle me-2"></i>
              <strong>Step 5: Egg Pick Up </strong>
            </p>
            <p>
              Egg collection is undertaken on day of surgery, done under
              ultrasound guidance. Most women prefer a light general
              anaesthetic, but you can have a local anaesthetic with sedation if
              you prefer. You will be at the hospital for about 4 to 6 hours. On
              the morning of your egg pick up, your partner will need to provide
              a fresh semen (sperm) sample, so we can immediately fertilise your
              eggs.
            </p>
            <p>
              <i className="fas fa-check-circle me-2"></i>
              <strong>Step 6: Egg fertilisation </strong>
            </p>
            <p>
              Collected eggs are taken to the lab and placed in culture medium
              to prepare them for fertilisation later that day. In IVF, prepared
              sperm and eggs are placed together in a dish where fertilisation
              occurs. In ICSI, an individual sperm is selected by our
              experienced embryologist, and, under very delicate microscopic
              control, the egg is injected with this single sperm. The embryo is
              grown in the lab till Day 3 or Day 5.
            </p>
            <p>
              <i className="fas fa-check-circle me-2"></i>
              <strong>Step 7: Embryo transfer </strong>
            </p>
            <p>
              Embryo transfer is a simple day surgery procedure and usually
              takes place three to five days after the egg collection. The
              embryos are transferred into the uterus through a very fine
              catheter passed through the cervix. Frozen embryo transfer can be
              done at a later date.
            </p>
            <p>
              <i className="fas fa-check-circle me-2"></i>
              <strong>Step 8: Embryo freezing </strong>
            </p>
            <p>
              Any extra embryos not used during a treatment cycle are then
              frozen for future use.
            </p>
            <p>
              <i className="fas fa-check-circle me-2"></i>
              <strong>Step 9: Pregnancy test </strong>
            </p>
            <p>
              We do a blood test two weeks after the embryo transfer. If the
              pregnancy test is positive, we will arrange an ultrasound scan
              approximately three weeks later.
            </p>
            <p style={{ textAlign: "justify" }}>
              {" "}
              Our IVF Department is a full-service fertility clinic with the
              latest fertility equipment and technologies for successful
              treatment. We are committed to providing the best IVF treatment in
              Vijayanagar Bangalore.
            </p>
          </div>
        </div>
      </div>

      {/* -------------------------advantage cards---------------- */}

      {/* <div className="container-fluid my-4">
        <div className="row justify-content-center" id="scards">
          <h2 className="text-center">Advantages of Ovulation Induction</h2>
          <br />
          <br />
          <div className="col-md-3 m-2 p-3 the-3-service-card">
            <div className="text-center">
              <img src={logo} alt="" />
              <p>
                <strong>Affordable</strong>
              </p>
            </div>
            <p>
              Ovulation Induction involves oral medications and sometimes
              injections and thus is not expensive.
            </p>
          </div>
          <div className="col-md-3 m-2 p-3 the-3-service-card">
            <div className="text-center">
              <img src={logo} alt="" />
              <p>
                <strong>Non-invasive</strong>
              </p>
            </div>
            <p>It does not involve any invasive procedures.</p>
          </div>
          <div className="col-md-3 m-2 p-3 the-3-service-card">
            <div className="text-center">
              <img src={logo} alt="" />
              <p>
                <strong>Suitability</strong>
              </p>
            </div>
            <p>
              It is most suitable in women with PCOS, anovulation, mild-moderate
              endometriosis, unexplained infertility.
            </p>
          </div>
        </div>
      </div> */}

      {/* ---------------------------faq------------------------ */}

      <div className="accordion container-fluid my-5 " id="accordionExample">
        <h2 className="text-center text-blue">FAQs</h2>
        <br />
        {Accodian.map((item) => {
          return (
            <>
              <div className="accordion-item">
                <h2 className="accordion-header" id={`heading${item.id}`}>
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#collapse${item.id}`}
                    aria-expanded="false"
                    aria-controls={`collapse${item.id}`}
                  >
                    • {item.question}
                  </button>
                </h2>
                <div
                  id={`collapse${item.id}`}
                  className="accordion-collapse collapse"
                  aria-labelledby={`heading${item.id}`}
                  data-bs-parent="#accordionExample"
                >
                  {/* <div className="accordion-body">{item.answer}</div> */}
                  <div dangerouslySetInnerHTML={{ __html: item.answer }} />
                </div>
              </div>
            </>
          );
        })}
      </div>
      {/* ----------------some heading and para----------- */}
      <div className="container-fluid bg-light ">
        <div className="container">
          <div className="row">
            <div className="col text-center my-sm-5 my-3">
              <h2 className="heading2">
                Why choose Kangaroo Care Fertility for IVF Treatment in
                Bangalore?
              </h2>
              <p>
                Kangaroo Care Fertility is one of the best fertility clinics in
                Vijayanagar Bangalore to provide a wide range of fertility
                services like{" "}
                <a href="https://kangaroocarefertility.com/treatments/ovulation-induction">
                  ovulation induction
                </a>
                ,{" "}
                <a href="https://kangaroocarefertility.com/treatments/intrauterine">
                  IUI
                </a>
                , and{" "}
                <a href="https://kangaroocarefertility.com/treatments/intracytoplasmic">
                  ICSI,
                </a>{" "}
                which makes us unique from others. We aim to provide the best
                IVF treatment in Vijayanagar Bangalore.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-light ">
        <div className="container">
          <div className="row">
            <div className="col text-center my-sm-5 my-3">
              <h2 className="heading2">Best IVF Center in Bangalore</h2>
              <p>
                IVF, fertilising the egg in an external environment. With our
                team of IVF specialists in Bangalore, we closely monitor the
                simulation of a woman’s womb and the whole ovulatory process to
                fertilise the egg. We help you embark on a beautiful journey of
                life with the best IVF treatment in Bangalore.
              </p>
              <p>
                We also provide treatments like &nbsp;
                <a href="https://kangaroocarefertility.com/treatments/ovulation-induction">
                  Ovulation Induction,
                </a>
                <a href="https://kangaroocarefertility.com/treatments/invitro-fertilization">
                  IVF treatment,
                </a>
                <a href="https://kangaroocarefertility.com/treatments/intrauterine">
                  IUI,
                </a>
                <a href="https://kangaroocarefertility.com/treatments/intracytoplasmic">
                  ICSI,
                </a>
                <a href="https://kangaroocarefertility.com/treatments/genetic-counselling-and-testing">
                  genetic counseling & testing,
                </a>
                <a href="https://kangaroocarefertility.com/treatments/varicocele">
                  varicocele treatment,
                </a>
                <a href="https://kangaroocarefertility.com/treatments/tesa-pesa">
                  TESA/PESA
                </a>
                for both female infertility and male infertility problems.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
